import { Grid } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import feedbackSent from '../../../assets/images/feedback-sent.png';
import { TranslationKey } from '../../../i18n/translations';
import MainButton from '../../MainButton';
import styles from './FeedbackSent.module.scss';

const FeedbackSent = (): ReactElement => (
  <div className={styles.container}>
    <Grid container justify="center" alignContent="center">
      <Grid item xs={false} sm={false} md={1} />

      <Grid item xs={12} sm={7} md={4} className={styles.textContainer}>
        <h1>
          <FormattedMessage id={TranslationKey.READY} />
        </h1>
        <p>
          <FormattedMessage id={TranslationKey.FEEDBACK_SENT_PARAGRAPH_1} />
        </p>
        <p>
          <FormattedMessage id={TranslationKey.FEEDBACK_SENT_PARAGRAPH_2} />
        </p>
      </Grid>

      <Grid item xs={12} sm={5} md={6}>
        <img src={feedbackSent} alt="Paper plane" className={styles.img} />
      </Grid>

      <Grid item xs={12} className={styles.buttonContainer}>
        <Link to="/">
          <MainButton type="button">
            <FormattedMessage id={TranslationKey.ACCEPT} />
          </MainButton>
        </Link>
      </Grid>
    </Grid>
  </div>
);

export default FeedbackSent;
