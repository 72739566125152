/* eslint-disable consistent-return */
/* eslint-disable default-case */
/* eslint-disable no-param-reassign */

import {
  AppAction,
  CLEAR_REPORTS_STATE,
  DOWNLOAD_REPORT_DATA,
  FETCH_REPORT_DATA,
  SET_REPORTS_DOWNLOADING,
  SET_REPORTS_FETCHING,
  SET_REPORT_CONFIG,
  SET_REPORT_DATA,
} from '../actions/actionTypes';
import { immerProduce } from '../helpers/util-functions';
import { ReportsState } from '../types';

const initState: ReportsState = {
  downloading: false,
  fetching: false,
  config: undefined,
  reportData: undefined,
};

const reportsReducer = (state = initState, action: AppAction): ReportsState =>
  immerProduce(state, (draft) => {
    switch (action.type) {
      case SET_REPORTS_FETCHING:
        draft.fetching = action.fetching;
        return;

      case SET_REPORT_CONFIG:
        draft.config = action.config;
        return;

      case FETCH_REPORT_DATA:
        draft.fetching = true;
        draft.reportData = undefined;
        return;

      case SET_REPORT_DATA:
        draft.fetching = false;
        draft.reportData = action.reportData;
        return;

      case SET_REPORTS_DOWNLOADING:
        draft.downloading = action.downloading;
        return;

      case DOWNLOAD_REPORT_DATA:
        draft.downloading = true;
        return;

      case CLEAR_REPORTS_STATE:
        return initState;
    }
  });

export default reportsReducer;
