import { createSelector } from 'reselect';
import { AppModules } from '../enums';
import { AppState, WordCloudState } from '../types';

const getModuleState = (state: AppState): WordCloudState =>
  state[AppModules.WordCloud];

export const getFetching = createSelector(
  getModuleState,
  (state) => state.fetching,
);

export const getWordCloudConfig = createSelector(
  getModuleState,
  (state) => state.config,
);

export const getData = createSelector(getModuleState, (state) => state.data);

export const getCount = createSelector(getModuleState, (state) => state.count);
