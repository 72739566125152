import { toast } from 'react-toastify';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { of } from 'rxjs';
import {
  catchError,
  debounceTime,
  filter,
  map,
  mergeMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { adminActions } from '../actions';
import {
  AppAction,
  NOTIFY_ROUTE_CHANGE,
  SetAdminPageAction,
  SetAdminSortColumnAction,
  SetAdminSortDirectionAction,
  SetAdminTaskAction,
  SetAdminTextFilterAction,
  SET_ADMIN_PAGE,
  SET_ADMIN_SORT_COLUMN,
  SET_ADMIN_SORT_DIRECTION,
  SET_ADMIN_TASK,
  SET_ADMIN_TEXT_FILTER,
  UserChangesRequestAction,
  USER_CHANGES_REQUEST,
} from '../actions/actionTypes';
import { adminAPI } from '../api';
import toastConfig from '../config/toast';
import { AdminTask } from '../enums';
import { serializeQuery, userAdminMapper } from '../helpers/util-functions';
import intlHelper from '../i18n/intlHelper';
import { TranslationKey } from '../i18n/translations';
import { adminSelectors } from '../selectors';
import { logger } from '../services';
import { AdminFilters, AdminUsersResponse, AppState } from '../types';

export const adminUsersRequestEpic: Epic<AppAction, AppAction, AppState> = (
  action$,
  state$,
) =>
  action$.pipe(
    ofType<
      AppAction,
      | SetAdminTaskAction
      | SetAdminPageAction
      | SetAdminSortColumnAction
      | SetAdminSortDirectionAction
      | SetAdminTextFilterAction
    >(
      SET_ADMIN_TASK,
      SET_ADMIN_PAGE,
      SET_ADMIN_SORT_COLUMN,
      SET_ADMIN_SORT_DIRECTION,
      SET_ADMIN_TEXT_FILTER,
    ),
    map(
      (): AdminFilters => ({
        task: adminSelectors.getTask(state$.value),
        page: adminSelectors.getPage(state$.value),
        sortColumn: adminSelectors.getSortColumn(state$.value),
        sortDirection: adminSelectors.getSortDirection(state$.value),
        textFilter: adminSelectors.getTextFilter(state$.value),
      }),
    ),
    debounceTime(300),
    filter(({ textFilter }) => !!textFilter),
    tap((filters) => {
      logger.info(
        'Fetching users for admin with:',
        `?${serializeQuery(filters)}`,
      );
    }),
    mergeMap((filters) =>
      adminAPI.fetchAdminUsers(filters).pipe(
        map(({ response }: { response: AdminUsersResponse }) => response),
        map(({ data, count }) => ({
          users: userAdminMapper(data),
          count,
        })),
        map(({ users, count }) => {
          logger.info('Fetched users for admin.', users, count);
          return adminActions.setUsers(users, count);
        }),
        catchError((error) => {
          logger.error('Error fetching admin users.', error);
          toast.error('Error fetching admin users.', toastConfig);
          return of(adminActions.setFetching(false));
        }),
        takeUntil(action$.pipe(ofType(NOTIFY_ROUTE_CHANGE))),
      ),
    ),
  );

const taskAPIMap: Record<
  AdminTask,
  | typeof adminAPI.createUsers
  | typeof adminAPI.updateUsers
  | typeof adminAPI.deleteUsers
> = {
  [AdminTask.Add]: adminAPI.createUsers,
  [AdminTask.Edit]: adminAPI.updateUsers,
  [AdminTask.Delete]: adminAPI.deleteUsers,
};

export const userChangesRequestEpic: Epic<AppAction, AppAction, AppState> = (
  action$,
  state$,
) =>
  action$.pipe(
    ofType<AppAction, UserChangesRequestAction>(USER_CHANGES_REQUEST),
    map(() => ({
      task: adminSelectors.getTask(state$.value),
      users: adminSelectors
        .getSelectedUsers(state$.value)
        // eslint-disable-next-line camelcase
        .map(({ id, email, role, dh_access }) => ({
          id,
          email,
          role,
          dh_access: Boolean(dh_access),
        })),
      textFilter: adminSelectors.getTextFilter(state$.value),
    })),
    filter(({ users }) => !!users.length),
    mergeMap(({ task, users, textFilter }) =>
      taskAPIMap[task](users).pipe(
        map(() => {
          logger.info('Updated users for admin.', users);
          const intl = intlHelper.getIntl();
          toast(
            intl?.formatMessage({
              id: TranslationKey.USERS_UPDATE_SUCCESS,
            }),
            toastConfig,
          );
          return adminActions.setTextFilter(textFilter);
        }),
        catchError((error) => {
          logger.error('Error updating admin users.', error);
          const intl = intlHelper.getIntl();
          toast.error(
            intl?.formatMessage({
              id: TranslationKey.USERS_UPDATE_ERROR,
            }),
            toastConfig,
          );
          return of(adminActions.setSubmitting(false));
        }),
        takeUntil(action$.pipe(ofType(NOTIFY_ROUTE_CHANGE))),
      ),
    ),
  );

export default combineEpics(adminUsersRequestEpic, userChangesRequestEpic);
