import { Container } from '@material-ui/core';
import {
  Add as AddIcon,
  Notifications as NotificationIcon,
} from '@material-ui/icons';
import React, {
  FunctionComponent,
  ReactElement,
  RefObject,
  useEffect,
  useRef,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { fromEvent } from 'rxjs';
import { throttleTime } from 'rxjs/operators';
import whitePartnersLogo from '../../assets/Loading/Logo Offboarding_Carga.svg';
import ABIButton from '../../components/ABIButton';
import AvatarContainer from '../../components/Avatar';
import { URL_SUPPORT } from '../../config/environment';
import { UserRole } from '../../enums';
import { useWindowWidth } from '../../hooks';
import { TranslationKey } from '../../i18n/translations';
import { userSessionSelectors } from '../../selectors';
import styles from './Menu.module.scss';

const BUTTON_TEXT_BREAKPOINT = 1100;

interface MenuProps {
  contentRef: RefObject<HTMLDivElement>;
}

const Menu: FunctionComponent<MenuProps> = ({ contentRef }): ReactElement => {
  const location = useLocation();

  const user = useSelector(userSessionSelectors.getUser) || {
    name: '',
    position: '',
    sharp: 0,
    email: '',
    role: '',
    dh_access: '',
  };

  const [scroll, setScroll] = useState(window.scrollY);

  useEffect(() => {
    if (!contentRef.current) return undefined;
    const contentElement = contentRef.current;
    const subscription = fromEvent(contentElement, 'scroll')
      .pipe(
        throttleTime(100, undefined, {
          leading: true,
          trailing: true,
        }),
      )
      .subscribe(() => {
        setScroll(contentElement.scrollTop);
      });

    return () => {
      subscription.unsubscribe();
    };
  }, [contentRef]);

  const abiLogoRef = useRef<HTMLImageElement>(null);
  useEffect(() => {
    if (!abiLogoRef.current) return undefined;

    const subscription = fromEvent(
      abiLogoRef.current,
      'transitionend',
    ).subscribe(() => {
      if (!abiLogoRef.current) return;
      abiLogoRef.current.classList[scroll ? 'add' : 'remove'](
        styles.logoHidden,
      );
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [scroll]);

  const windowWidth = useWindowWidth();

  return (
    <div className={styles.navMenu}>
      <Container className={styles.container}>
        <Link to={user ? '/' : '/session/login'}>
          <img
            src={whitePartnersLogo}
            alt="Maz Partners Logo"
            className={styles.logoExit}
          />
        </Link>

        {!location.pathname.startsWith('/external') && (
          <>
            <nav className={styles.navBar}>
              <ul>
                <li
                  className={
                    location.pathname === '/history/pending'
                      ? styles.menuSelected
                      : ''
                  }
                >
                  <Link to="/history/pending" className={styles.menuLink}>
                    <FormattedMessage id={TranslationKey.PENDING_INTERVIEWS} />
                  </Link>
                  <span />
                </li>
                {user?.dh_access && (
                  <li
                    className={
                      location.pathname === '/boards' ? styles.menuSelected : ''
                    }
                  >
                    <Link to="/boards" className={styles.menuLink}>
                      <FormattedMessage id={TranslationKey.BOARD} />
                    </Link>
                    <span />
                  </li>
                )}
                <li
                  className={
                    location.pathname === '/history' ? styles.menuSelected : ''
                  }
                >
                  <Link to="/history" className={styles.menuLink}>
                    <FormattedMessage id={TranslationKey.HISTORY} />
                  </Link>
                  <span />
                </li>
                <li
                  className={
                    location.pathname === '/reports' ? styles.menuSelected : ''
                  }
                >
                  <Link to="/reports" className={styles.menuLink}>
                    <FormattedMessage id={TranslationKey.REPORTS} />
                  </Link>
                  <span />
                </li>
                <li
                  className={
                    location.pathname === '/word-cloud'
                      ? styles.menuSelected
                      : ''
                  }
                >
                  <Link to="/word-cloud" className={styles.menuLink}>
                    <FormattedMessage id={TranslationKey.WORD_CLOUD} />
                  </Link>
                  <span />
                </li>
                {user?.role === UserRole.Admin && (
                  <li
                    className={
                      location.pathname.startsWith('/admin')
                        ? styles.menuSelected
                        : ''
                    }
                  >
                    <Link to="/admin" className={styles.menuLink}>
                      <FormattedMessage id={TranslationKey.ADMINISTRATION} />
                    </Link>
                    <span />
                  </li>
                )}
              </ul>
            </nav>

            <div className={styles.actionsContainer}>
              <Link to="/interview/new">
                <ABIButton type="button" className={styles.mainAction}>
                  {windowWidth >= BUTTON_TEXT_BREAKPOINT ? (
                    <FormattedMessage id={TranslationKey.APPLY_INTERVIEW} />
                  ) : (
                    <AddIcon color="inherit" />
                  )}
                </ABIButton>
              </Link>
              <Link to="/">
                <div className={styles.bellIcon}>
                  <NotificationIcon />
                  <div className={styles.activeBell} />
                </div>
              </Link>
              <AvatarContainer {...{ user }} />
            </div>
          </>
        )}
      </Container>
    </div>
  );
};

export default Menu;
