import { CircularProgress, Grid } from '@material-ui/core';
import emojiFlags from 'emoji-flags';
import { Form, Formik } from 'formik';
import React, { ReactElement, useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { reportsActions } from '../../actions';
import ABIButton from '../../components/ABIButton';
import { FormDateField, FormRadioField } from '../../components/FormFields';
import MainButton from '../../components/MainButton';
import { bigBUs, smallBUs } from '../../constants';
import {
  BusinessUnitCode,
  ChartReportType,
  EExitType,
  ReportFilterField,
  ReportFilterType,
  UserRole,
  VicePresidencyCode,
} from '../../enums';
import { getChartType, getReportTickType } from '../../helpers/util-functions';
import { TranslationKey } from '../../i18n/translations';
import { countriesWithZone } from '../../maps';
import { reportsSelectors, userSessionSelectors } from '../../selectors';
import isoCountries from '../../services/isoCountries';
import { ReportFilterValues } from '../../types';
import TranslateFormikErrors from '../TranslateFormikErrors';
import FormValueSync from './FormValueSync';
import OwnerBigFilter from './OwnerBigFilter';
import OwnerSmallFilter from './OwnerSmallFilter';
import RegularFilter from './RegularFilter';
import styles from './Reports.module.scss';
import { getChartValidationSchema } from './schemas';
import SuperAdminFilter from './SuperAdminFilter';

const initialValues: ReportFilterValues = {
  [ReportFilterField.FilterType]: ReportFilterType.Chart,
  [ReportFilterField.ReportType]: '',
  [ReportFilterField.ExitType]: '',
  [ReportFilterField.From]: '',
  [ReportFilterField.Until]: '',
  [ReportFilterField.AllZone]: false,
  [ReportFilterField.BusinessUnits]: [],
  [ReportFilterField.AllBU]: false,
  [ReportFilterField.Countries]: [],
  [ReportFilterField.VicePresidencies]: [],
  [ReportFilterField.UENs]: [],
};

const ChartFilters = (): ReactElement => {
  const intl = useIntl();

  const userRole = useSelector(userSessionSelectors.getRole);
  const userBU = useSelector(userSessionSelectors.getUserBU);

  const dispatch = useDispatch();
  const handleSubmit = useCallback(
    (values: ReportFilterValues) => {
      dispatch(reportsActions.setFetching(true));
      dispatch(
        reportsActions.setReportConfig({
          chartType: getChartType(values),
          [ReportFilterField.ReportType]: values[
            ReportFilterField.ReportType
          ] as ChartReportType,
          [ReportFilterField.From]: values[ReportFilterField.From] as Date,
          [ReportFilterField.Until]:
            values[ReportFilterField.Until] || new Date(),
          tickType: getReportTickType(values),
        }),
      );

      dispatch(
        reportsActions.fetchReportData({
          ...values,
          [ReportFilterField.Until]:
            values[ReportFilterField.Until] || new Date(),
        }),
      );
    },
    [dispatch],
  );

  const validationSchema = useMemo(
    () => getChartValidationSchema(intl, userRole, userBU),
    [intl, userRole, userBU],
  );

  const reportTypeOptions = useMemo(
    () =>
      Object.values(ChartReportType).map((value) => ({
        label: intl.formatMessage({
          id: `REPORT_TYPE_${value}`,
        }),
        value,
      })),
    [intl],
  );

  const exitTypeOptions = useMemo(
    () =>
      Object.values(EExitType).map((value) => ({
        value,
        label: intl.formatMessage({
          id: value,
        }),
      })),
    [intl],
  );

  const businessUnitOptions = useMemo(
    () =>
      Object.values(BusinessUnitCode).map((code) => ({
        value: code,
        label: intl.formatMessage({
          id: `BUSINESS_UNIT_${code}`,
        }),
      })),
    [intl],
  );

  const countryOptions = useMemo(
    () =>
      countriesWithZone
        .filter(({ businessUnit }) =>
          [BusinessUnitCode.CAC, BusinessUnitCode.HondurasAndSalvador].includes(
            businessUnit,
          ),
        )
        .map(({ country }) => ({
          value: country,
          label: `${
            emojiFlags.countryCode(country)?.emoji || ''
          } ${isoCountries.getName(country, intl.locale)}`,
        })),
    [intl.locale],
  );

  const vicePresidencyOptions = useMemo(
    () =>
      Object.values(VicePresidencyCode).map((vpCode) => ({
        value: vpCode,
        label: intl.formatMessage({
          id: `VP_${vpCode}`,
        }),
      })),
    [intl],
  );

  const fetching = useSelector(reportsSelectors.getFetching);

  return (
    <div className={styles.filterCard}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        autoComplete="off"
      >
        <Form noValidate autoComplete="off">
          <TranslateFormikErrors />
          <FormValueSync />

          <h3 className={`${styles.filterStep} ${styles.required}`}>
            1. <FormattedMessage id={TranslationKey.PARAMETER} />
          </h3>

          <FormRadioField
            formClassName={styles.radioForm}
            name={ReportFilterField.ReportType}
            options={reportTypeOptions}
            disabled={fetching}
          />

          <h3 className={`${styles.filterStep} ${styles.required}`}>
            2. <FormattedMessage id={TranslationKey.EXIT_TYPE} />
          </h3>

          <FormRadioField
            formClassName={styles.radioForm}
            name={ReportFilterField.ExitType}
            options={exitTypeOptions}
            disabled={fetching}
          />

          <h3 className={`${styles.filterStep} ${styles.required}`}>
            3. <FormattedMessage id={TranslationKey.PERIOD} />
          </h3>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormDateField
                name={ReportFilterField.From}
                label={intl.formatMessage({
                  id: TranslationKey.FROM,
                })}
                maxDate={new Date()}
                disabled={fetching}
              />
            </Grid>

            <Grid item xs={6}>
              <FormDateField
                name={ReportFilterField.Until}
                label={intl.formatMessage({
                  id: TranslationKey.UNTIL,
                })}
                maxDate={new Date()}
                disabled={fetching}
              />
            </Grid>
          </Grid>

          {userRole === UserRole.Admin && (
            <SuperAdminFilter
              businessUnitOptions={businessUnitOptions}
              vicePresidencyOptions={vicePresidencyOptions}
            />
          )}

          {[UserRole.Regular, UserRole.CountryOwner].includes(userRole) && (
            <RegularFilter vicePresidencyOptions={vicePresidencyOptions} />
          )}

          {bigBUs.includes(userBU) && userRole === UserRole.Owner && (
            <OwnerBigFilter vicePresidencyOptions={vicePresidencyOptions} />
          )}

          {smallBUs.includes(userBU) && userRole === UserRole.Owner && (
            <OwnerSmallFilter
              vicePresidencyOptions={vicePresidencyOptions}
              countryOptions={countryOptions}
            />
          )}

          <div className={styles.buttonContainer}>
            <ABIButton type="submit" buttonType="cancel" disabled={fetching}>
              {fetching && (
                <CircularProgress
                  size="14px"
                  color="inherit"
                  thickness={6}
                  className={styles.buttonSpinner}
                />
              )}
              <FormattedMessage
                id={
                  fetching
                    ? TranslationKey.GENERATING
                    : TranslationKey.GENERATE_CHART
                }
              />
            </ABIButton>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default ChartFilters;
