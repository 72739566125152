import { Container, Grid } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import instructionsImage from '../../../assets/images/interview-instructions-bg.png';
import { TranslationKey } from '../../../i18n/translations';
import ABIButton from '../../ABIButton';
import styles from './Instructions.module.scss';

const Instructions = (): ReactElement => {
  const navigate = useNavigate();

  const onClick = () => {
    navigate('/interview/questionary');
  };
  const onReturnClick = () => {
    navigate(-1);
  };

  return (
    <div className={styles.container}>
      <Container>
        <Grid container>
          <Grid item xs={12} md={6} className={styles.text}>
            <h2 className={styles.title}>
              <FormattedMessage
                id={TranslationKey.INTERVIEW_INSTRUCTIONS_TITLE}
              />
            </h2>

            <p>
              <FormattedMessage
                id={TranslationKey.INTERVIEW_INSTRUCTIONS_HEAD}
              />
            </p>

            <ul>
              <li>
                <FormattedMessage
                  id={TranslationKey.INTERVIEW_INSTRUCTIONS_POINT_1}
                />
              </li>
              <li>
                <FormattedMessage
                  id={TranslationKey.INTERVIEW_INSTRUCTIONS_POINT_2}
                />
              </li>
              <li>
                <FormattedMessage
                  id={TranslationKey.INTERVIEW_INSTRUCTIONS_POINT_3}
                />
              </li>
            </ul>

            <p>
              <FormattedMessage
                id={TranslationKey.INTERVIEW_INSTRUCTIONS_BOTTOM}
              />
            </p>
          </Grid>

          <Grid item xs={12} md={6} className={styles.imageContainer}>
            <img
              src={instructionsImage}
              alt="Hand shaking characters"
              className={styles.image}
            />
          </Grid>
        </Grid>

        <Grid item xs={12} md={7}>
          <div className={styles.buttonContainer}>
            <ABIButton buttonType="return" onClick={onReturnClick}>
              <FormattedMessage id={TranslationKey.BACK} />
            </ABIButton>
            <ABIButton {...{ onClick }}>
              <FormattedMessage id={TranslationKey.CONTINUE} />
            </ABIButton>
          </div>
        </Grid>
      </Container>
    </div>
  );
};

export default Instructions;
