import { useFormikContext } from 'formik';
import { FunctionComponent, useEffect } from 'react';
import { QuestionaryFieldPrefix } from '../../enums';
import { IAnswer, IQuestion } from '../../types';

interface FocusErrorsProps {
  questions: IQuestion[];
}

type ErrorValues = Record<QuestionaryFieldPrefix, IAnswer>;

const FocusErrors: FunctionComponent<FocusErrorsProps> = ({
  questions,
}): null => {
  const { isSubmitting, isValid, errors } = useFormikContext();

  useEffect(() => {
    if (isValid || !isSubmitting) return;
    const errorKeys = Object.keys(errors);

    if (errorKeys.length) {
      const firstQuestionErrorKey = errorKeys[0] as QuestionaryFieldPrefix;
      const questionError = (errors as ErrorValues)[firstQuestionErrorKey];
      const innerErrorKeys = Object.keys(questionError);
      const firstInnerErrorKey = innerErrorKeys[0];
      const id = `${firstQuestionErrorKey}-${firstInnerErrorKey}`;
      const element = document.getElementById(id);
      if (element) {
        setTimeout(() => {
          element.scrollIntoView({
            block: 'center',
            behavior: 'smooth',
          });
        }, 100);
      }
    }
  }, [errors, isSubmitting, isValid, questions]);

  return null;
};

export default FocusErrors;
