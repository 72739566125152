import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { ReportFilterField } from '../../enums';
import { ReportFilterValues } from '../../types';

const FormValueSync = (): null => {
  const formikContext = useFormikContext<ReportFilterValues>();
  const setFieldValue = formikContext.setFieldValue.bind(formikContext);

  const { values } = formikContext;
  const from = values[ReportFilterField.From];
  const until = values[ReportFilterField.Until];

  /**
   * Handle `from` after `until`
   */
  useEffect(() => {
    if (!from || !until) return;

    if (from > until) {
      setFieldValue(ReportFilterField.Until, from);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [from, setFieldValue]);

  /**
   * Handle `until` before `from`
   */
  useEffect(() => {
    if (!from || !until) return;

    if (until < from) {
      setFieldValue(ReportFilterField.From, until);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [from, setFieldValue]);

  return null;
};

export default FormValueSync;
