import { Grid } from '@material-ui/core';
import { useFormikContext } from 'formik';
import React, { FunctionComponent, ReactElement } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import {
  FormAutocompleteField,
  FormCheckboxField,
  FormMultipleSelectField,
} from '../../components/FormFields';
import { apiURL } from '../../config/environment';
import { ReportFilterField } from '../../enums';
import { TranslationKey } from '../../i18n/translations';
import { reportsSelectors } from '../../selectors';
import { ReportFilterValues, SelectOption } from '../../types';
import styles from './WordCloud.module.scss';

interface OwnerBigFilterProps {
  vicePresidencyOptions: SelectOption[];
}

const OwnerBigFilter: FunctionComponent<OwnerBigFilterProps> = ({
  vicePresidencyOptions,
}): ReactElement => {
  const formikContext = useFormikContext<ReportFilterValues>();
  const setFieldValue = formikContext.setFieldValue.bind(formikContext);

  const intl = useIntl();
  const fetching = useSelector(reportsSelectors.getFetching);

  return (
    <>
      <h3 className={`${styles.filterStep} ${styles.required}`}>4. Scope</h3>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormCheckboxField
            name={ReportFilterField.AllBU}
            label="BU"
            onValueChange={(checked: boolean): void => {
              if (!checked) return;
              setFieldValue(ReportFilterField.VicePresidencies, []);
              setFieldValue(ReportFilterField.UENs, []);
            }}
            disabled={fetching}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormMultipleSelectField
            name={ReportFilterField.VicePresidencies}
            label={intl.formatMessage({
              id: TranslationKey.VP_SHORT,
            })}
            options={vicePresidencyOptions}
            onValueChange={() => {
              setFieldValue(ReportFilterField.AllBU, false);
              setFieldValue(ReportFilterField.UENs, []);
            }}
            disabled={fetching}
            showAll
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormAutocompleteField
            name={ReportFilterField.UENs}
            label={intl.formatMessage({
              id: TranslationKey.UEN,
            })}
            apiURL={`${apiURL}/auth/autocomplete/uen`}
            onValueChange={() => {
              setFieldValue(ReportFilterField.AllBU, false);
              setFieldValue(ReportFilterField.VicePresidencies, []);
            }}
            disabled={fetching}
            multiple
          />
        </Grid>
      </Grid>
    </>
  );
};

export default OwnerBigFilter;
