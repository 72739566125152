/* eslint-disable @typescript-eslint/ban-ts-comment */
import { shouldPolyfill } from '@formatjs/intl-datetimeformat/should-polyfill';

export default async function polyfill(): Promise<void> {
  if (shouldPolyfill()) {
    // Load the polyfill 1st BEFORE loading data
    await import('@formatjs/intl-datetimeformat/polyfill');
  }

  // @ts-ignore
  if (Intl.DateTimeFormat.polyfilled) {
    // @ts-ignore
    await import('@formatjs/intl-datetimeformat/locale-data/en');
    // @ts-ignore
    await import('@formatjs/intl-datetimeformat/locale-data/fr');
  }
}
