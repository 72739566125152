import { CircularProgress, Grid } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import { Form, Formik } from 'formik';
import React, {
  ReactElement,
  ReactNode,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { interviewActions } from '../../actions';
import ABIButton from '../../components/ABIButton';
import ConfirmModal from '../../components/ConfirmModal';
import ContentTitle from '../../components/ContentTitle';
import LoadingSpinner from '../../components/LoadingSpinner';
import MainButton from '../../components/MainButton';
import Question from '../../components/Question';
import { FeedbackFieldPrefix } from '../../enums';
import { TranslationKey } from '../../i18n/translations';
import { commonSelectors, interviewSelectors } from '../../selectors';
import { FeedbackValues, IAnswer } from '../../types';
import TranslateFormikErrors from '../TranslateFormikErrors';
import DynamicQuestions from './DynamicQuestions';
import styles from './Feedback.module.scss';
import FetchStoredFeedback from './FetchStoredFeedback';
import SavePartialFeedback from './SavePartialFeedback';
import getValidationSchema from './schema';

const field: IAnswer = {
  answer: '',
  comments: '',
};

const emptyValues: FeedbackValues = {
  [FeedbackFieldPrefix.GeneralFeedback]: field,
  [FeedbackFieldPrefix.ActionPlan]: field,
};

const Feedback = (): ReactElement => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const valuesRef = useRef<FeedbackValues>();

  const [openedModal, setOpenedModal] = useState(false);

  const handleNoClick = useCallback(() => {
    setOpenedModal(false);
  }, []);

  const handleYesClick = useCallback(() => {
    if (!valuesRef.current) return;
    setOpenedModal(false);
    dispatch(interviewActions.saveFeedbackRequest(valuesRef.current));
  }, [dispatch]);
  const onReturnClick = () => {
    navigate('/');
  };

  const validationSchema = useMemo(() => getValidationSchema(intl), [intl]);

  const handleSubmit = useCallback((values: FeedbackValues) => {
    valuesRef.current = values;
    setOpenedModal(true);
  }, []);

  const [fetchingFeedback, setFetchingFeedback] = useState(true);
  const sending = useSelector(interviewSelectors.getSending);
  const collaborator = useSelector(interviewSelectors.getCollaborator);
  const canEditInterview = useSelector(commonSelectors.canEditInterview);

  return (
    <div className={styles.container}>
      <ConfirmModal isOpen={openedModal}>
        <h2 className={styles.modalTitle}>
          <FormattedMessage id={TranslationKey.ARE_YOU_SURE} />
        </h2>
        <p className={styles.modalText}>
          <FormattedMessage id={TranslationKey.FEEDBACK_CONFIRMATION} />
        </p>
        <div className={styles.modalButtons}>
          <ABIButton buttonType="cancel" onClick={handleNoClick}>
            <FormattedMessage id={TranslationKey.NO} />
          </ABIButton>
          <ABIButton onClick={handleYesClick}>
            <FormattedMessage id={TranslationKey.YES} />
          </ABIButton>
        </div>
      </ConfirmModal>

      <Container>
        <Formik
          initialValues={emptyValues}
          initialErrors={emptyValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          autoComplete="off"
        >
          {({ isValid }) => (
            <Form noValidate autoComplete="off">
              <TranslateFormikErrors />

              <FetchStoredFeedback setFetching={setFetchingFeedback} />
              {!fetchingFeedback && <SavePartialFeedback />}

              <Grid
                container
                direction="column"
                justify="center"
                alignContent="center"
                spacing={1}
              >
                <div className={styles.formContainer}>
                  <p className={styles.explanation}>
                    <ContentTitle className={styles.title}>
                      <FormattedMessage id={TranslationKey.FEEDBACK_TITLE} />
                    </ContentTitle>
                    <FormattedMessage
                      id={TranslationKey.FEEDBACK_EXPLANATION}
                      values={{
                        b: (...chunks: ReactNode[]): ReactElement => (
                          <b>{chunks}</b>
                        ),
                      }}
                    />
                  </p>

                  <DynamicQuestions name={collaborator}>
                    {({ questions, fetching }) =>
                      fetching ? (
                        <div className={styles.spinnerContainer}>
                          <LoadingSpinner />
                        </div>
                      ) : (
                        <Grid item xs={12}>
                          {questions.map((question) => (
                            <Grid key={question.fieldPrefix} item xs={12}>
                              <Question
                                {...question}
                                disabled={
                                  fetchingFeedback ||
                                  sending ||
                                  !canEditInterview
                                }
                                loading={fetchingFeedback}
                              />
                            </Grid>
                          ))}
                        </Grid>
                      )
                    }
                  </DynamicQuestions>
                </div>

                <Grid
                  container
                  item
                  xs={12}
                  justify="center"
                  alignItems="center"
                >
                  {canEditInterview && (
                    <div className={styles.buttonContainer}>
                      <ABIButton buttonType="cancel" onClick={onReturnClick}>
                        <FormattedMessage id={TranslationKey.EXIT} />
                      </ABIButton>
                      <ABIButton type="submit" disabled={!isValid || sending}>
                        <FormattedMessage id={TranslationKey.FEEDBACK_BUTTON} />
                      </ABIButton>
                    </div>
                  )}
                </Grid>

                {sending && (
                  <Grid
                    container
                    item
                    xs={12}
                    justify="center"
                    alignItems="center"
                    className={styles.buttonContainer}
                  >
                    <CircularProgress
                      color="primary"
                      thickness={6}
                      size="5em"
                    />
                  </Grid>
                )}
              </Grid>
            </Form>
          )}
        </Formik>
      </Container>
    </div>
  );
};

export default Feedback;
