import { SortDirection } from '../enums';
import {
  HistoryFilterValues,
  HistoryInterview,
  IAdditionalFilters,
} from '../types';
import * as types from './actionTypes';

export const initializeHistory = (): types.InitializeHistoryAction => ({
  type: types.INITIALIZE_HISTORY,
});

export const setPage = (page: number): types.SetHistoryPageAction => ({
  type: types.SET_HISTORY_PAGE,
  page,
});

export const setSortColumn = (
  sortColumn: string,
): types.SetHistorySortColumnAction => ({
  type: types.SET_HISTORY_SORT_COLUMN,
  sortColumn,
});

export const setSortDirection = (
  sortDirection: SortDirection,
): types.SetHistorySortDirectionAction => ({
  type: types.SET_HISTORY_SORT_DIRECTION,
  sortDirection,
});

export const setFilters = (
  filters: HistoryFilterValues,
): types.SetHistoryFiltersAction => ({
  type: types.SET_HISTORY_FILTERS,
  filters,
});

export const setAdditionalFilters = (
  additionalFilters: IAdditionalFilters,
): types.SetHistoryAdditionalFiltersAction => ({
  type: types.SET_HISTORY_ADDITIONAL_FILTERS,
  additionalFilters,
});

export const setOnlyPending = (
  onlyPending: boolean,
): types.SetOnlyPendingAction => ({
  type: types.SET_ONLY_PENDING,
  onlyPending,
});

export const setExpanded = (
  expanded: boolean,
): types.SetHistoryExpandedAction => ({
  type: types.SET_HISTORY_EXPANDED,
  expanded,
});

export const setFetchingHistory = (
  fetching: boolean,
): types.SetFetchingHistoryAction => ({
  type: types.SET_FETCHING_HISTORY,
  fetching,
});

export const setHistoryInterviews = (
  interviews: HistoryInterview[],
  count: number,
): types.SetHistoryInterviewsAction => ({
  type: types.SET_HISTORY_INTERVIEWS,
  interviews,
  count,
});

export const toggleInterviewSelected = (
  id: number,
): types.ToggleInterviewSelectedAction => ({
  type: types.TOGGLE_INTERVIEW_SELECTED,
  id,
});

export const setAllInterviewSelection = (
  selected: boolean,
): types.SetAllInterviewsSelectionAction => ({
  type: types.SET_ALL_INTERVIEWS_SELECTION,
  selected,
});

export const downloadInterviewsRequest = (): types.DownloadInterviewsRequestAction => ({
  type: types.DOWNLOAD_INTERVIEWS_REQUEST,
});

export const setDownloadingInterviews = (
  downloading: boolean,
): types.SetDownloadingInterviewsAction => ({
  type: types.SET_DOWNLOADING_INTERVIEWS,
  downloading,
});

export const clearState = (): types.ClearHistoryStateAction => ({
  type: types.CLEAR_HISTORY_STATE,
});
