import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { NewInterviewField, QuestionaryFieldPrefix } from '../../enums';
import { NewInterviewValues } from '../../types';

const ValueCorrections = (): null => {
  const { values, setFieldValue } = useFormikContext<NewInterviewValues>();
  // Clear Exit Motive when changing the exit type.
  const exitType = values[NewInterviewField.ExitType];
  useEffect(() => {
    setFieldValue(QuestionaryFieldPrefix.MainExitMotive, {
      answer: '',
      comments: '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exitType]);
  return null;
};

export default ValueCorrections;
