import { useFormikContext } from 'formik';
import React, {
  FunctionComponent,
  ReactElement,
  ReactNode,
  useMemo,
} from 'react';
import { useIntl } from 'react-intl';
import { BooleanSelection, QuestionaryType } from '../../enums';
import { useFetchQuestions } from '../../hooks';
import { TranslationKey } from '../../i18n/translations';
import { IQuestion } from '../../types';

interface FeedbackPartialValues {
  actionPlan: {
    answer: string | BooleanSelection;
    comments: string;
  };
  fetched: boolean;
}

interface DynamicQuestionsProps {
  children: (props: { questions: IQuestion[]; fetching: boolean }) => ReactNode;
  name?: string;
}

const DynamicQuestions: FunctionComponent<DynamicQuestionsProps> = ({
  children,
  name,
}): ReactElement => {
  const { values } = useFormikContext<FeedbackPartialValues>();
  const ans = values.actionPlan?.answer;

  const intl = useIntl();
  const [fetching, rawQuestions] = useFetchQuestions(
    QuestionaryType.Feedback,
    intl,
  );

  const questions = useMemo(
    () =>
      rawQuestions.map(
        (question): IQuestion => {
          if (question.fieldPrefix !== 'actionPlan') {
            return {
              ...question,
              question: question.question.replace('{name}', name || ''),
            };
          }

          const partialQuestion = {
            ...question,
            withComments: false,
          };
          if (ans === '') return partialQuestion;

          let restOfQuestion = {};
          if (ans === BooleanSelection.Yes) {
            restOfQuestion = {
              explanation: intl.formatMessage({
                id: TranslationKey.FEEDBACK_ACTION_PRE_EXPLANATION,
              }),
              postExplanation: intl.formatMessage({
                id: TranslationKey.FEEDBACK_ACTION_POST_EXPLANATION,
              }),
              withComments: true,
            };
          } else if (ans === BooleanSelection.No) {
            restOfQuestion = {
              explanation: intl.formatMessage({
                id: TranslationKey.JUSTIFICATION,
              }),
              withComments: true,
            };
          }

          return {
            ...partialQuestion,
            ...restOfQuestion,
          };
        },
      ),
    [ans, intl, name, rawQuestions],
  );

  return (
    <>
      {children({
        fetching,
        questions,
      })}
    </>
  );
};

export default DynamicQuestions;
