import { combineReducers } from 'redux';
import admin from './admin';
import history from './history';
import interview from './interview';
import reports from './reports';
import userSession from './userSession';
import wordCloud from './wordCloud';

const rootReducer = combineReducers({
  admin,
  history,
  interview,
  reports,
  userSession,
  wordCloud,
});

export default rootReducer;
