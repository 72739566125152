import { GetApp as GetAppIcon } from '@material-ui/icons';
import html2canvas from 'html2canvas';
import React, { ReactElement, useCallback, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import 'react-vis/dist/style.css';
import ABIButton from '../../components/ABIButton';
import LoadingSpinner from '../../components/LoadingSpinner';
import LoadingPage from '../../components/LoadingSpinner/LoadingPage';
import MainButton from '../../components/MainButton';
import { Breakpoint, ChartType } from '../../enums';
import {
  downloadWithFileURL,
  setInlineStyles,
} from '../../helpers/util-functions';
import { useWindowWidth } from '../../hooks';
import { TranslationKey } from '../../i18n/translations';
import { reportsSelectors } from '../../selectors';
import BarChart from './BarChart';
import DonutChart from './DonutChart';
import styles from './ReportChart.module.scss';

const ReportChart = (): ReactElement | null => {
  const chartRef = useRef<HTMLDivElement>(null);
  const windowWidth = useWindowWidth();

  const smallViewport = windowWidth > Breakpoint.Small;
  const chartWidth = smallViewport ? 700 : 350;
  const chartHeight = smallViewport ? 300 : 150;

  const handleDownloadClick = useCallback(async () => {
    if (!chartRef.current) return;
    // Element must be visible in order to render correctly
    chartRef.current.scrollIntoView({
      block: 'center',
    });

    // Styles must be inline for `html2canvas`
    setInlineStyles(chartRef.current);

    // Generate canvas
    const area = chartRef.current.getBoundingClientRect();
    const canvas = await html2canvas(chartRef.current, {
      scrollX: 0,
      scrollY: 0,
      width: area.width,
      height: area.height,
    });
    downloadWithFileURL('chart.png', canvas.toDataURL());
  }, []);

  const fetching = useSelector(reportsSelectors.getFetching);
  const reportData = useSelector(reportsSelectors.getReportData);
  const { chartType } = useSelector(reportsSelectors.getConfig) || {};

  if (!reportData && !fetching) return null;

  return fetching ? (
    <LoadingSpinner />
  ) : (
    <div className={styles.chartBox}>
      {chartType === ChartType.Bar && (
        <BarChart ref={chartRef} width={chartWidth} height={chartHeight} />
      )}

      {chartType === ChartType.Donut && (
        <DonutChart
          ref={chartRef}
          size={smallViewport ? chartHeight : chartWidth}
        />
      )}

      <ABIButton buttonType="cancel" onClick={handleDownloadClick}>
        <GetAppIcon
          style={{
            fontSize: 16,
          }}
        />
        <FormattedMessage id={TranslationKey.DOWNLOAD_IMAGE} />
      </ABIButton>
    </div>
  );
};

export default ReportChart;
