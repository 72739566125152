import { Container } from '@material-ui/core';
import React, { ReactElement, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Outlet, useLocation, useNavigate } from 'react-router';
import { TranslationKey } from '../i18n/translations';

import ContentTitle from '../components/ContentTitle';
import StepperComponent from '../components/Stepper';

import styles from './InterviewStatus.module.scss';

/**
 * Redirects interview to the correct step based on its status
 */
const InterviewStatus = (): ReactElement => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const p = pathname.split('/')[2];
  const hasSub = Boolean(p);
  const isNew = hasSub && p === 'new';

  let activeStep = 0;
  let stepWidth = 33;

  if (p === 'questionary' || p === 'instructions') {
    activeStep = 1;
    stepWidth = 66;
  } else if (p === 'pre-feedback' || p === 'feedback') {
    activeStep = 2;
    stepWidth = 100;
  }

  useEffect(() => {
    if (pathname === '/interview') {
      navigate('/interview/new');
    }
  }, [pathname, navigate]);

  return (
    <>
      {hasSub && (
        <>
          <div className={styles.headerSuperContainer}>
            <Container>
              <div
                className={`${styles.newInterviewHeader} ${
                  !isNew ? styles.notNewHeader : ''
                }`}
              >
                {isNew && (
                  <ContentTitle>
                    <FormattedMessage id={TranslationKey.NEW_INTERVIEW} />
                    <span>
                      <FormattedMessage id={TranslationKey.INTERVIEW_DESC} />
                    </span>
                  </ContentTitle>
                )}
                <StepperComponent {...{ activeStep }} />
              </div>
            </Container>
          </div>
          <div className={styles.progressBar}>
            <div style={{ width: `${stepWidth}%` }} />
          </div>
        </>
      )}
      <Outlet />
    </>
  );
};

export default InterviewStatus;
