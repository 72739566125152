import { IntlShape } from 'react-intl';
import * as yup from 'yup';
import { QuestionaryFieldPrefix } from '../../enums';
import { TranslationKey } from '../../i18n/translations';

const getValidationSchema = (intl: IntlShape): yup.SchemaOf<unknown> => {
  const requiredMessage = intl.formatMessage({
    id: TranslationKey.VALIDATE_REQUIRED_GENERIC,
  });

  const requiredAnswerSchema = yup.object().shape({
    answer: yup.string().nullable().required(requiredMessage),
    comments: yup.string().nullable(),
  });

  const plainSchema = yup.object().shape({
    answer: yup.string().nullable(),
    comments: yup.string().nullable(),
  });

  return yup.object().shape({
    [QuestionaryFieldPrefix.AdditionalExitMotives]: requiredAnswerSchema,
    [QuestionaryFieldPrefix.NewPositionImprovements]: plainSchema,
    [QuestionaryFieldPrefix.RecommendsABI]: requiredAnswerSchema,
    [QuestionaryFieldPrefix.ProfessionalGrowth]: requiredAnswerSchema,
    [QuestionaryFieldPrefix.MotivationLevel]: requiredAnswerSchema,
    [QuestionaryFieldPrefix.BurnoutLevel]: requiredAnswerSchema,
    [QuestionaryFieldPrefix.WorkArea]: requiredAnswerSchema,
    [QuestionaryFieldPrefix.RewardsCommunication]: requiredAnswerSchema,
    [QuestionaryFieldPrefix.UnderstoodJobResponsibilities]:
      requiredAnswerSchema,
    [QuestionaryFieldPrefix.TargetAchievementImpact]: requiredAnswerSchema,
    [QuestionaryFieldPrefix.RecognizedByContributions]: requiredAnswerSchema,
    [QuestionaryFieldPrefix.TrainingAndDevelopment]: requiredAnswerSchema,
    [QuestionaryFieldPrefix.CompanyMatters]: requiredAnswerSchema,
    [QuestionaryFieldPrefix.NoGrowthDiscrimination]: requiredAnswerSchema,
    [QuestionaryFieldPrefix.RespectedOpinions]: requiredAnswerSchema,
    [QuestionaryFieldPrefix.AllowedSmartRisks]: requiredAnswerSchema,
    [QuestionaryFieldPrefix.LeadershipFeedbackResponse]: requiredAnswerSchema,
    [QuestionaryFieldPrefix.WhatCouldWeHaveDone]: plainSchema,
    [QuestionaryFieldPrefix.NewJobDifference]: plainSchema,
    [QuestionaryFieldPrefix.NewInstitutionName]: plainSchema,
    [QuestionaryFieldPrefix.NewJobPackage]: plainSchema,
    [QuestionaryFieldPrefix.AnythingElse]: plainSchema,
    [QuestionaryFieldPrefix.Email]: yup.object().shape({
      answer: yup
        .string()
        .nullable()
        .email(
          intl.formatMessage({
            id: TranslationKey.VALIDATE_EMAIL,
          }),
        ),
      comments: yup.string().nullable(),
    }),
    [QuestionaryFieldPrefix.PhoneNumber]: plainSchema,
    [QuestionaryFieldPrefix.Critical]: requiredAnswerSchema,
  });
};

export default getValidationSchema;
