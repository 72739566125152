import Icon from '@material-ui/core/Icon';
import React, { ReactElement, useState } from 'react';
import './BannerMorePeople.scss';


const BannerMorePeople = (): ReactElement => {

  const [ show, setShow ] = useState(true);

  function close(){
    setShow(false);
  }

  return (
    <div className="banner-message">
      {show && 
        <div className="container-fluid">
          <div className="content-message alert fade show fade show"  role="alert">
              <div className="main-logos-apps">
                  <span className="logos-apps logo-app"> </span>
                  <span className="arrow-app"> </span>
                  <span className="logos-apps logo-app-people"> </span>
                  <div className='message-text'>
                    Exit Interview evolucionó a +People Offboarding, pronto nuestras herramientas se actualizarán. ¡Espéralo!
                  </div>
              </div>
              <div className='btn-close'>
                    <Icon  btn-close fontSize="medium" onClick={close}>close</Icon>
              </div>
          </div>
      </div> 
      }
    </div>
  );
}
export default BannerMorePeople;
