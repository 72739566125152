import React, {
  FunctionComponent,
  ReactElement,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { Router } from 'react-router';
import { commonActions } from '../actions';
import history from '../history';

interface RouterWrapperProps {
  children: ReactNode;
}

const RouterWrapper: FunctionComponent<RouterWrapperProps> = ({
  children,
}): ReactElement => {
  const [location, setLocation] = useState(history.location);
  const dispatch = useDispatch();

  useEffect(() => {
    const unListen = history.listen((update) => {
      window.scrollTo(0, 0);
      dispatch(commonActions.notifyRouteChange(update.location.pathname));
      setLocation(update.location);
    });
    return () => {
      unListen();
    };
  }, [dispatch]);

  return (
    <Router navigator={history} location={location}>
      {children}
    </Router>
  );
};

export default RouterWrapper;
