import { Avatar, Box, Popper } from '@material-ui/core';
import React, { useState, MouseEvent, useCallback } from 'react';
import { v4 } from 'uuid';
import { FormattedMessage } from 'react-intl';
import { HelpOutline, ExitToApp } from '@material-ui/icons';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { TranslationKey } from '../../i18n/translations';
import { userSessionActions } from '../../actions';

import styles from './Avatar.module.scss';

type AvatarContainerProps = {
  user: { name: string; position: string; sharp: number; email: string };
};

const AvatarContainer = ({ user }: AvatarContainerProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const name = user?.name || '';
  const position = user?.position || '';
  const sharp = user?.sharp || 0;
  const email = user?.email || '';

  const onClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const onCloseClick = useCallback(() => {
    dispatch(userSessionActions.logoutUserRequest());
    setAnchorEl(null);
    navigate('/');
  }, [dispatch, navigate]);

  const id = v4();
  const open = Boolean(anchorEl);

  return (
    <>
      <Avatar
        {...{ onClick, open }}
        className={styles.miniAvatar}
        aria-describedby={id}
        alt={name}
      >
        {name
          ?.split(' ')
          .map((part) => part[0])
          .slice(0, 2)
          .join('')}
      </Avatar>
      <Popper {...{ id, open, anchorEl }} className={styles.popperContainer}>
        <Box className={styles.popperBox}>
          <div className={styles.popperAvatarInfo}>
            <Avatar className={styles.popperAvatar} alt={name}>
              {name
                ?.split(' ')
                .map((part) => part[0])
                .slice(0, 2)
                .join('')}
            </Avatar>
            <div className={styles.popperAvatarDescriptor}>
              <h3>{name}</h3>
              <p>{position}</p>
              <p>{sharp}</p>
              <p>{email}</p>
            </div>
          </div>
          <ul className={styles.popperItemList}>
            <li>
              <Link to="/help">
                <HelpOutline />
                <span>
                  <FormattedMessage id={TranslationKey.HELP} />
                </span>
              </Link>
            </li>
            <li>
              <button type="button" onClick={onCloseClick}>
                <ExitToApp />
                <span>
                  <FormattedMessage id={TranslationKey.CLOSE_SESSION} />
                </span>
              </button>
            </li>
          </ul>
        </Box>
      </Popper>
    </>
  );
};

export default AvatarContainer;
