import { createSelector } from 'reselect';
import { AppModules } from '../enums';
import { AppState, InterviewState } from '../types';

const getModuleState = (state: AppState): InterviewState =>
  state[AppModules.Interview];

export const getSending = createSelector(
  getModuleState,
  (state) => state.sending,
);

export const getSaving = createSelector(
  getModuleState,
  (state) => state.saving,
);

export const getId = createSelector(getModuleState, (state) => state.id);

export const getInterviewerId = createSelector(
  getModuleState,
  (state) => state.interviewerId,
);

export const getCollaborator = createSelector(
  getModuleState,
  (state) => state.collaborator,
);

export const getStatus = createSelector(
  getModuleState,
  (state) => state.status,
);
