import { useFormikContext } from 'formik';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { userSessionSelectors } from '../selectors';

/**
 * Updates error field translations on locale change
 */
const TranslateFormikErrors = (): null => {
  const locale = useSelector(userSessionSelectors.getLocale);
  const { touched, setTouched } = useFormikContext();
  const mountedRef = useRef<boolean>(false);

  useEffect(() => {
    if (!mountedRef.current) {
      mountedRef.current = true;
      return;
    }
    /**
     * The `setTimeout` (push to the end of the event queue)
     * is needed so it waits for the locale to change.
     */
    setTimeout(() => {
      setTouched(touched);
    }, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  return null;
};

export default TranslateFormikErrors;
