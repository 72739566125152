import React, { FunctionComponent, ReactElement, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import styles from './PictureLink.module.scss';

interface PictureLinkProps {
  icon: ReactNode;
  label: string | ReactNode;
  to: string;
  isExternal?: boolean;
}

const PictureLink: FunctionComponent<PictureLinkProps> = ({
  icon,
  label,
  to,
  isExternal = false,
}): ReactElement => {
  const content = (
    <div className={styles.container}>
      {icon}
      <h3>{label}</h3>
    </div>
  );
  return isExternal ? (
    <a
      href={to}
      target="_blank"
      title=""
      rel="noopener noreferrer"
      className={styles.link}
    >
      {content}
    </a>
  ) : (
    <Link to={to} className={styles.link}>
      {content}
    </Link>
  );
};

export default PictureLink;
