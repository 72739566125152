import { IntlShape } from 'react-intl';
import * as yup from 'yup';
import { bigBUs, smallBUs } from '../../constants';
import { BusinessUnitCode, UserRole, WordCloudFilterField } from '../../enums';
import { TranslationKey } from '../../i18n/translations';
import { WordCloudFilterValues } from '../../types';

export const getChartValidationSchema = (
  intl: IntlShape,
  userRole: UserRole,
  userBU: BusinessUnitCode,
): yup.SchemaOf<unknown> => {
  const requiredGeneric = intl.formatMessage({
    id: TranslationKey.VALIDATE_REQUIRED_GENERIC,
  });

  const scopeMessage = intl.formatMessage({
    id: TranslationKey.VALIDATE_MUST_SELECT_SCOPE,
  });
  let additionalFields = {};
  if (userRole === UserRole.Admin) {
    const scopeFunction = function scope(this: {
      parent: WordCloudFilterValues;
    }): boolean {
      const zone = this.parent[WordCloudFilterField.AllZone];
      const businessUnits = this.parent[WordCloudFilterField.BusinessUnits];
      return zone || !!businessUnits?.length;
    };
    additionalFields = {
      [WordCloudFilterField.AllZone]: yup
        .boolean()
        .test('no-scope-zone', scopeMessage, scopeFunction),
      [WordCloudFilterField.BusinessUnits]: yup
        .array()
        .of(yup.string())
        .test('no-scope-bu', scopeMessage, scopeFunction),
    };
  } else if (userRole === UserRole.Owner && bigBUs.includes(userBU)) {
    const scopeFunction = function scope(this: {
      parent: WordCloudFilterValues;
    }): boolean {
      const allBU = this.parent[WordCloudFilterField.AllBU];
      const vicePresidencies =
        this.parent[WordCloudFilterField.VicePresidencies];
      const uens = this.parent[WordCloudFilterField.UENs];
      return allBU || !!vicePresidencies?.length || !!uens?.length;
    };
    additionalFields = {
      [WordCloudFilterField.AllBU]: yup
        .boolean()
        .test('no-scope-bu', scopeMessage, scopeFunction),
      [WordCloudFilterField.VicePresidencies]: yup
        .array()
        .of(yup.string())
        .test('no-scope-vps', scopeMessage, scopeFunction),
      [WordCloudFilterField.UENs]: yup
        .array()
        .of(
          yup.object().shape({
            name: yup.string(),
          }),
        )
        .test('no-scope-uens', scopeMessage, scopeFunction),
    };
  } else if (userRole === UserRole.Owner && smallBUs.includes(userBU)) {
    const scopeFunction = function scope(this: {
      parent: WordCloudFilterValues;
    }): boolean {
      const allBU = this.parent[WordCloudFilterField.AllBU];
      const countries = this.parent[WordCloudFilterField.Countries];
      return allBU || !!countries?.length;
    };
    additionalFields = {
      [WordCloudFilterField.AllBU]: yup
        .boolean()
        .test('no-scope-bu', scopeMessage, scopeFunction),
      [WordCloudFilterField.Countries]: yup
        .array()
        .of(yup.string())
        .test('no-scope-countries', scopeMessage, scopeFunction),
    };
  } else if (userRole === UserRole.Regular) {
    const scopeFunction = function scope(this: {
      parent: WordCloudFilterValues;
    }): boolean {
      const vicePresidencies =
        this.parent[WordCloudFilterField.VicePresidencies];
      const uens = this.parent[WordCloudFilterField.UENs];
      return !!vicePresidencies?.length || !!uens?.length;
    };
    additionalFields = {
      [WordCloudFilterField.VicePresidencies]: yup
        .array()
        .of(yup.string().defined())
        .test('no-scope-vp', scopeMessage, scopeFunction),
      [WordCloudFilterField.UENs]: yup
        .array()
        .of(
          yup.object().shape({
            name: yup.string(),
          }),
        )
        .test('no-scope-vp', scopeMessage, scopeFunction),
    };
  }

  return yup.object().shape({
    [WordCloudFilterField.Question]: yup.string().required(requiredGeneric),
    [WordCloudFilterField.From]: yup.date().required(requiredGeneric),
    ...additionalFields,
  });
};
