import { Icon } from '@material-ui/core';
import {
  BarChartOutlined as BarChartIcon,
  HistoryOutlined as HistoryIcon,
  HomeOutlined as HomeIcon,
  PeopleAlt as PeopleIcon,
  SvgIconComponent,
} from '@material-ui/icons';
import React, { FunctionComponent, ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { UserRole } from '../../enums';
import { TranslationKey } from '../../i18n/translations';
import { userSessionSelectors } from '../../selectors';
import styles from './MobileFooter.module.scss';

interface MobileMenuItemProps {
  IconElement: SvgIconComponent;
  translationKey: string;
  linkPath: string;
}

const MobileMenuItem: FunctionComponent<MobileMenuItemProps> = ({
  IconElement,
  translationKey,
  linkPath,
}): ReactElement => (
  <Link to={linkPath} className={styles.itemLink}>
    <div className={styles.item}>
      <IconElement className={styles.itemIcon} />
      <span className={styles.itemLabel}>
        <FormattedMessage id={translationKey} />
      </span>
    </div>
  </Link>
);

const MobileFooter = (): ReactElement => {
  const isAdmin = useSelector(userSessionSelectors.getRole) === UserRole.Admin;

  return (
    <div className={styles.container}>
      <MobileMenuItem
        IconElement={HomeIcon}
        translationKey={TranslationKey.HOME}
        linkPath="/"
      />

      <MobileMenuItem
        IconElement={(props: unknown) => <Icon {...props}>pending</Icon>}
        translationKey={TranslationKey.PENDING}
        linkPath="/history/pending"
      />

      <MobileMenuItem
        IconElement={HistoryIcon}
        translationKey={TranslationKey.HISTORY}
        linkPath="/history"
      />

      <MobileMenuItem
        IconElement={BarChartIcon}
        translationKey={TranslationKey.REPORTS}
        linkPath="/reports"
      />

      {isAdmin && (
        <MobileMenuItem
          IconElement={PeopleIcon}
          translationKey={TranslationKey.ADMINISTRATION}
          linkPath="/admin"
        />
      )}
    </div>
  );
};

export default MobileFooter;
