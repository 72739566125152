import { IntlShape } from 'react-intl';
import * as yup from 'yup';
import { PasswordChangeFields } from '../../enums';
import { testCredentialsError } from '../../helpers/util-functions';
import { TranslationKey } from '../../i18n/translations';

const getValidationSchema = (
  intl: IntlShape,
  isAdmin = false,
): yup.SchemaOf<unknown> =>
  yup.object().shape({
    credentialsError: yup.boolean(),

    [PasswordChangeFields.OldPassword]: yup
      .string()
      .required(
        intl.formatMessage(
          {
            id: TranslationKey.VALIDATE_REQUIRED,
          },
          {
            fieldName: intl.formatMessage({
              id: TranslationKey.OLD_PASSWORD,
            }),
          },
        ),
      )
      .test(
        'no-credentials-error',
        intl.formatMessage({
          id: TranslationKey.VALIDATE_CURRENT_PASSWORD,
        }),
        testCredentialsError,
      ),

    [PasswordChangeFields.NewPassword]: yup
      .string()
      .required(
        intl.formatMessage(
          {
            id: TranslationKey.VALIDATE_REQUIRED,
          },
          {
            fieldName: intl.formatMessage({
              id: TranslationKey.NEW_PASSWORD,
            }),
          },
        ),
      )
      .matches(
        isAdmin
          ? /^(?=.*[A-Za-z])(?=.*\d)(?=.*[.@$!%*#?&-])[A-Za-z\d.@$!%*#?&-]{15,}$/
          : /^(?=.*[A-Za-z])(?=.*\d)(?=.*[.@$!%*#?&-])[A-Za-z\d.@$!%*#?&-]{8,}$/,
        intl.formatMessage(
          {
            id: TranslationKey.VALIDATE_PASSWORD_INSTRUCTIONS,
          },
          {
            length: isAdmin ? 15 : 8,
          },
        ),
      )
      .test(
        'no-repeated-password',
        intl.formatMessage({
          id: TranslationKey.VALIDATE_PASSWORD_NOT_NEW,
        }),
        function noRepeatedPassword(
          this: {
            parent: { oldPassword: string };
          },
          value,
        ): boolean {
          return this.parent.oldPassword !== value;
        },
      ),

    [PasswordChangeFields.RepeatNewPassword]: yup
      .string()
      .when(PasswordChangeFields.NewPassword, {
        is: (password: string | null): boolean =>
          !!password && !!password.length,
        then: yup.string().oneOf(
          [yup.ref(PasswordChangeFields.NewPassword)],
          intl.formatMessage({
            id: TranslationKey.VALIDATE_PASSWORD_MATCH,
          }),
        ),
      })
      .required(
        intl.formatMessage(
          {
            id: TranslationKey.VALIDATE_REQUIRED,
          },
          {
            fieldName: intl.formatMessage({
              id: TranslationKey.REPEAT_NEW_PASSWORD,
            }),
          },
        ),
      ),
  });

export default getValidationSchema;
