import { BusinessUnitCode, CountryCode, InterviewStatus } from './enums';
import { CountryWithZone } from './types';

export const countriesWithZone: CountryWithZone[] = [
  {
    businessUnit: BusinessUnitCode.CAC,
    country: CountryCode.Barbados,
  },
  {
    businessUnit: BusinessUnitCode.CAC,
    country: CountryCode.Dominica,
  },
  {
    businessUnit: BusinessUnitCode.CAC,
    country: CountryCode.DominicanRepublic,
  },
  {
    businessUnit: BusinessUnitCode.CAC,
    country: CountryCode.Guatemala,
  },
  {
    businessUnit: BusinessUnitCode.CAC,
    country: CountryCode.Panama,
  },
  {
    businessUnit: BusinessUnitCode.CAC,
    country: CountryCode.SaintVincent,
  },

  {
    businessUnit: BusinessUnitCode.Colombia,
    country: CountryCode.Colombia,
  },

  {
    businessUnit: BusinessUnitCode.Ecuador,
    country: CountryCode.Ecuador,
  },

  {
    businessUnit: BusinessUnitCode.HondurasAndSalvador,
    country: CountryCode.Honduras,
  },
  {
    businessUnit: BusinessUnitCode.HondurasAndSalvador,
    country: CountryCode.Salvador,
  },

  {
    businessUnit: BusinessUnitCode.Mexico,
    country: CountryCode.Mexico,
  },

  {
    businessUnit: BusinessUnitCode.Peru,
    country: CountryCode.Peru,
  },
];

export const cursorStatusMap: Record<
  InterviewStatus,
  'pointer' | 'not-allowed'
> = {
  [InterviewStatus.Registered]: 'not-allowed',
  [InterviewStatus.Interview]: 'pointer',
  [InterviewStatus.Feedback]: 'pointer',
  [InterviewStatus.FeedbackSent]: 'not-allowed',
  [InterviewStatus.FeedbackAccepted]: 'pointer',
  [InterviewStatus.FeedbackRejected]: 'pointer',
  [InterviewStatus.FeedbackNotConfirmed]: 'pointer',
};
