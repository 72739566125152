import { Grid } from '@material-ui/core';
import { useFormikContext } from 'formik';
import React, { FunctionComponent, ReactElement } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import {
  FormAutocompleteField,
  FormCheckboxField,
  FormMultipleSelectField,
} from '../../components/FormFields';
import { apiURL } from '../../config/environment';
import { ReportFilterField } from '../../enums';
import { TranslationKey } from '../../i18n/translations';
import { reportsSelectors } from '../../selectors';
import { ReportFilterValues, SelectOption } from '../../types';
import styles from './WordCloud.module.scss';

interface SuperAdminFilterProps {
  businessUnitOptions: SelectOption[];
  vicePresidencyOptions: SelectOption[];
}

const SuperAdminFilter: FunctionComponent<SuperAdminFilterProps> = ({
  businessUnitOptions: buOptions,
  vicePresidencyOptions,
}): ReactElement => {
  const formikContext = useFormikContext<ReportFilterValues>();
  const { values } = formikContext;
  const setFieldValue = formikContext.setFieldValue.bind(formikContext);

  const intl = useIntl();
  const fetching = useSelector(reportsSelectors.getFetching);

  return (
    <>
      <h3 className={`${styles.filterStep} ${styles.required}`}>4. Scope</h3>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormCheckboxField
            name={ReportFilterField.AllZone}
            label={intl.formatMessage({
              id: TranslationKey.ZONE,
            })}
            onValueChange={(checked: boolean): void => {
              if (!checked) return;
              setFieldValue(ReportFilterField.BusinessUnits, []);
              setFieldValue(ReportFilterField.UENs, []);
            }}
            disabled={fetching}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} style={{ marginBottom: '20px' }}>
        <Grid item xs={12}>
          <FormMultipleSelectField
            name={ReportFilterField.BusinessUnits}
            label="BU"
            options={buOptions}
            onValueChange={(businessUnits): void => {
              setFieldValue(ReportFilterField.AllZone, false);
              if (Number(businessUnits?.length) > 1) {
                setFieldValue(ReportFilterField.VicePresidencies, []);
                setFieldValue(ReportFilterField.UENs, []);
              }
            }}
            disabled={fetching}
            showAll
          />
        </Grid>
      </Grid>

      <h3 className={styles.filterStep}>
        5. <FormattedMessage id={TranslationKey.SHOW_BY} />{' '}
        <span>
          (
          {intl
            .formatMessage({ id: TranslationKey.OPTIONAL })
            .toLocaleUpperCase()}
          )
        </span>
      </h3>

      <hr className={styles.separator} />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormMultipleSelectField
            name={ReportFilterField.VicePresidencies}
            label={intl.formatMessage({
              id: TranslationKey.VP_SHORT,
            })}
            options={vicePresidencyOptions}
            onValueChange={() => {
              setFieldValue(ReportFilterField.UENs, []);
            }}
            disabled={
              Number(values[ReportFilterField.BusinessUnits]?.length) > 1 ||
              fetching
            }
            showAll
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormAutocompleteField
            name={ReportFilterField.UENs}
            label={intl.formatMessage({
              id: TranslationKey.UEN,
            })}
            apiURL={`${apiURL}/auth/autocomplete/uen`}
            disabled={
              !!values[ReportFilterField.AllZone] ||
              Number(values[ReportFilterField.BusinessUnits]?.length) > 1 ||
              fetching
            }
            onValueChange={() => {
              setFieldValue(ReportFilterField.VicePresidencies, []);
            }}
            multiple
          />
        </Grid>
      </Grid>
    </>
  );
};

export default SuperAdminFilter;
