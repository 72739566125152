import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import {
  Clear as ClearIcon,
  FilterList as FilterListIcon,
} from '@material-ui/icons';
import { omit, snakeCase } from 'lodash-es';
import React, { ChangeEvent, ReactElement, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { historyActions } from '../../actions';
import AutocompleteField from '../../components/AutocompleteField';
import { apiURL } from '../../config/environment';
import { CountryCode, VicePresidencyCode } from '../../enums';
import { TranslationKey } from '../../i18n/translations';
import { historySelectors } from '../../selectors';
import isoCountries from '../../services/isoCountries';
import { IOption } from '../../types';
import styles from './History.module.scss';

const AdditionalFilters = (): ReactElement => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const expanded = useSelector(historySelectors.getExpanded);
  const additionalFilters = useSelector(historySelectors.getAdditionalFilters);

  const handleExpandedChange = useCallback(
    (_event, newExpanded) => {
      dispatch(historyActions.setExpanded(newExpanded));
    },
    [dispatch],
  );

  const handleCountryChange = useCallback(
    (event: ChangeEvent<{ value: unknown }>) => {
      dispatch(
        historyActions.setAdditionalFilters({
          ...additionalFilters,
          country: event.target.value as string,
        }),
      );
    },
    [additionalFilters, dispatch],
  );

  const handleVicePresidencyChange = useCallback(
    (event: ChangeEvent<{ value: unknown }>) => {
      dispatch(
        historyActions.setAdditionalFilters({
          ...additionalFilters,
          vicePresidency: event.target.value as string,
        }),
      );
    },
    [additionalFilters, dispatch],
  );

  const handleClearAllClick = useCallback(() => {
    dispatch(historyActions.setAdditionalFilters({}));
  }, [dispatch]);

  const getChipText = useCallback(
    (field: string, value: string | IOption): string => {
      if (field === 'country') {
        return isoCountries.getName(value as string, intl.locale);
      }
      if (field === 'uen') return value ? (value as IOption).name : '';
      return value as string;
    },
    [intl.locale],
  );

  return (
    <Accordion
      className={styles.additionalFilters}
      expanded={expanded}
      onChange={handleExpandedChange}
    >
      <AccordionSummary expandIcon={<FilterListIcon />}>
        {Object.entries(additionalFilters).map(([field, value]) => (
          <Chip
            key={field}
            label={`${intl.formatMessage({
              id: `HISTORY_COLUMN_${snakeCase(field).toUpperCase()}`,
            })}: ${getChipText(field, value)}`}
            onDelete={() => {
              dispatch(
                historyActions.setAdditionalFilters(
                  omit(additionalFilters, [field]),
                ),
              );
            }}
            className={styles.filterChip}
          />
        ))}
      </AccordionSummary>

      <AccordionDetails>
        <Grid container spacing={2} justify="flex-end" alignItems="flex-end">
          <Grid item xs={6} sm={3} md={2}>
            <FormControl variant="outlined" size="small" fullWidth>
              <InputLabel shrink={false}>
                <FormattedMessage id={TranslationKey.COUNTRY} />
              </InputLabel>

              <Select
                label={<FormattedMessage id={TranslationKey.COUNTRY} />}
                value={additionalFilters.country || ''}
                onChange={handleCountryChange}
              >
                {Object.values(CountryCode).map((code) => (
                  <MenuItem key={code} value={code}>
                    {isoCountries.getName(code, intl.locale)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6} sm={4} md={2}>
            <FormControl variant="outlined" size="small" fullWidth>
              <InputLabel shrink={false}>
                <FormattedMessage
                  id={TranslationKey.HISTORY_COLUMN_VICE_PRESIDENCY}
                />
              </InputLabel>

              <Select
                label={
                  <FormattedMessage
                    id={TranslationKey.HISTORY_COLUMN_VICE_PRESIDENCY}
                  />
                }
                value={additionalFilters.vicePresidency || ''}
                onChange={handleVicePresidencyChange}
              >
                {Object.values(VicePresidencyCode).map((vpCode) => (
                  <MenuItem key={vpCode} value={vpCode}>
                    <FormattedMessage id={`VP_${vpCode}`} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={10} sm={4} md={3}>
            <AutocompleteField
              apiURL={`${apiURL}/auth/autocomplete/uen`}
              label={intl.formatMessage({
                id: TranslationKey.HISTORY_COLUMN_UEN,
              })}
              value={additionalFilters.uen}
              onChange={(newUen: IOption) => {
                dispatch(
                  historyActions.setAdditionalFilters(
                    newUen
                      ? {
                          ...additionalFilters,
                          uen: newUen,
                        }
                      : omit(additionalFilters, ['uen']),
                  ),
                );
              }}
            />
          </Grid>

          <IconButton
            onClick={handleClearAllClick}
            className={styles.additionalFiltersClear}
          >
            <ClearIcon />
          </IconButton>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default AdditionalFilters;
