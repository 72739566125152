import { useFormikContext } from 'formik';
import { FunctionComponent, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { map } from 'rxjs/operators';
import { historyAPI } from '../../api';
import toastConfig from '../../config/toast';
import { answersMapper } from '../../helpers/util-functions';
import { logger } from '../../services';
import { IAnswer, QuestionaryValues } from '../../types';

interface AnswersFetcherProps {
  id: number;
  setFetching: (fetching: boolean) => void;
}

const AnswersFetcher: FunctionComponent<AnswersFetcherProps> = ({
  id,
  setFetching,
}): null => {
  const { setFieldValue } = useFormikContext<QuestionaryValues>();

  const setAnswers = useCallback(
    (answers) => {
      Object.entries(answers).forEach(([field, value]) => {
        setFieldValue(field, value);
      });
    },
    [setFieldValue],
  );

  useEffect(() => {
    const subscription = historyAPI
      .fetchInterviewDetails(id)
      .pipe(
        map(({ response }: { response: { questionary: IAnswer[] } }) =>
          answersMapper(response.questionary),
        ),
      )
      .subscribe(
        (storedAnswers) => {
          logger.info(`Fetched interview details for id: ${id}`, storedAnswers);
          setAnswers(storedAnswers);
          setFetching(false);
        },
        (err) => {
          logger.error(`Error fetching interview details for id: ${id}`, err);
          toast.error('Error fetching interview details.', toastConfig);
          setFetching(false);
        },
      );
    return () => {
      subscription.unsubscribe();
    };
  }, [id, setAnswers, setFetching]);

  return null;
};

export default AnswersFetcher;
