import {
  Chip,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  SelectProps,
} from '@material-ui/core';
import { useField } from 'formik';
import React, {
  ChangeEvent,
  FunctionComponent,
  ReactElement,
  ReactNode,
  useCallback,
  useMemo,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { ALL } from '../../constants';
import { TranslationKey } from '../../i18n/translations';
import { SelectOption } from '../../types';

import styles from './FormFields.module.scss';

const useStyles = makeStyles(() => ({
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 1,
  },
}));

interface FormMultipleSelectFieldProps extends Partial<SelectProps> {
  name: string;
  label: string;
  options: SelectOption[];
  required?: boolean;
  loading?: boolean;
  showAll?: boolean;
  onValueChange?: (value?: string[], name?: string) => void;
}

const FormMultipleSelectField: FunctionComponent<FormMultipleSelectFieldProps> =
  ({
    name,
    label,
    placeholder,
    options,
    required = false,
    loading = false,
    showAll = false,
    onValueChange,
    ...props
  }): ReactElement => {
    const [field, meta] = useField<string[]>(name);

    const handleChange = useCallback(
      (
        event: ChangeEvent<
          { name?: string; value: unknown } | HTMLSelectElement
        >,
      ): void => {
        const newValue = event.target.value as string[] | undefined;
        const realValue = newValue?.includes(ALL)
          ? options.map(({ value }) => value)
          : newValue;

        field.onChange({
          ...event,
          target: {
            name,
            value: realValue,
          },
        });

        if (onValueChange)
          onValueChange(realValue as string[] | undefined, name);
      },
      [field, name, onValueChange, options],
    );

    const menuItems = useMemo(
      () =>
        options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}{' '}
          </MenuItem>
        )),
      [options],
    );

    const hasError = meta.touched && !!meta.error;
    const classes = useStyles();

    return (
      <FormControl
        variant="outlined"
        size="small"
        required={required}
        fullWidth
        error={hasError}
      >
        <InputLabel className={styles.baseLabel} shrink={false}>
          {label}
        </InputLabel>
        <Select
          {...field}
          {...props}
          onChange={handleChange}
          className={styles.multiSelectInput}
          label={label}
          placeholder={placeholder || label}
          multiple
          endAdornment={
            loading ? (
              <InputAdornment position="end">
                <CircularProgress size="20px" thickness={5} color="primary" />
              </InputAdornment>
            ) : undefined
          }
          renderValue={(selected): ReactNode => (
            <div className={classes.chips}>
              {(selected as string[]).map((value) => (
                <Chip
                  key={value}
                  className={classes.chip}
                  label={
                    options.find((option) => option.value === value)?.label ||
                    ''
                  }
                  disabled={props.disabled}
                  onDelete={() => {
                    const newValue = (field.value || []).filter(
                      (option) => option !== value,
                    );
                    field.onChange({
                      target: {
                        name,
                        value: newValue,
                      },
                    });
                    if (onValueChange) onValueChange(newValue);
                  }}
                  onMouseDown={(event) => {
                    event.stopPropagation();
                  }}
                />
              ))}
            </div>
          )}
        >
          {showAll && field.value?.length !== options.length && (
            <MenuItem key={ALL} value={ALL}>
              <FormattedMessage id={TranslationKey.ALL} />
            </MenuItem>
          )}
          {menuItems}
        </Select>
        <FormHelperText>{hasError ? meta.error : ''}</FormHelperText>
      </FormControl>
    );
  };

export default FormMultipleSelectField;
