/* eslint-disable consistent-return */
/* eslint-disable default-case */
/* eslint-disable no-param-reassign */

import {
  AppAction,
  CLEAR_ADMIN_STATE,
  SET_ADMIN_FETCHING,
  SET_ADMIN_PAGE,
  SET_ADMIN_SORT_COLUMN,
  SET_ADMIN_SORT_DIRECTION,
  SET_ADMIN_SUBMITTING,
  SET_ADMIN_TASK,
  SET_ADMIN_TEXT_FILTER,
  SET_ADMIN_USERS,
  SET_ADMIN_USER_DH_ACCESS,
  SET_ADMIN_USER_EMAIL,
  SET_ADMIN_USER_ROLE,
  SET_ALL_USERS_SELECTED,
  TOGGLE_USER_SELECTED,
  USER_CHANGES_REQUEST,
} from '../actions/actionTypes';
import { AdminTableField, AdminTask, SortDirection, UserRole } from '../enums';
import { immerProduce } from '../helpers/util-functions';
import { AdminState } from '../types';

const initState: AdminState = {
  task: AdminTask.Add,
  fetching: false,
  submitting: false,
  page: 0,
  sortColumn: AdminTableField.Id,
  sortDirection: SortDirection.Descending,
  textFilter: '',
  users: [],
  count: 0,
};

const adminReducer = (state = initState, action: AppAction): AdminState =>
  immerProduce(state, (draft) => {
    switch (action.type) {
      case SET_ADMIN_FETCHING:
        draft.fetching = action.fetching;
        return;

      case SET_ADMIN_SUBMITTING:
        draft.submitting = action.submitting;
        return;

      case SET_ADMIN_TASK:
        draft.page = initState.page;
        draft.sortColumn = initState.sortColumn;
        draft.sortDirection = initState.sortDirection;
        draft.fetching = !!draft.textFilter;
        draft.task = action.task;
        return;

      case SET_ADMIN_PAGE:
        draft.page = action.page;
        draft.fetching = true;
        return;

      case SET_ADMIN_SORT_COLUMN:
        draft.sortColumn = action.sortColumn;
        draft.fetching = true;
        return;

      case SET_ADMIN_SORT_DIRECTION:
        draft.sortDirection = action.sortDirection;
        draft.fetching = true;
        return;

      case SET_ADMIN_TEXT_FILTER:
        draft.textFilter = action.textFilter;
        draft.page = initState.page;
        draft.fetching = !!action.textFilter;
        draft.submitting = false;
        if (!action.textFilter) {
          draft.users = initState.users;
          draft.page = initState.page;
          draft.sortColumn = initState.sortColumn;
          draft.sortDirection = initState.sortDirection;
        }
        return;

      case SET_ADMIN_USERS:
        draft.users = action.users.map((user) => ({
          ...user,
          role: user.role || UserRole.Regular,
          selected: false,
        }));
        draft.count = action.count;
        draft.fetching = false;
        return;

      case TOGGLE_USER_SELECTED:
        draft.users.forEach((user) => {
          if (user.id !== action.id) return;
          user.selected = !user.selected;
        });
        return;

      case SET_ADMIN_USER_EMAIL:
        draft.users.forEach((user) => {
          if (user.id !== action.id) return;
          user.email = action.email;
        });
        return;

      case SET_ADMIN_USER_ROLE:
        draft.users.forEach((user) => {
          if (user.id !== action.id) return;
          user.role = action.role;
        });
        return;

      case SET_ALL_USERS_SELECTED:
        draft.users.forEach((user) => {
          user.selected = action.selected;
        });
        return;
      case SET_ADMIN_USER_DH_ACCESS:
        draft.users.forEach((user) => {
          if (user.id !== action.id) return;
          user.dh_access = action.dh_access;
        });
        return;
      case USER_CHANGES_REQUEST:
        draft.submitting = true;
        return;

      case CLEAR_ADMIN_STATE:
        return initState;
    }
  });

export default adminReducer;
