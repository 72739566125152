import { CircularProgress } from '@material-ui/core';
import React, { FunctionComponent, ReactElement } from 'react';
import styles from './LoadingSpinner.module.scss';

interface LoadingSpinnerProps {
  size?: string | number;
  thickness?: number;
}

const LoadingSpinner: FunctionComponent<LoadingSpinnerProps> = ({
  size = '5em',
  thickness = 5,
}): ReactElement => (
  <div
    className={styles.loader}
    style={{
      width: size,
      height: size,
    }}
  >
    <CircularProgress color="primary" thickness={thickness} size={size} />
  </div>
);

export default LoadingSpinner;
