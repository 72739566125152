import { Email as EmailIcon, Send as SendIcon } from '@material-ui/icons';
import { Form, Formik, FormikProps } from 'formik';
import React, { ReactElement, useCallback, useMemo } from 'react';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { userSessionActions } from '../../actions';
import ABIButton from '../../components/ABIButton';
import { FormTextField } from '../../components/FormFields';
import LoadingSpinner from '../../components/LoadingSpinner';
import { HTMLInputType } from '../../enums';
import { TranslationKey } from '../../i18n/translations';
import { userSessionSelectors } from '../../selectors';
import TranslateFormikErrors from '../TranslateFormikErrors';

interface ForgotPasswordValues {
  email: string;
}

const getValidationSchema = (intl: IntlShape) =>
  yup.object({
    email: yup
      .string()
      .email(
        intl.formatMessage({
          id: TranslationKey.VALIDATE_EMAIL,
        }),
      )
      .required(
        intl.formatMessage(
          {
            id: TranslationKey.VALIDATE_EMAIL,
          },
          {
            fieldName: 'Email',
          },
        ),
      ),
  });

const ForgotPassword = (): ReactElement => {
  const sending = useSelector(userSessionSelectors.getSending);

  const dispatch = useDispatch();
  const handleSubmit = useCallback(
    (values: ForgotPasswordValues) => {
      dispatch(userSessionActions.forgotPasswordRequest(values.email));
    },
    [dispatch],
  );

  const intl = useIntl();
  const validationSchema = useMemo(() => getValidationSchema(intl), [intl]);

  return (
    <>
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        initialErrors={{
          email: '',
          password: '',
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        autoComplete="off"
      >
        {({ isValid }: FormikProps<ForgotPasswordValues>) => (
          <Form autoComplete="off">
            <TranslateFormikErrors />

            <p>
              <FormattedMessage id={TranslationKey.FORGOT_PASSWORD_MESSAGE} />
            </p>

            {/* Email Field */}
            <FormTextField
              name="email"
              label="Email"
              type={HTMLInputType.Email}
              placeholder="usuario@abinbev.com"
              startElement={<EmailIcon color="inherit" />}
            />

            <Link to="/session/login">
              <FormattedMessage id={TranslationKey.RETURN} />
            </Link>

            <ABIButton type="submit" disabled={!isValid || sending}>
              <FormattedMessage id={TranslationKey.SEND} />{' '}
              <SendIcon
                color="inherit"
                fontSize="small"
                style={{
                  marginLeft: 5,
                }}
              />
            </ABIButton>
          </Form>
        )}
      </Formik>

      {sending && (
        <div>
          <LoadingSpinner size="1em" />
        </div>
      )}
    </>
  );
};

export default ForgotPassword;
