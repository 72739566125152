import { differenceInDays, startOfDay } from 'date-fns';
import * as HttpStatus from 'http-status-codes';
import { toast } from 'react-toastify';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { of } from 'rxjs';
import { AjaxError } from 'rxjs/ajax';
import { catchError, map, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { userSessionActions } from '../actions';
import {
  AppAction,
  ClearUserSessionAction,
  FETCH_USER_SESSION,
  ForgotPasswordRequestAction,
  FORGOT_PASSWORD_REQUEST,
  LoginUserRequestAction,
  LOGIN_USER_REQUEST,
  LogoutUSerRequestAction,
  LOGOUT_USER_REQUEST,
  NotifyRouteChangeAction,
  NOTIFY_ROUTE_CHANGE,
  PasswordRecoveryRequestAction,
  PASSWORD_RECOVERY_REQUEST,
  SetCredentialsErrorAction,
  SetUserSessionAction,
} from '../actions/actionTypes';
import { userSessionAPI } from '../api';
import toastConfig from '../config/toast';
import { PASSWORD_CHANGE_WARNING_DAYS } from '../constants';
import { UserRole } from '../enums';
import { userSessionMapper } from '../helpers/util-functions';
import history from '../history';
import intlHelper from '../i18n/intlHelper';
import { TranslationKey } from '../i18n/translations';
import { logger } from '../services';
import { LoginResponse, RawUser } from '../types';

export const fetchUserSessionEpic: Epic<
  AppAction,
  SetUserSessionAction | ClearUserSessionAction
> = (action$) =>
    action$.pipe(
      ofType(FETCH_USER_SESSION),
      mergeMap(() =>
        userSessionAPI.fetchUserSession().pipe(
          map(({ response }: { response: { user: RawUser } }) =>
            userSessionMapper(response.user),
          ),
          map((user) => {
            logger.info('Fetched user session.', user);
            return userSessionActions.setUserSession(user);
          }),
          tap(() => {
            if (history.location.pathname.includes('/session')) {
              history.push({
                pathname: '/',
                search: '',
              });
            }
          }),
          catchError((error) => {
            logger.error('Error fetching user session.', error);
            if (
              !history.location.pathname.includes('/session') &&
              !history.location.pathname.includes('/external')
            ) {
              history.push({
                pathname: '/session/login',
                search: '',
              });
            }
            return of(userSessionActions.clearUserSession());
          }),
        ),
      ),
    );

export const loginUserRequestEpic: Epic<
  AppAction,
  SetUserSessionAction | ClearUserSessionAction | SetCredentialsErrorAction
> = (action$) =>
    action$.pipe(
      ofType<AppAction, LoginUserRequestAction>(LOGIN_USER_REQUEST),
      mergeMap((action) =>
        userSessionAPI.loginUser(action.code, action.state).pipe(
          map(({ response }: LoginResponse) => userSessionMapper(response.user)),
          tap((user) => {
            const isAdmin = user.role === UserRole.Admin;
            const maxValidDays = isAdmin ? 30 : 90;
            const daysFromLastChange = differenceInDays(
              startOfDay(new Date()),
              startOfDay(user.lastPasswordChange),
            );
            const daysUntilExpiration = maxValidDays - daysFromLastChange;
            if (daysUntilExpiration <= PASSWORD_CHANGE_WARNING_DAYS) {
              const intl = intlHelper.getIntl();
              toast(
                intl?.formatMessage(
                  {
                    id: TranslationKey.PASSWORD_CHANGE_WARNING,
                  },
                  {
                    days: daysUntilExpiration,
                  },
                ),
                toastConfig,
              );
            }
          }),
          map((user) => {
            logger.info('Logged in user.', user);
            return userSessionActions.setUserSession(user);
          }),
          tap(() => {
            // `setTimeout` forces wait on user login on Redux store
            setTimeout(() => {
              history.push({
                pathname: '/',
                search: '',
              });
            }, 0);
          }),
          catchError((error: AjaxError) => {
            if (
              ![
                HttpStatus.UNAUTHORIZED,
                HttpStatus.UNPROCESSABLE_ENTITY,
                HttpStatus.NOT_ACCEPTABLE,
                HttpStatus.NOT_FOUND
              ].includes(error.status)
            ) {
              throw error;
            }

            sessionStorage.removeItem("state");

            history.push({
              pathname: '/session/login',
              search: '',
            });

            return of(userSessionActions.setCredentialsError(true));
          }),
          catchError((error) => {
            sessionStorage.removeItem("state");
            logger.error('Error login user.', error);
            history.push({
              pathname: '/session/login',
              search: '',
            });
            toast.error('Error sending login request.', toastConfig);
            return of(userSessionActions.clearUserSession());
          }),
        ),
      ),
    );

export const logoutUserRequestEpic: Epic<AppAction, ClearUserSessionAction> = (
  action$,
) =>
  action$.pipe(
    ofType<AppAction, LogoutUSerRequestAction>(LOGOUT_USER_REQUEST),
    mergeMap(() =>
      userSessionAPI.logoutUser().pipe(
        map((response) => {
          logger.info('User logout.', response);
          history.push({
            pathname: '/session/login',
            search: '',
          });
          return userSessionActions.clearUserSession();
        }),
        catchError((error) => {
          logger.error('Error on user logout.', error);
          history.push({
            pathname: '/session/login',
            search: '',
          });
          return of(userSessionActions.clearUserSession());
        }),
      ),
    ),
  );

export const forgotPasswordRequestEpic: Epic<
  AppAction,
  ClearUserSessionAction
> = (action$) =>
    action$.pipe(
      ofType<AppAction, ForgotPasswordRequestAction>(FORGOT_PASSWORD_REQUEST),
      mergeMap((action) =>
        userSessionAPI.forgotPassword(action.email).pipe(
          map((response) => {
            logger.info('Sent forgot password request.', response);
            history.push({
              pathname: '/session/login',
              search: '',
            });
            const intl = intlHelper.getIntl();
            toast(
              intl?.formatMessage({
                id: TranslationKey.FORGOT_PASSWORD_NOTIFICATION,
              }),
              toastConfig,
            );
            return userSessionActions.clearUserSession();
          }),
          catchError((error) => {
            logger.error('Error login user.', error);
            history.push({
              pathname: '/session/forgot-password',
              search: '',
            });
            toast.error('Error sending forgot password.', toastConfig);
            return of(userSessionActions.clearUserSession());
          }),
          takeUntil(
            action$.pipe(
              ofType<AppAction, NotifyRouteChangeAction>(NOTIFY_ROUTE_CHANGE),
            ),
          ),
        ),
      ),
    );

export const passwordRecoveryRequestEpic: Epic<AppAction, AppAction> = (
  action$,
) =>
  action$.pipe(
    ofType<AppAction, PasswordRecoveryRequestAction>(PASSWORD_RECOVERY_REQUEST),
    mergeMap((action) =>
      userSessionAPI
        .recoverPassword(
          action.newPassword,
          action.currentPassword,
          action.token,
        )
        .pipe(
          map((response) => {
            const isInternalChange = !action.token;
            logger.info('Password change', response);
            const pathname = isInternalChange ? '/' : '/session/login';
            history.push({
              pathname,
              search: '',
            });
            const intl = intlHelper.getIntl();
            toast(
              intl?.formatMessage({
                id: TranslationKey.PASSWORD_RECOVERY_MESSAGE,
              }),
              toastConfig,
            );
            return action.token
              ? userSessionActions.setCredentialsError(false)
              : userSessionActions.clearUserSession();
          }),
          catchError((error: AjaxError) => {
            if (
              ![
                HttpStatus.UNAUTHORIZED,
                HttpStatus.UNPROCESSABLE_ENTITY,
                HttpStatus.NOT_ACCEPTABLE,
              ].includes(error.status)
            ) {
              throw error;
            }
            return of(userSessionActions.setCredentialsError(true));
          }),
          catchError((error) => {
            logger.error('Error recovering password', error);
            toast.error(
              'Error sending password recovery request.',
              toastConfig,
            );
            return of(userSessionActions.clearUserSession());
          }),
          takeUntil(action$.pipe(ofType(NOTIFY_ROUTE_CHANGE))),
        ),
    ),
  );

export default combineEpics(
  fetchUserSessionEpic,
  loginUserRequestEpic,
  logoutUserRequestEpic,
  forgotPasswordRequestEpic,
  passwordRecoveryRequestEpic,
);
