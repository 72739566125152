import { useFormikContext } from 'formik';
import { useCallback, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { interviewActions } from '../../actions';
import { interviewAPI } from '../../api';
import { useDebounce } from '../../hooks';
import { TranslationKey } from '../../i18n/translations';
import { commonSelectors, interviewSelectors } from '../../selectors';
import { logger } from '../../services';
import { IAnswer } from '../../types';

const SavePartialQuestionary = (): null => {
  const interviewId = useSelector(interviewSelectors.getId);
  const mountedRef = useRef(false);

  const dispatch = useDispatch();
  const setSaving = useCallback(
    (saving: boolean) => {
      dispatch(interviewActions.setSaving(saving));
    },
    [dispatch],
  );

  const { values } = useFormikContext<Record<string, IAnswer>>();

  const intl = useIntl();
  // Stores the translated error in a ref
  const formattedError = useRef(
    intl.formatMessage({
      id: TranslationKey.ERROR_SAVING_PARTIAL_QUESTIONARY,
    }),
  );
  useEffect(() => {
    formattedError.current = intl.formatMessage({
      id: TranslationKey.ERROR_SAVING_PARTIAL_QUESTIONARY,
    });
  }, [intl]);

  const ONE_SECOND = 1000;
  const debouncedValues = useDebounce(values, ONE_SECOND);
  const canEditInterview = useSelector(commonSelectors.canEditInterview);

  useEffect(() => {
    if (!interviewId || !mountedRef.current || !canEditInterview) {
      setSaving(false);
      mountedRef.current = true;
      return undefined;
    }

    setSaving(true);

    const subscription$ = interviewAPI
      .savePartialQuestionary(interviewId, debouncedValues)
      .subscribe(
        (res) => {
          logger.info(
            `Saved partial questions for interview for interviewId: ${interviewId}.`,
            res,
          );
          setSaving(false);
        },
        (err) => {
          logger.error(
            `Error saving partial questions for interviewId: ${interviewId}.`,
            err,
          );
          toast.error(formattedError.current);
          setSaving(false);
        },
      );

    return () => {
      subscription$.unsubscribe();
    };
  }, [debouncedValues, interviewId, canEditInterview, setSaving]);

  return null;
};

export default SavePartialQuestionary;
