import { createSelector } from 'reselect';
import { ChartReportType, ChartType } from '../enums';
import { mapSLAReportData } from '../helpers/util-functions';
import { AppState, ReportsState } from '../types';

const getModuleState = (state: AppState): ReportsState => state.reports;

export const getFetching = createSelector(
  getModuleState,
  (state) => state.fetching,
);

export const getConfig = createSelector(
  getModuleState,
  (state) => state.config,
);

export const getReportData = createSelector(
  getModuleState,
  (state) => state.reportData,
);

export const getMappedReportData = createSelector(
  [getConfig, getReportData],
  (config, reportData) =>
    config?.reportType === ChartReportType.KPI &&
    config?.chartType === ChartType.Bar
      ? reportData
      : mapSLAReportData(reportData),
);

export const getDownloading = createSelector(
  getModuleState,
  (state) => state.downloading,
);
