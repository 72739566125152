import {
  CircularProgress,
  Grid,
  IconButton,
  TooltipProps,
} from '@material-ui/core';
import Container from '@material-ui/core/Container/Container';
import { Done as DoneIcon, Edit as EditIcon } from '@material-ui/icons';
import { Form, Formik } from 'formik';
import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { interviewActions } from '../../actions';
import ABIButton from '../../components/ABIButton';
import {
  FormAutocompleteField,
  FormDateField,
  FormSelectField,
  FormTextField,
} from '../../components/FormFields';
import LoadingSpinner from '../../components/LoadingSpinner';
import Question from '../../components/Question';
import { apiURL } from '../../config/environment';
import {
  BooleanSelection,
  BusinessUnitCode,
  CountryCode,
  EExitType,
  Gender,
  NewInterviewField,
  OPRRating,
  QuestionaryFieldPrefix,
  QuestionaryType,
  VicePresidencyCode,
} from '../../enums';
import { useFetchQuestions } from '../../hooks';
import { TranslationKey } from '../../i18n/translations';
import { interviewSelectors } from '../../selectors';
import isoCountries from '../../services/isoCountries';
import { NewInterviewValues } from '../../types';
import TranslateFormikErrors from '../TranslateFormikErrors';
import FetchCollaborator from './FetchCollaborator';
import styles from './NewInterview.module.scss';
import getValidationSchema from './schema';
import ValueCorrections from './ValueCorrections';

const initialValues: NewInterviewValues = {
  [NewInterviewField.ApplicationDate]: new Date(),
  [NewInterviewField.ID]: '',
  [NewInterviewField.Name]: '',
  [NewInterviewField.Sex]: '',
  [NewInterviewField.LineManager]: '',
  [NewInterviewField.LineManagerEmail]: '',
  [NewInterviewField.Band]: '',
  [NewInterviewField.Position]: '',
  [NewInterviewField.Country]: '',
  [NewInterviewField.UEN]: '',
  [NewInterviewField.Location]: '',
  [NewInterviewField.BusinessUnit]: '',
  [NewInterviewField.VicePresidency]: '',
  [NewInterviewField.HiringDate]: '',
  [NewInterviewField.OprRating]: '',
  [NewInterviewField.ExitDate]: '',
  [NewInterviewField.ExitType]: '',
  [NewInterviewField.AgreedToInterview]: '',
  [QuestionaryFieldPrefix.MainExitMotive]: {
    answer: '',
    comments: '',
  },
};

const NewInterview = (): ReactElement => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleSubmit = useCallback(
    (newInterview: NewInterviewValues) => {
      dispatch(interviewActions.createInterviewRequest(newInterview));
    },
    [dispatch],
  );

  const intl = useIntl();
  const validationSchema = useMemo(() => getValidationSchema(intl), [intl]);

  const [editingCollaborator, setEditingCollaborator] = useState(false);
  const handleEditClick = useCallback(() => {
    setEditingCollaborator(!editingCollaborator);
  }, [editingCollaborator]);

  const [fetchingCollaborator, setFetchingCollaborator] = useState(false);
  const handleSetFetching = useCallback((fetching: boolean) => {
    setEditingCollaborator(false);
    setFetchingCollaborator(fetching);
  }, []);

  const sending = useSelector(interviewSelectors.getSending);
  const fieldDisabled = !editingCollaborator || fetchingCollaborator || sending;

  const commonTooltipProps: Partial<TooltipProps> = {
    arrow: true,
    placement: 'top',
  };

  const [voluntary, setVoluntary] = useState(true);
  const handleExitTypeChange = useCallback((newValue) => {
    setVoluntary(newValue === EExitType.Voluntary);
  }, []);
  const [fetchingQuestionary, questions] = useFetchQuestions(
    QuestionaryType.Interview,
    intl,
    voluntary,
  );
  const onReturnClick = () => {
    navigate(-1);
  };
  const exitMotivesQuestion = useMemo(
    () =>
      questions.find(
        ({ fieldPrefix }) =>
          fieldPrefix === QuestionaryFieldPrefix.MainExitMotive,
      ),
    [questions],
  );

  const disabledRegister =
    fetchingCollaborator ||
    fetchingQuestionary ||
    !exitMotivesQuestion ||
    sending;

  return (
    <div className={styles.container}>
      <div className={styles.formContainer}>
        <Container>
          <Grid
            container
            spacing={1}
            alignItems="center"
            justify="center"
            direction="column"
            className={styles.gridContainer}
          >
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              autoComplete="off"
            >
              {({ values }) => (
                <Form noValidate autoComplete="off">
                  <TranslateFormikErrors />
                  <FetchCollaborator setFetching={handleSetFetching} />
                  <ValueCorrections />

                  <Grid
                    container
                    spacing={2}
                    className={styles.collaboratorContainer}
                  >
                    <div className={styles.subFormContainer}>
                      <h3>
                        <FormattedMessage
                          id={TranslationKey.INTERVIEW_FIRST_SECTION}
                        />
                      </h3>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={2}>
                          <FormTextField
                            name="id"
                            label="ID"
                            type="number"
                            required
                            disabled={sending}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4} md={2}>
                          <FormDateField
                            name={NewInterviewField.ApplicationDate}
                            label={intl.formatMessage({
                              id: TranslationKey.APPLICATION_DATE,
                            })}
                            required
                            disabled
                          />
                        </Grid>
                      </Grid>
                    </div>
                    <div className={styles.subFormContainer}>
                      <h2>
                        <FormattedMessage
                          id={TranslationKey.TEAM_MEMBER_DATA}
                        />
                      </h2>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={4}>
                          <FormTextField
                            name={NewInterviewField.Name}
                            label={intl.formatMessage({
                              id: TranslationKey.EMPLOYEE_NAME,
                            })}
                            required
                            disabled={fieldDisabled}
                            loading={fetchingCollaborator}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                          <FormSelectField
                            name={NewInterviewField.Sex}
                            label={intl.formatMessage({
                              id: TranslationKey.SEX,
                            })}
                            required
                            disabled={fieldDisabled}
                            loading={fetchingCollaborator}
                            options={Object.values(Gender).map((gender) => ({
                              value: gender,
                              label: gender,
                            }))}
                          />
                        </Grid>
                        <div style={{ width: '100%' }} />
                        <Grid item xs={12} sm={6} md={2}>
                          <FormDateField
                            name={NewInterviewField.HiringDate}
                            label={intl.formatMessage({
                              id: TranslationKey.HIRING_DATE,
                            })}
                            required
                            maxDate={new Date()}
                            disabled={fieldDisabled}
                            loading={fetchingCollaborator}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                          <FormTextField
                            name={NewInterviewField.Band}
                            label={intl.formatMessage({
                              id: TranslationKey.BAND,
                            })}
                            required
                            disabled={fieldDisabled}
                            loading={fetchingCollaborator}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <FormTextField
                            name={NewInterviewField.Position}
                            label={intl.formatMessage({
                              id: TranslationKey.POSITION,
                            })}
                            required
                            disabled={fieldDisabled}
                            loading={fetchingCollaborator}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                          <FormAutocompleteField
                            apiURL={`${apiURL}/auth/autocomplete/uen`}
                            name={NewInterviewField.UEN}
                            label={intl.formatMessage({
                              id: TranslationKey.UEN,
                            })}
                            required
                            disabled={fieldDisabled}
                            loading={fetchingCollaborator}
                          />
                        </Grid>
                        <div style={{ width: '100%' }} />
                        <Grid item xs={12} sm={6} md={2}>
                          <FormAutocompleteField
                            apiURL={`${apiURL}/auth/autocomplete/location`}
                            name={NewInterviewField.Location}
                            label={intl.formatMessage({
                              id: TranslationKey.LOCATION,
                            })}
                            required
                            disabled={fieldDisabled}
                            loading={fetchingCollaborator}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                          <FormSelectField
                            name={NewInterviewField.BusinessUnit}
                            label="BU"
                            required
                            disabled={fieldDisabled}
                            loading={fetchingCollaborator}
                            options={Object.values(BusinessUnitCode).map(
                              (code) => ({
                                value: code,
                                label: intl.formatMessage({
                                  id: `BUSINESS_UNIT_${code}`,
                                }),
                              }),
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                          <FormSelectField
                            name={NewInterviewField.Country}
                            label={intl.formatMessage({
                              id: TranslationKey.COUNTRY,
                            })}
                            required
                            disabled={fieldDisabled}
                            loading={fetchingCollaborator}
                            options={Object.values(CountryCode).map((code) => ({
                              value: code,
                              label: isoCountries.getName(code, intl.locale),
                            }))}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <FormSelectField
                            name={NewInterviewField.VicePresidency}
                            label={intl.formatMessage({
                              id: TranslationKey.VP,
                            })}
                            required
                            disabled={fieldDisabled}
                            loading={fetchingCollaborator}
                            options={Object.values(VicePresidencyCode).map(
                              (code) => ({
                                value: code,
                                label: intl.formatMessage({
                                  id: `VP_${code}`,
                                }),
                              }),
                            )}
                          />
                        </Grid>
                      </Grid>
                      <div className={styles.separator} />
                      <h2>
                        <FormattedMessage id={TranslationKey.LINE_MNGR_DATA} />
                      </h2>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={5}>
                          <FormAutocompleteField
                            name={NewInterviewField.LineManager}
                            label={intl.formatMessage({
                              id: TranslationKey.LINE_MANAGER,
                            })}
                            apiURL={`${apiURL}/auth/line-manager`}
                            queryProperty="name"
                            disabled={fieldDisabled}
                            loading={fetchingCollaborator}
                            required
                          />
                        </Grid>
                        <Grid item xs={12} md={5}>
                          <FormTextField
                            name={NewInterviewField.LineManagerEmail}
                            label={intl.formatMessage({
                              id: TranslationKey.LINE_MANAGER_EMAIL,
                            })}
                            disabled={fieldDisabled}
                            loading={fetchingCollaborator}
                            required
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={2}
                          className={styles.editButton}
                          justifyContent="center"
                          alignItems="flex-end"
                        >
                          <div>
                            <IconButton
                              color="inherit"
                              onClick={handleEditClick}
                              disabled={fetchingCollaborator}
                            >
                              {editingCollaborator ? (
                                <DoneIcon fontSize="inherit" />
                              ) : (
                                <EditIcon fontSize="inherit" />
                              )}
                            </IconButton>
                            <span>
                              {editingCollaborator ? (
                                <FormattedMessage id={TranslationKey.ACCEPT} />
                              ) : (
                                <FormattedMessage
                                  id={TranslationKey.EDIT_INFO}
                                />
                              )}
                            </span>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                    <div className={styles.subFormContainer}>
                      <h2>
                        <FormattedMessage id={TranslationKey.QUIT_DATA} />
                      </h2>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={2}>
                          <FormSelectField
                            name={NewInterviewField.OprRating}
                            label={intl.formatMessage({
                              id: TranslationKey.OPR_RATING,
                            })}
                            disabled={sending}
                            options={Object.values(OPRRating).map((value) => ({
                              label: value,
                              value,
                            }))}
                            tooltipProps={{
                              ...commonTooltipProps,
                              title: intl.formatMessage({
                                id: TranslationKey.TOOLTIP_OPR,
                              }),
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={2}>
                          <FormDateField
                            name={NewInterviewField.ExitDate}
                            label={intl.formatMessage({
                              id: TranslationKey.EXIT_DATE,
                            })}
                            required
                            disabled={sending}
                            tooltipProps={{
                              ...commonTooltipProps,
                              title: intl.formatMessage({
                                id: TranslationKey.TOOLTIP_EXIT_DATE,
                              }),
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={2}>
                          <FormSelectField
                            name={NewInterviewField.ExitType}
                            label={intl.formatMessage({
                              id: TranslationKey.EXIT_TYPE,
                            })}
                            options={Object.values(EExitType).map((value) => ({
                              value,
                              label: intl.formatMessage({
                                id: value,
                              }),
                            }))}
                            required
                            disabled={sending}
                            tooltipProps={{
                              ...commonTooltipProps,
                              title: intl.formatMessage({
                                id: TranslationKey.TOOLTIP_EXIT_TYPE,
                              }),
                            }}
                            onValueChange={handleExitTypeChange}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <FormSelectField
                            name={NewInterviewField.AgreedToInterview}
                            label={intl.formatMessage({
                              id: TranslationKey.AGREED_TO_INTERVIEW,
                            })}
                            options={Object.values(BooleanSelection).map(
                              (value) => ({
                                value,
                                label: intl.formatMessage({
                                  id: value,
                                }),
                              }),
                            )}
                            required
                            disabled={sending}
                            tooltipProps={{
                              ...commonTooltipProps,
                              title: intl.formatMessage({
                                id: TranslationKey.TOOLTIP_AGREED_TO_INTERVIEW,
                              }),
                            }}
                          />
                        </Grid>
                        {/*  */}
                        <Grid
                          item
                          xs={12}
                          style={{
                            display: values[NewInterviewField.ExitType]
                              ? 'block'
                              : 'none',
                          }}
                        >
                          {fetchingQuestionary || !exitMotivesQuestion ? (
                            <LoadingSpinner size="3em" thickness={5} />
                          ) : (
                            <Question
                              {...exitMotivesQuestion}
                              disabled={
                                fetchingQuestionary ||
                                !exitMotivesQuestion ||
                                !values[NewInterviewField.ExitType]
                              }
                              loading={
                                fetchingQuestionary || !exitMotivesQuestion
                              }
                              withComments
                            />
                          )}
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>

                  <Grid
                    container
                    item
                    xs={12}
                    spacing={2}
                    justifyContent="flex-end"
                    className={styles.buttonContainer}
                  >
                    <Grid item xs={2}>
                      <ABIButton
                        type="button"
                        buttonType="return"
                        onClick={onReturnClick}
                      >
                        <FormattedMessage id={TranslationKey.BACK} />
                      </ABIButton>
                    </Grid>
                    <Grid item xs={2}>
                      <ABIButton type="submit" disabled={disabledRegister}>
                        {sending && (
                          <CircularProgress
                            size="14px"
                            color="inherit"
                            thickness={6}
                            className={styles.buttonSpinner}
                          />
                        )}
                        <FormattedMessage id={TranslationKey.REGISTER} />
                      </ABIButton>
                    </Grid>
                  </Grid>

                  {sending && (
                    <Grid
                      container
                      item
                      xs={12}
                      justify="center"
                      alignItems="center"
                    >
                      <CircularProgress
                        color="primary"
                        thickness={5}
                        size="3em"
                        className={styles.spinner}
                      />
                    </Grid>
                  )}
                </Form>
              )}
            </Formik>
          </Grid>
        </Container>
      </div>
    </div>
  );
};

export default NewInterview;
