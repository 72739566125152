import { Grid } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import earlyEndingImage from '../../../assets/images/early-ending-background.png';
import { TranslationKey } from '../../../i18n/translations';
import MainButton from '../../MainButton';
import styles from './EarlyEnding.module.scss';

const EarlyEnding = (): ReactElement => (
  <div className={styles.container}>
    <Grid container>
      <Grid item xs={12} sm={12} md={6} className={styles.text}>
        <h3>
          <FormattedMessage id={TranslationKey.EARLY_ENDING} />
        </h3>
        <h1>
          <FormattedMessage id={TranslationKey.THANK_YOU} />
        </h1>
      </Grid>

      <Grid item xs={12} sm={12} md={6} className={styles.imageContainer}>
        <img
          src={earlyEndingImage}
          alt="Checklist board"
          className={styles.image}
        />
      </Grid>
    </Grid>

    <Grid
      container
      justify="center"
      alignItems="center"
      className={styles.buttonContainer}
    >
      <Link to="/">
        <MainButton type="button">
          <FormattedMessage id={TranslationKey.ACCEPT} />
        </MainButton>
      </Link>
    </Grid>
  </div>
);

export default EarlyEnding;
