import { useFormikContext } from 'formik';
import { isEqual as _isEqual, omit as _omit } from 'lodash-es';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userSessionActions } from '../../actions';
import { usePrevious } from '../../hooks';
import { userSessionSelectors } from '../../selectors';

type FormValues = Record<string, string>;

const CredentialsError = (): null => {
  const { setFieldValue, values } = useFormikContext<FormValues>();
  const credentialsError = useSelector(
    userSessionSelectors.getCredentialsError,
  );

  useEffect(() => {
    setFieldValue('credentialsError', credentialsError);
  }, [credentialsError, setFieldValue]);

  const prevValues = usePrevious(values);
  const equalValues = _isEqual(
    _omit(prevValues, 'credentialsError'),
    _omit(values, 'credentialsError'),
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (equalValues) return;
    dispatch(userSessionActions.setCredentialsError(false));
  }, [dispatch, equalValues]);

  return null;
};

export default CredentialsError;
