import React, { HTMLProps, ReactElement } from 'react';
import {
  FormattedDate,
  FormattedMessage,
  IntlShape,
  useIntl,
} from 'react-intl';
import { useSelector } from 'react-redux';
import {
  DiscreteColorLegend,
  HorizontalGridLines,
  LabelSeries,
  VerticalBarSeries,
  XAxis,
  XYPlot,
  YAxis,
} from 'react-vis';
import {
  ChartReportType,
  ExitMotive,
  KPIField,
  ReportTickType,
} from '../../enums';
import { TranslationKey } from '../../i18n/translations';
import { reportsSelectors } from '../../selectors';
import isoCountries from '../../services/isoCountries';
import { ReportConfig } from '../../types';
import { exitMotiveColors, KPIColors } from './colors';
import styles from './ReportChart.module.scss';

const tickTypeMap: Record<
  ReportTickType,
  (field: string, intl: IntlShape) => string
> = {
  [ReportTickType.BusinessUnit]: (field, innerIntl) =>
    innerIntl.formatMessage({
      id: `BUSINESS_UNIT_${field}`,
    }),
  [ReportTickType.Country]: (field, innerIntl) =>
    isoCountries.getName(field, innerIntl.locale),
  [ReportTickType.VicePresidency]: (field, innerIntl) =>
    innerIntl.formatMessage({
      id: `VP_${field}`,
    }),
  [ReportTickType.Location]: (field) => field,
};

interface BarChartProps extends HTMLProps<HTMLDivElement> {
  height: number;
  width: number;
}

const BarChart = React.forwardRef<HTMLDivElement | null, BarChartProps>(
  ({ height, width }, ref): ReactElement => {
    const intl = useIntl();
    const reportData = useSelector(reportsSelectors.getMappedReportData);

    const reportConfig = useSelector(
      reportsSelectors.getConfig,
    ) as ReportConfig;
    const { reportType, tickType, from, until } = reportConfig;
    const isKPI = reportType === ChartReportType.KPI;
    const fontSize = 10;

    const legendItems = Object.keys(reportData || {}).map((label) => ({
      title: intl.formatMessage({
        id: isKPI ? label : `EXIT_MOTIVE_${label}`,
      }),
      color: isKPI
        ? KPIColors[label as KPIField]
        : exitMotiveColors[label as ExitMotive],
      strokeWidth: 12,
    }));

    const range = 100;
    const tickValues = Object.values(reportData || {})[0]?.map(
      (__, index) => index + 1,
    );

    const tickFormatter = tickTypeMap[tickType || ReportTickType.Location];
    const tickFormat = (value: number) =>
      tickFormatter(Object.values(reportData || {})[0][value - 1].field, intl);
    const sumMap = Object.values(reportData || {}).reduce((currMap, record) => {
      const newMap = {
        ...currMap,
      };
      record.forEach(({ field, value }) => {
        newMap[field] = value + (currMap[field] || 0);
      });
      return newMap;
    }, {} as Record<string, number>);

    return (
      <div ref={ref} className={styles.chartContainer}>
        <h2 className={styles.title}>
          <FormattedMessage id={`REPORT_TYPE_${reportType}_TITLE`} />
        </h2>
        {reportType === ChartReportType.KPI && (
          <h4 className={styles.subTitle}>% de cumplimiento</h4>
        )}

        <h6 className={styles.dates}>
          <FormattedMessage id={TranslationKey.FROM} />{' '}
          <FormattedDate value={from} />{' '}
          {intl
            .formatMessage({
              id: TranslationKey.UNTIL,
            })
            .toLowerCase()}{' '}
          <FormattedDate value={until} />
        </h6>

        <XYPlot
          stackBy="y"
          height={height}
          width={width}
          yDomain={[0, range]}
          className={styles.xyPlot}
        >
          <DiscreteColorLegend
            className={styles.chartLegend}
            orientation="vertical"
            items={legendItems}
          />

          <XAxis hideLine tickValues={tickValues} tickFormat={tickFormat} />
          <YAxis hideLine />
          <HorizontalGridLines />

          {Object.entries(reportData || {}).map(([key, record]) => (
            <VerticalBarSeries
              key={key}
              color={
                isKPI
                  ? KPIColors[key as KPIField]
                  : exitMotiveColors[key as ExitMotive]
              }
              barWidth={0.5}
              data={Object.values(record).map(({ field, value }, index) => {
                const fieldTotal = sumMap[field];
                const recordValue = fieldTotal ? (value * 100) / fieldTotal : 0;
                return {
                  x: index + 1,
                  y: recordValue,
                };
              })}
            />
          ))}

          {Object.entries(reportData || {}).map(([key, record]) => (
            <LabelSeries
              key={key}
              labelAnchorX="middle"
              labelAnchorY="middle"
              className={styles.barLabel}
              data={Object.values(record).map(({ field, value }, index) => {
                const fieldTotal = sumMap[field];
                const recordValue = fieldTotal ? (value * 100) / fieldTotal : 0;
                return {
                  x: index + 1,
                  y: recordValue,
                  label: recordValue ? `${recordValue.toFixed(2)}%` : '',
                  yOffset:
                    (height / range) * (recordValue / 2) +
                    fontSize / (height / range) / 2,
                  style: {
                    fontSize,
                  },
                };
              })}
            />
          ))}
        </XYPlot>
      </div>
    );
  },
);

export default BarChart;
