import { IntlShape } from 'react-intl';
import * as yup from 'yup';
import { bigBUs, smallBUs } from '../../constants';
import {
  BusinessUnitCode,
  ChartReportType,
  ReportFilterField,
  UserRole,
} from '../../enums';
import { TranslationKey } from '../../i18n/translations';
import { ReportFilterValues } from '../../types';

export const getChartValidationSchema = (
  intl: IntlShape,
  userRole: UserRole,
  userBU: BusinessUnitCode,
): yup.SchemaOf<unknown> => {
  const requiredGeneric = intl.formatMessage({
    id: TranslationKey.VALIDATE_REQUIRED_GENERIC,
  });

  const scopeMessage = intl.formatMessage({
    id: TranslationKey.VALIDATE_MUST_SELECT_SCOPE,
  });
  let additionalFields = {};
  if (userRole === UserRole.Admin) {
    const scopeFunction = function scope(this: {
      parent: ReportFilterValues;
    }): boolean {
      const zone = this.parent[ReportFilterField.AllZone];
      const businessUnits = this.parent[ReportFilterField.BusinessUnits];
      return zone || !!businessUnits?.length;
    };
    additionalFields = {
      [ReportFilterField.AllZone]: yup
        .boolean()
        .test('no-scope-zone', scopeMessage, scopeFunction),
      [ReportFilterField.BusinessUnits]: yup
        .array()
        .of(yup.string())
        .test('no-scope-bu', scopeMessage, scopeFunction),
    };
  } else if (userRole === UserRole.Owner && bigBUs.includes(userBU)) {
    const scopeFunction = function scope(this: {
      parent: ReportFilterValues;
    }): boolean {
      const allBU = this.parent[ReportFilterField.AllBU];
      const vicePresidencies = this.parent[ReportFilterField.VicePresidencies];
      const uens = this.parent[ReportFilterField.UENs];
      return allBU || !!vicePresidencies?.length || !!uens?.length;
    };
    additionalFields = {
      [ReportFilterField.AllBU]: yup
        .boolean()
        .test('no-scope-bu', scopeMessage, scopeFunction),
      [ReportFilterField.VicePresidencies]: yup
        .array()
        .of(yup.string())
        .test('no-scope-vps', scopeMessage, scopeFunction),
      [ReportFilterField.UENs]: yup
        .array()
        .of(
          yup.object().shape({
            name: yup.string(),
          }),
        )
        .test('no-scope-uens', scopeMessage, scopeFunction),
    };
  } else if (userRole === UserRole.Owner && smallBUs.includes(userBU)) {
    const scopeFunction = function scope(this: {
      parent: ReportFilterValues;
    }): boolean {
      const allBU = this.parent[ReportFilterField.AllBU];
      const countries = this.parent[ReportFilterField.Countries];
      return allBU || !!countries?.length;
    };
    additionalFields = {
      [ReportFilterField.AllBU]: yup
        .boolean()
        .test('no-scope-bu', scopeMessage, scopeFunction),
      [ReportFilterField.Countries]: yup
        .array()
        .of(yup.string())
        .test('no-scope-countries', scopeMessage, scopeFunction),
    };
  } else if (userRole === UserRole.Regular) {
    const scopeFunction = function scope(this: {
      parent: ReportFilterValues;
    }): boolean {
      const vicePresidencies = this.parent[ReportFilterField.VicePresidencies];
      const uens = this.parent[ReportFilterField.UENs];
      return !!vicePresidencies?.length || !!uens?.length;
    };
    additionalFields = {
      [ReportFilterField.VicePresidencies]: yup
        .array()
        .of(yup.string().defined())
        .test('no-scope-vp', scopeMessage, scopeFunction),
      [ReportFilterField.UENs]: yup
        .array()
        .of(
          yup.object().shape({
            name: yup.string(),
          }),
        )
        .test('no-scope-vp', scopeMessage, scopeFunction),
    };
  }

  return yup.object().shape({
    [ReportFilterField.ReportType]: yup
      .mixed()
      .oneOf(Object.values(ChartReportType))
      .required(requiredGeneric),
    [ReportFilterField.From]: yup.date().required(requiredGeneric),
    ...additionalFields,
  });
};

export const getExcelValidationSchema = (
  intl: IntlShape,
): yup.SchemaOf<unknown> => {
  const requiredGeneric = intl.formatMessage({
    id: TranslationKey.VALIDATE_REQUIRED_GENERIC,
  });

  return yup.object().shape({
    [ReportFilterField.From]: yup.date().required(requiredGeneric),
  });
};
