import React, { ReactElement, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { historyActions } from '../../actions';

const HistoryContainer = (): ReactElement => {
  const dispatch = useDispatch();
  useEffect(
    () => () => {
      dispatch(historyActions.clearState());
    },
    [dispatch],
  );
  return <Outlet />;
};

export default HistoryContainer;
