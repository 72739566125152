import { IntlShape } from 'react-intl';
import * as yup from 'yup';
import {
  BooleanSelection,
  EExitType,
  NewInterviewField,
  QuestionaryFieldPrefix,
} from '../../enums';
import { TranslationKey } from '../../i18n/translations';

const getValidationSchema = (intl: IntlShape): yup.SchemaOf<unknown> =>
  yup.object().shape({
    [NewInterviewField.ApplicationDate]: yup.date().required(
      intl.formatMessage(
        {
          id: TranslationKey.VALIDATE_REQUIRED,
        },
        {
          fieldName: intl.formatMessage({
            id: TranslationKey.APPLICATION_DATE,
          }),
        },
      ),
    ),
    [NewInterviewField.ID]: yup.number().required(
      intl.formatMessage(
        {
          id: TranslationKey.VALIDATE_REQUIRED,
        },
        {
          fieldName: 'ID',
        },
      ),
    ),
    [NewInterviewField.Name]: yup.string().required(
      intl.formatMessage(
        {
          id: TranslationKey.VALIDATE_REQUIRED,
        },
        {
          fieldName: intl.formatMessage({
            id: TranslationKey.EMPLOYEE_NAME,
          }),
        },
      ),
    ),
    [NewInterviewField.Sex]: yup.string().required(
      intl.formatMessage(
        {
          id: TranslationKey.VALIDATE_REQUIRED,
        },
        {
          fieldName: intl.formatMessage({
            id: TranslationKey.SEX,
          }),
        },
      ),
    ),
    [NewInterviewField.LineManager]: yup
      .object()
      .nullable()
      .shape({
        id: yup.number().nullable(),
        name: yup.string(),
      })
      .test(
        'has-lm',
        intl.formatMessage(
          {
            id: TranslationKey.VALIDATE_REQUIRED,
          },
          {
            fieldName: intl.formatMessage({
              id: TranslationKey.LINE_MANAGER,
            }),
          },
        ),
        (value): boolean =>
          value?.id !== 0 && value?.id !== undefined && value?.id !== null,
      )
      .required(
        intl.formatMessage(
          {
            id: TranslationKey.VALIDATE_REQUIRED,
          },
          {
            fieldName: intl.formatMessage({
              id: TranslationKey.LINE_MANAGER,
            }),
          },
        ),
      ) as yup.AnySchema,

    [NewInterviewField.LineManagerEmail]: yup
      .string()
      .nullable()
      .required(
        intl.formatMessage(
          {
            id: TranslationKey.VALIDATE_REQUIRED,
          },
          {
            fieldName: intl.formatMessage({
              id: TranslationKey.LINE_MANAGER_EMAIL,
            }),
          },
        ),
      )
      .email(
        intl.formatMessage({
          id: TranslationKey.VALIDATE_EMAIL,
        }),
      ),
    [NewInterviewField.Band]: yup.string().required(
      intl.formatMessage(
        {
          id: TranslationKey.VALIDATE_REQUIRED,
        },
        {
          fieldName: intl.formatMessage({
            id: TranslationKey.BAND,
          }),
        },
      ),
    ),
    [NewInterviewField.Position]: yup.string().required(
      intl.formatMessage(
        {
          id: TranslationKey.VALIDATE_REQUIRED,
        },
        {
          fieldName: intl.formatMessage({
            id: TranslationKey.POSITION,
          }),
        },
      ),
    ),
    [NewInterviewField.Country]: yup.string().required(
      intl.formatMessage(
        {
          id: TranslationKey.VALIDATE_REQUIRED,
        },
        {
          fieldName: intl.formatMessage({
            id: TranslationKey.COUNTRY,
          }),
        },
      ),
    ),
    [NewInterviewField.UEN]: yup
      .object()
      .nullable()
      .shape({
        id: yup.number(),
        name: yup.string().nullable(),
      })
      .test(
        'has-uen',
        intl.formatMessage(
          {
            id: TranslationKey.VALIDATE_REQUIRED,
          },
          {
            fieldName: intl.formatMessage({
              id: TranslationKey.UEN,
            }),
          },
        ),
        (value): boolean => !!value?.name,
      )
      .required(
        intl.formatMessage(
          {
            id: TranslationKey.VALIDATE_REQUIRED,
          },
          {
            fieldName: intl.formatMessage({
              id: TranslationKey.UEN,
            }),
          },
        ),
      ) as yup.AnySchema,
    [NewInterviewField.Location]: yup
      .object()
      .nullable()
      .shape({
        id: yup.number(),
        name: yup.string().nullable(),
      })
      .test(
        'has-location',
        intl.formatMessage(
          {
            id: TranslationKey.VALIDATE_REQUIRED,
          },
          {
            fieldName: intl.formatMessage({
              id: TranslationKey.LOCATION,
            }),
          },
        ),
        (value): boolean => !!value?.name,
      )
      .required(
        intl.formatMessage(
          {
            id: TranslationKey.VALIDATE_REQUIRED,
          },
          {
            fieldName: intl.formatMessage({
              id: TranslationKey.LOCATION,
            }),
          },
        ),
      ) as yup.AnySchema,
    [NewInterviewField.BusinessUnit]: yup.string().required(
      intl.formatMessage(
        {
          id: TranslationKey.VALIDATE_REQUIRED,
        },
        {
          fieldName: 'BU',
        },
      ),
    ),
    [NewInterviewField.VicePresidency]: yup.string().required(
      intl.formatMessage(
        {
          id: TranslationKey.VALIDATE_REQUIRED,
        },
        {
          fieldName: intl.formatMessage({
            id: TranslationKey.VP,
          }),
        },
      ),
    ),
    [NewInterviewField.HiringDate]: yup
      .date()
      .nullable()
      .required(
        intl.formatMessage(
          {
            id: TranslationKey.VALIDATE_REQUIRED,
          },
          {
            fieldName: intl.formatMessage({
              id: TranslationKey.HIRING_DATE,
            }),
          },
        ),
      ),
    [NewInterviewField.OprRating]: yup.string(),
    [NewInterviewField.ExitDate]: yup
      .date()
      .nullable()
      .required(
        intl.formatMessage(
          {
            id: TranslationKey.VALIDATE_REQUIRED,
          },
          {
            fieldName: intl.formatMessage({
              id: TranslationKey.EXIT_DATE,
            }),
          },
        ),
      ),
    [NewInterviewField.ExitType]: yup
      .mixed()
      .oneOf(Object.values(EExitType))
      .required(
        intl.formatMessage(
          {
            id: TranslationKey.VALIDATE_REQUIRED,
          },
          {
            fieldName: intl.formatMessage({
              id: TranslationKey.EXIT_TYPE,
            }),
          },
        ),
      ),
    [NewInterviewField.AgreedToInterview]: yup
      .mixed()
      .oneOf(Object.values(BooleanSelection))
      .required(
        intl.formatMessage(
          {
            id: TranslationKey.VALIDATE_REQUIRED,
          },
          {
            fieldName: intl.formatMessage({
              id: TranslationKey.AGREED_TO_INTERVIEW,
            }),
          },
        ),
      ),
    [QuestionaryFieldPrefix.MainExitMotive]: yup.object().shape({
      answer: yup.string().required(
        intl.formatMessage(
          {
            id: TranslationKey.VALIDATE_REQUIRED,
          },
          {
            fieldName: intl.formatMessage({
              id: TranslationKey.THIS_QUESTION,
            }),
          },
        ),
      ),
      comments: yup.string().required(
        intl.formatMessage(
          {
            id: TranslationKey.VALIDATE_REQUIRED,
          },
          {
            fieldName: intl.formatMessage({
              id: TranslationKey.BP_OBSERVATIONS,
            }),
          },
        ),
      ),
    }),
  });

export default getValidationSchema;
