import { combineEpics, Epic, ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { wordCloudActions } from '../actions';
import {
  AppAction,
  FetchWordCloudDataAction,
  FETCH_WORD_CLOUD_DATA,
  SetWordCloudDataAction,
} from '../actions/actionTypes';
import { wordCloudAPI } from '../api';
import { userSessionSelectors } from '../selectors';
import { logger } from '../services';
import { AppState } from '../types';

export const fetchDataEpic: Epic<
  AppAction,
  FetchWordCloudDataAction | SetWordCloudDataAction,
  AppState
> = (action$, state$) =>
  action$.pipe(
    ofType<AppAction, FetchWordCloudDataAction>(FETCH_WORD_CLOUD_DATA),
    map((action) => ({
      ...action.filters,
      locale: userSessionSelectors.getLocale(state$.value),
    })),
    tap((filters) => {
      logger.info('Fetching report data with: ', filters);
    }),
    mergeMap((filters) =>
      wordCloudAPI.fetchWordCloudData(filters).pipe(
        map(({ response: { data, count } }) => {
          logger.info('Fetched report data.', data);
          return wordCloudActions.setWordCloudData(data, count);
        }),
        catchError((error) => {
          logger.error('Fetched report data.', error);
          return of(wordCloudActions.setWordCloudData([], 0));
        }),
      ),
    ),
  );

export default combineEpics(fetchDataEpic);
