import React, { ReactElement, useRef } from 'react';
import { Outlet } from 'react-router';
import Menu from '../../containers/Menu';
import Footer from '../Footer';
import styles from './External.module.scss';

const External = (): ReactElement => {
  const contentRef = useRef(null);
  return (
    <div className={styles.container}>
      <Menu contentRef={contentRef} />
      <div ref={contentRef} className={styles.content}>
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};

export default External;
