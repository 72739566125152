import { Grid } from '@material-ui/core';
import React, { ReactElement, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import helpIcon from '../../assets/images/icon-help.svg';
import { useHelpMailLink } from '../../hooks';
import { TranslationKey } from '../../i18n/translations';
import ContentTitle from '../ContentTitle';
import styles from './Help.module.scss';

const Help = (): ReactElement => {
  const helpLink =
    'https://anheuserbuschinbev.sharepoint.com/sites/somos_MAZ/simplificaMAZ/SitePages/Exit-Interview.aspx';
  const mailLink = useHelpMailLink();
  const samLink = 'https://abimaz.service-now.com';

  return (
    <Grid
      container
      className={styles.container}
      alignItems="flex-start"
      justify="flex-start"
    >
      <Grid item xs={12} spacing={1}>
        <ContentTitle>
          <FormattedMessage id={TranslationKey.HELP_TITLE} />
        </ContentTitle>
      </Grid>

      <Grid item sm={12} md={6} className={styles.textContainer}>
        <ol>
          <li className={styles.helpParagraph}>
            <FormattedMessage
              id={TranslationKey.HELP_P1}
              values={{
                a: (...chunks: ReactNode[]): ReactElement => (
                  <a href={helpLink} target="_blank" rel="noopener noreferrer">
                    {chunks}
                  </a>
                ),
              }}
            />
          </li>

          <li className={styles.helpParagraph}>
            <FormattedMessage
              id={TranslationKey.HELP_P2}
              values={{
                a: (...chunks: ReactNode[]): ReactElement => (
                  <a href={mailLink} target="_blank" rel="noopener noreferrer">
                    {chunks}
                  </a>
                ),
              }}
            />
          </li>

          <li className={styles.helpParagraph}>
            <FormattedMessage
              id={TranslationKey.HELP_P3}
              values={{
                a: (...chunks: ReactNode[]): ReactElement => (
                  <a href={samLink} target="_blank" rel="noopener noreferrer">
                    {chunks}
                  </a>
                ),
              }}
            />
          </li>
        </ol>
      </Grid>

      <Grid item sm={12} md={6} className={styles.imgContainer}>
        <img src={helpIcon} alt="Help icon" />
      </Grid>
    </Grid>
  );
};

export default Help;
