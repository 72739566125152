import { Observable, Subscriber } from 'rxjs';
import { ajax, AjaxResponse } from 'rxjs/ajax';
import { apiURL } from '../config/environment';
import { HttpMethod, Locale } from '../enums';
import { serializeQuery } from '../helpers/util-functions';
import { AdminFilters, AdminUserRequest } from '../types';

const contentTypeJSONHeader = {
  'Content-Type': 'application/json; charset=UTF-8',
};
const acceptJSONHeader = {
  Accept: 'application/json; charset=UTF-8',
};

export const fetchAdminUsers = (
  filters: AdminFilters,
): Observable<AjaxResponse> =>
  ajax({
    url: `${apiURL}/auth/user-admin?${serializeQuery(filters)}`,
    method: HttpMethod.Get,
    headers: acceptJSONHeader,
    withCredentials: true,
  });

export const createUsers = (
  users: AdminUserRequest[],
): Observable<AjaxResponse> =>
  ajax({
    url: `${apiURL}/auth/user-admin`,
    method: HttpMethod.Post,
    body: users,
    headers: {
      ...contentTypeJSONHeader,
      ...acceptJSONHeader,
    },
    withCredentials: true,
  });

export const updateUsers = (
  users: AdminUserRequest[],
): Observable<AjaxResponse> =>
  ajax({
    url: `${apiURL}/auth/user-admin`,
    method: HttpMethod.Put,
    body: users,
    headers: {
      ...contentTypeJSONHeader,
      ...acceptJSONHeader,
    },
    withCredentials: true,
  });

export const deleteUsers = (
  users: AdminUserRequest[],
): Observable<AjaxResponse> =>
  ajax({
    url: `${apiURL}/auth/user-admin`,
    method: HttpMethod.Delete,
    body: users,
    headers: {
      ...contentTypeJSONHeader,
      ...acceptJSONHeader,
    },
    withCredentials: true,
  });

export const uploadReport = (
  formData: FormData,
  progressSubscriber: Subscriber<ProgressEvent>,
  isHeadCount: boolean,
): Observable<AjaxResponse> =>
  ajax({
    url: isHeadCount
      ? `${apiURL}/auth/load-head-count`
      : `${apiURL}/auth/load-withdrawals`,
    method: HttpMethod.Post,
    body: formData,
    headers: acceptJSONHeader,
    withCredentials: true,
    progressSubscriber,
  });

interface UserReportResponse extends AjaxResponse {
  response: Blob;
}
export const getUserReport = (locale: Locale): Observable<UserReportResponse> =>
  ajax({
    url: `${apiURL}/auth/user-report`,
    method: HttpMethod.Get,
    responseType: 'blob',
    headers: {
      'Accept-Language': locale,
    },
    withCredentials: true,
  });
