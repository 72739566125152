import React, { ReactElement, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { userSessionSelectors } from '../../selectors';


const ServiceNps = (): ReactElement => {

  const user:number = useSelector(userSessionSelectors.getUser)?.sharp || 0;

  useEffect(() => {
    if (user > 0) {
      // window.test(user.toString());
    }
  
  }, []);

  return (
    <span style={{
      display: "none"
    }}>Execution nps</span>
  );
};
export default ServiceNps;
