import React, { ReactElement, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes } from 'react-router';
import { userSessionActions } from '../../actions';
import Footer from '../../components/Footer';
import Help from '../../components/Help';
import {
  EarlyEnding,
  FeedbackSent,
  Instructions,
  PreFeedback,
} from '../../components/Interview';
import LoadingPage from '../../components/LoadingSpinner/LoadingPage';
import ServiceNps from '../../components/ServiceNps';
import { Breakpoint } from '../../enums';
import { useInactivityTimer, useWindowWidth } from '../../hooks';
import { userSessionSelectors } from '../../selectors';
import Admin from '../Admin';
import Board from '../Board';
import CompletedInterview from '../CompletedInterview';
import FeedBack from '../Feedback';
import History from '../History';
import HistoryContainer from '../History/HistoryContainer';
import Home from '../Home';
import InterviewStatus from '../InterviewStatus';
import Menu from '../Menu';
import MobileFooter from '../MobileFooter';
import MobileMenu from '../MobileMenu';
import NewInterview from '../NewInterview';
import PasswordChange from '../PasswordChange';
import Pending from '../Pending';
import Questionary from '../Questionary';
import Reports from '../Reports';
import WordCloud from '../WordCloud';
import styles from './Dashboard.module.scss';

const Dashboard = (): ReactElement => {
  const hasUser = !!useSelector(userSessionSelectors.getUser);
  const fetching = useSelector(userSessionSelectors.getFetching);

  // Fetch user session on startup
  const dispatch = useDispatch();
  useEffect(() => {
    if (fetching) {
      dispatch(userSessionActions.fetchUserSession());
    }
  }, [dispatch, fetching]);

  useInactivityTimer();

  const windowWidth = useWindowWidth();
  const showMobile = windowWidth < Breakpoint.Medium;

  const contentRef = useRef(null);

  return fetching ? (
    // <div
    //   style={{
    //     width: '100vw',
    //     height: '100vh',
    //     display: 'flex',
    //     alignItems: 'center',
    //     justifyContent: 'center',
    //   }}
    // >
    //   <LoadingSpinner />
    // </div>
    <LoadingPage />
  ) : (
    <div className={styles.container}>
      {showMobile ? <MobileMenu /> : <Menu contentRef={contentRef} />}

      <div ref={contentRef} className={styles.content}>
        <Routes>
          <Route path="" element={<Home />} />

          <Route path="interview" element={<InterviewStatus />}>
            <Route path="new" element={<NewInterview />} />
            <Route path="early-ending" element={<EarlyEnding />} />

            <Route path="instructions" element={<Instructions />} />
            <Route
              path="questionary"
              element={<Questionary contentRef={contentRef} />}
            />

            <Route path="pre-feedback" element={<PreFeedback />} />

            <Route path="feedback" element={<FeedBack />} />

            <Route path="feedback-sent" element={<FeedbackSent />} />
          </Route>

          <Route path="history" element={<HistoryContainer />}>
            <Route path="" element={<History />} />
            <Route path="pending" element={<Pending />} />
            <Route path=":id" element={<CompletedInterview />} />
          </Route>

          <Route path="admin/*" element={<Admin />} />

          <Route path="reports" element={<Reports />} />

          <Route path="word-cloud" element={<WordCloud />} />

          <Route path="boards" element={<Board />} />

          <Route path="help" element={<Help />} />

          <Route path="profile">
            <Route path="password-change" element={<PasswordChange />} />
          </Route>
        </Routes>
      </div>

      {showMobile && hasUser ? <MobileFooter /> : <Footer />}
      <ServiceNps />
    </div>
  );
};

export default Dashboard;
