import { IconButton } from '@material-ui/core';
import {
  Lock as LockIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@material-ui/icons';
import { Form, Formik, FormikProps } from 'formik';
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { map } from 'rxjs/operators';
import * as yup from 'yup';
import { userSessionActions } from '../../actions';
import { userSessionAPI } from '../../api';
import { FormTextField } from '../../components/FormFields';
import LoadingSpinner from '../../components/LoadingSpinner';
import MainButton from '../../components/MainButton';
import toastConfig from '../../config/toast';
import { HTMLInputType } from '../../enums';
import { TranslationKey } from '../../i18n/translations';
import { userSessionSelectors } from '../../selectors';
import { logger } from '../../services';
import TranslateFormikErrors from '../TranslateFormikErrors';
import styles from './Session.module.scss';

interface PasswordRecoveryValues {
  password: string;
  repeatPassword: string;
}

const getValidationSchema = (intl: IntlShape, isAdmin = false) =>
  yup.object({
    password: yup
      .string()
      .required(
        intl.formatMessage(
          {
            id: TranslationKey.VALIDATE_REQUIRED,
          },
          {
            fieldName: intl.formatMessage({
              id: TranslationKey.PASSWORD,
            }),
          },
        ),
      )
      .matches(
        isAdmin
          ? /^(?=.*[A-Za-z])(?=.*\d)(?=.*[.@$!%*#?&-])[A-Za-z\d.@$!%*#?&-]{15,}$/
          : /^(?=.*[A-Za-z])(?=.*\d)(?=.*[.@$!%*#?&-])[A-Za-z\d.@$!%*#?&-]{8,}$/,
        intl.formatMessage(
          {
            id: TranslationKey.VALIDATE_PASSWORD_INSTRUCTIONS,
          },
          {
            length: isAdmin ? 15 : 8,
          },
        ),
      ),

    repeatPassword: yup
      .string()
      .when('password', {
        is: (password: string | null): boolean =>
          !!password && !!password.length,
        then: yup.string().oneOf(
          [yup.ref('password')],
          intl.formatMessage({
            id: TranslationKey.VALIDATE_PASSWORD_MATCH,
          }),
        ),
      })
      .required(
        intl.formatMessage(
          {
            id: TranslationKey.VALIDATE_REQUIRED,
          },
          {
            fieldName: intl.formatMessage({
              id: TranslationKey.REPEAT_NEW_PASSWORD,
            }),
          },
        ),
      ),
  });

const PasswordRecovery = (): ReactElement => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [isAdmin, setIsAdmin] = useState(false);

  const token = new URLSearchParams(location.search).get('token') || '';
  useEffect(() => {
    const subscription = userSessionAPI
      .verifyPasswordRecoveryToken(token)
      .pipe(
        map(
          ({ response }: { response: { isAdmin: boolean } }) =>
            response.isAdmin,
        ),
      )
      .subscribe(
        (verification) => {
          logger.info('User admin status', verification);
          setIsAdmin(verification);
        },
        (error) => {
          logger.error('Error verifying token', error);
          toast.error('Error sending token verification.', toastConfig);
          navigate('/session/login');
        },
      );
    return () => {
      subscription.unsubscribe();
    };
  }, [navigate, token]);

  const [showingPassword, setShowingPassword] = useState(false);
  const toggleShowPassword = useCallback(() => {
    setShowingPassword(!showingPassword);
  }, [showingPassword]);

  const sending = useSelector(userSessionSelectors.getSending);

  const handleSubmit = useCallback(
    (values: PasswordRecoveryValues) => {
      dispatch(
        userSessionActions.passwordRecoveryRequest(values.password, '', token),
      );
    },
    [dispatch, token],
  );

  const validationSchema = useMemo(() => getValidationSchema(intl, isAdmin), [
    intl,
    isAdmin,
  ]);

  return (
    <>
      <Formik
        initialValues={{
          password: '',
          repeatPassword: '',
        }}
        initialErrors={{
          password: '',
          repeatPassword: '',
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        autoComplete="off"
      >
        {({ isValid }: FormikProps<PasswordRecoveryValues>) => (
          <Form autoComplete="off">
            <TranslateFormikErrors />

            {/* Password */}
            <FormTextField
              name="password"
              label={intl.formatMessage({
                id: TranslationKey.NEW_PASSWORD,
              })}
              placeholder="*********"
              type={
                showingPassword ? HTMLInputType.Text : HTMLInputType.Password
              }
              startElement={<LockIcon color="inherit" />}
              endElement={
                <IconButton
                  onClick={toggleShowPassword}
                  className={styles.formInputButton}
                  tabIndex={-1}
                >
                  {showingPassword ? (
                    <VisibilityIcon color="inherit" />
                  ) : (
                    <VisibilityOffIcon color="inherit" />
                  )}
                </IconButton>
              }
              disabled={sending}
              required
            />

            {/* Repeat Password */}
            <FormTextField
              name="repeatPassword"
              label={intl.formatMessage({
                id: TranslationKey.REPEAT_NEW_PASSWORD,
              })}
              placeholder="*********"
              type={
                showingPassword ? HTMLInputType.Text : HTMLInputType.Password
              }
              startElement={<LockIcon color="inherit" />}
              endElement={
                <IconButton
                  onClick={toggleShowPassword}
                  className={styles.formInputButton}
                  tabIndex={-1}
                >
                  {showingPassword ? (
                    <VisibilityIcon color="inherit" />
                  ) : (
                    <VisibilityOffIcon color="inherit" />
                  )}
                </IconButton>
              }
              disabled={sending}
              required
            />

            <MainButton type="submit" disabled={!isValid || sending}>
              <FormattedMessage id={TranslationKey.RECOVER_PASSWORD} />
            </MainButton>
          </Form>
        )}
      </Formik>

      {sending && (
        <div>
          <LoadingSpinner size="1em" />
        </div>
      )}
    </>
  );
};

export default PasswordRecovery;
