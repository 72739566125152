import { Grid } from '@material-ui/core';
import React, { ReactElement, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import ContentTitle from '../../components/ContentTitle';
import { Iframe } from '../../components/Iframe';
import { TranslationKey } from '../../i18n/translations';
import styles from './Board.module.scss';
import { URL_DASHBOARD } from '../../config/environment';

const Board = (): ReactElement => {
  useEffect(() => {
    //
  }, []);

  return (
    <div className={styles.container}>
      <Grid container>
        <ContentTitle>
          <FormattedMessage id={TranslationKey.BOARD} />
        </ContentTitle>
        <Grid item xs={12} className={styles['container-iframe']}>
          <Iframe
            src={URL_DASHBOARD}
            title="Dashboard"
            width="100%"
            height="80vh"
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default Board;
