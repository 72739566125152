import { startCase } from 'lodash-es';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import { createEpicMiddleware, Epic } from 'redux-observable';
import { BehaviorSubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AppAction } from './actions/actionTypes';
import rootEpic from './epics';
import rootReducer from './reducers';

const composeEnhancers = composeWithDevTools({
  name: `Exit Interview ${startCase(process.env.NODE_ENV)}`,
  trace: false,
});

const epicMiddleware = createEpicMiddleware();

const middleware = [epicMiddleware];

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(...middleware)),
);

const epic$ = new BehaviorSubject(rootEpic);
// Every time a new epic is given to epic$ it
// will unsubscribe from the previous one then
// call and subscribe to the new one because of
// how switchMap works
const hotReloadingEpic = (...args: unknown[]) =>
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  epic$.pipe(switchMap((epic: Epic<AppAction, AppAction>) => epic(...args)));

epicMiddleware.run(hotReloadingEpic);

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./reducers', () => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-var-requires,global-require
    const newRootReducer = require('./reducers').default;
    store.replaceReducer(newRootReducer);
  });

  module.hot.accept('./epics', () => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-var-requires,global-require
    const nextRootEpic = require('./epics').default;
    epic$.next(nextRootEpic);
  });
}

export default store;
