import React, {
  FunctionComponent,
  ReactElement,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import { IntlShape, RawIntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import intlHelper from '../i18n/intlHelper';
import { userSessionSelectors } from '../selectors';

interface IntlWrapperProps {
  children: ReactNode;
}

const IntlWrapper: FunctionComponent<IntlWrapperProps> = ({
  children,
}): ReactElement => {
  const locale = useSelector(userSessionSelectors.getLocale);
  const [intl, setIntl] = useState<IntlShape>(intlHelper.setIntl(locale));

  useEffect(() => {
    setIntl(intlHelper.setIntl(locale));
  }, [locale]);

  return <RawIntlProvider value={intl}>{children}</RawIntlProvider>;
};

export default IntlWrapper;
