import { createSelector } from 'reselect';
import { AppModules, BusinessUnitCode, Locale, UserRole } from '../enums';
import { AppState, UserInSession, UserSessionState } from '../types';

const getModule = (state: AppState): UserSessionState =>
  state[AppModules.UserSession];

export const getFetching = createSelector(
  getModule,
  (state): boolean => state.fetchingSession,
);

export const getSending = createSelector(
  getModule,
  (state): boolean => state.sendingRequest,
);

export const getCredentialsError = createSelector(
  getModule,
  (state) => state.credentialsError,
);

export const getIsUserInSession = createSelector(
  getModule,
  (state): boolean => !!state.email,
);

export const getLocale = createSelector(
  getModule,
  (state): Locale => state.locale || navigator.language,
);

export const getUser = createSelector(
  getModule,
  (state): UserInSession | null => state.user,
);

export const getUserId = createSelector(getUser, (user) => user?.id);

export const getUserBU = createSelector(
  getUser,
  (user: UserInSession | null) => user?.businessUnit as BusinessUnitCode,
);

export const getCountry = createSelector(
  getUser,
  (user: UserInSession | null) => user?.country,
);

export const getRole = createSelector(
  getUser,
  (user: UserInSession | null) => user?.role as UserRole,
);
