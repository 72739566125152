import React, { ReactElement, FunctionComponent} from 'react';
import DOMpurify from 'dompurify';
import reactParser from 'html-react-parser';
import intlHelper from '../../i18n/intlHelper';

interface ComponentProps {
    id: string
}
const BodyInnerHTML :FunctionComponent<ComponentProps> = React.memo(
    ({
        id
    }): ReactElement => {

        const content = intlHelper.getIntl()?.formatMessage({id});

        const cleanHtmlString: string = DOMpurify.sanitize(content ?? '', {
            USE_PROFILES: { html: true },
        });
        
        const reactElement = reactParser(cleanHtmlString);
    
        return <>{reactElement}</>;
    }
);

export default BodyInnerHTML;
