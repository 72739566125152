import { Container, Grid } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';
import preFeedbackImage from '../../../assets/images/feedback.png';
import { TranslationKey } from '../../../i18n/translations';
import ABIButton from '../../ABIButton';
import ContentTitle from '../../ContentTitle';
import MainButton from '../../MainButton';
import styles from './PreFeedback.module.scss';

const PreFeedback = (): ReactElement => {
  const navigate = useNavigate();

  const onReturnClick = () => {
    navigate('/');
  };
  const onClick = () => {
    navigate('/interview/feedback');
  };

  return (
    <div className={styles.container}>
      <Container>
        <Grid container>
          <Grid item xs={12}>
            <ContentTitle className={styles.title}>
              <FormattedMessage id={TranslationKey.FEEDBACK_TITLE} />
            </ContentTitle>
          </Grid>

          <Grid item sm={12} md={6} className={styles.text}>
            <p>
              <FormattedMessage id={TranslationKey.PRE_FEEDBACK_TEXT_1} />
            </p>

            <p>
              <FormattedMessage id={TranslationKey.PRE_FEEDBACK_TEXT_2} />
            </p>
          </Grid>

          <Grid item sm={12} md={6} className={styles.imageContainer}>
            <img
              src={preFeedbackImage}
              alt="People having conversation"
              className={styles.image}
            />
          </Grid>
        </Grid>

        <Grid
          className={styles.bottomContainer}
          container
          direction="column"
          justify="center"
          alignItems="center"
        >
          <Grid item>
            <p className={styles.whatNext}>
              <FormattedMessage id={TranslationKey.PRE_FEEDBACK_QUESTION} />
            </p>
          </Grid>

          <Grid item xs={12}>
            <div className={styles.buttonContainer}>
              <ABIButton buttonType="cancel" onClick={onReturnClick}>
                <FormattedMessage id={TranslationKey.EXIT} />
              </ABIButton>
              <ABIButton {...{ onClick }}>
                <FormattedMessage id={TranslationKey.CONTINUE} />
              </ABIButton>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default PreFeedback;
