import { omit as _omit } from 'lodash-es';
import { toast } from 'react-toastify';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { of } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { catchError, delay, map, mergeMap, tap } from 'rxjs/operators';
import { reportsActions } from '../actions';
import {
  AppAction,
  DownloadReportDataAction,
  DOWNLOAD_REPORT_DATA,
  FetchReportDataAction,
  FETCH_REPORT_DATA,
  SetReportDataAction,
  SetReportsDownloadingAction,
} from '../actions/actionTypes';
import { reportsAPI } from '../api';
import toastConfig from '../config/toast';
import {
  downloadFile,
  reportDataMapper,
  serializeQuery,
} from '../helpers/util-functions';
import intlHelper from '../i18n/intlHelper';
import { TranslationKey } from '../i18n/translations';
import { userSessionSelectors } from '../selectors';
import { logger } from '../services';
import { AppState, RawReportData } from '../types';

export const fetchReportDataEpic: Epic<
  AppAction,
  FetchReportDataAction | SetReportDataAction,
  AppState
> = (action$, state$) =>
  action$.pipe(
    ofType<AppAction, FetchReportDataAction>(FETCH_REPORT_DATA),
    map((action) => ({
      ...action.filters,
      locale: userSessionSelectors.getLocale(state$.value),
    })),
    tap((filters) => {
      logger.info('Fetching report data with: ', `?${serializeQuery(filters)}`);
    }),
    mergeMap((filters) =>
      reportsAPI.fetchReportData(filters).pipe(
        map(({ response }: AjaxResponse) =>
          reportDataMapper(response as RawReportData),
        ),
        map((reportData) => {
          logger.info('Fetched report data.', reportData);
          return reportsActions.setReportData(reportData);
        }),
        catchError((error) => {
          logger.error('Fetched report data.', error);
          return of(reportsActions.setReportData(undefined));
        }),
      ),
    ),
  );

export const downloadReportDataEpic: Epic<
  AppAction,
  DownloadReportDataAction | SetReportsDownloadingAction,
  AppState
> = (action$, state$) =>
  action$.pipe(
    ofType<AppAction, DownloadReportDataAction>(DOWNLOAD_REPORT_DATA),
    map((action) => ({
      ...action.filters,
      reportType: action.reportType,
      locale: userSessionSelectors.getLocale(state$.value),
    })),
    tap((filters) => {
      logger.info(
        `Downloading report data for with`,
        `?${serializeQuery(filters)}`,
      );
    }),
    delay(3000),
    mergeMap((filters) =>
      reportsAPI
        .downloadReportData(
          _omit(filters, 'reportType'),
          filters.locale,
          filters.reportType,
        )
        .pipe(
          map(({ response }) => {
            downloadFile('report.xlsx', response);
            logger.info('Downloaded report.', response);
            return reportsActions.serReportDownloading(false);
          }),
          catchError((error) => {
            logger.error('Error downloading report', error);
            const intl = intlHelper.getIntl();
            toast.error(
              intl?.formatMessage({
                id: TranslationKey.ERROR_DOWNLOADING_REPORT,
              }),
              toastConfig,
            );
            return of(reportsActions.serReportDownloading(false));
          }),
        ),
    ),
  );

export default combineEpics(fetchReportDataEpic, downloadReportDataEpic);
