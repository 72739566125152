import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
} from '@material-ui/core';
import { useField } from 'formik';
import React, {
  ChangeEvent,
  FunctionComponent,
  useCallback,
  useMemo,
} from 'react';
import { SelectOption } from '../../types';

import styles from './FormFields.module.scss';

interface FormMultipleCheckboxField {
  name: string;
  id?: string;
  options: SelectOption[];
  disabled?: boolean;
  required?: boolean;
  onValueChange?: (value?: string, name?: string) => void;
}

const FormMultipleCheckboxField: FunctionComponent<FormMultipleCheckboxField> =
  ({
    name,
    id,
    options,
    disabled = false,
    required = false,
    onValueChange,
  }) => {
    const [field, meta, { setValue }] = useField<string>(name);

    const currentValue = useMemo(
      () => field.value?.split(',').filter((val) => !!val) || [],
      [field.value],
    );

    const handleChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        event.stopPropagation();
        if (disabled) return;
        const rawNewValue = event.target.checked
          ? [...currentValue, event.target.name]
          : currentValue.filter((val) => val !== event.target.name);
        const newValue = rawNewValue.join(',');
        if (onValueChange) onValueChange(newValue);
        setValue(newValue);
      },
      [currentValue, disabled, onValueChange, setValue],
    );

    const hasError = meta.touched && !!meta.error;

    return (
      <FormControl
        required
        error={hasError}
        component="fieldset"
        style={{
          marginTop: 0,
        }}
      >
        <FormGroup>
          {options.map((option, index) => (
            <FormControlLabel
              key={option.value}
              control={
                <Checkbox
                  id={index === 0 ? id : undefined}
                  name={option.value as string}
                  checked={!!currentValue.includes(option.value as string)}
                  onChange={handleChange}
                  required={required}
                  color="primary"
                />
              }
              label={<span className={styles.inputLabel}>{option.label}</span>}
            />
          ))}
        </FormGroup>
        <FormHelperText error={hasError}>
          {hasError ? meta.error : ' '}
        </FormHelperText>
      </FormControl>
    );
  };

export default FormMultipleCheckboxField;
