import { Locale } from '../enums';
import { UserInSession } from '../types';
import * as types from './actionTypes';

export const fetchUserSession = (): types.FetchUserSessionAction => ({
  type: types.FETCH_USER_SESSION,
});

export const loginUserRequest = (
  code: string,
  state: string,
): types.LoginUserRequestAction => ({
  type: types.LOGIN_USER_REQUEST,
  code,
  state,
});

export const setCredentialsError = (
  credentialsError: boolean,
): types.SetCredentialsErrorAction => ({
  type: types.SET_CREDENTIALS_ERROR,
  credentialsError,
});

export const logoutUserRequest = (): types.LogoutUSerRequestAction => ({
  type: types.LOGOUT_USER_REQUEST,
});

export const forgotPasswordRequest = (
  email: string,
): types.ForgotPasswordRequestAction => ({
  type: types.FORGOT_PASSWORD_REQUEST,
  email,
});

export const passwordRecoveryRequest = (
  newPassword: string,
  currentPassword?: string,
  token?: string,
): types.PasswordRecoveryRequestAction => ({
  type: types.PASSWORD_RECOVERY_REQUEST,
  newPassword,
  currentPassword,
  token,
});

export const setUserSession = (
  user: UserInSession,
): types.SetUserSessionAction => ({
  type: types.SET_USER_SESSION,
  user,
});

export const clearUserSession = (): types.ClearUserSessionAction => ({
  type: types.CLEAR_USER_SESSION,
});

export const setLocale = (locale: Locale): types.SetLocaleAction => ({
  type: types.SET_LOCALE,
  locale,
});
