import { IntlShape } from 'react-intl';
import * as yup from 'yup';
import { BooleanSelection, FeedbackFieldPrefix } from '../../enums';
import { TranslationKey } from '../../i18n/translations';

const getValidationSchema = (intl: IntlShape): yup.SchemaOf<unknown> => {
  const requiredGeneric = intl.formatMessage({
    id: TranslationKey.VALIDATE_REQUIRED_GENERIC,
  });

  return yup.object().shape({
    [FeedbackFieldPrefix.GeneralFeedback]: yup.object().shape({
      comments: yup.string().nullable().required(requiredGeneric),
    }),
    [FeedbackFieldPrefix.ActionPlan]: yup.object().shape({
      answer: yup
        .mixed()
        .oneOf(Object.values(BooleanSelection))
        .nullable()
        .required(requiredGeneric),
      comments: yup.string().nullable().required(requiredGeneric),
    }),
  });
};

export default getValidationSchema;
