import React from 'react';

interface IframeProps {
  title: string;
  src: string;
  width: string;
  height: string;
}

export const Iframe = (props: IframeProps) => {
  const { src, width, height, title } = props;

  return <iframe src={src} title={title} style={{ width, height }} />;
};
