import { startCase } from 'lodash-es';
import React, { ReactElement, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userSessionActions } from '../../actions';
import { Locale } from '../../enums';
import { userSessionSelectors } from '../../selectors';
import styles from './LanguageSwitcher.module.scss';

const LanguageSwitcher = (): ReactElement => {
  const locale = useSelector(userSessionSelectors.getLocale);
  const dispatch = useDispatch();
  const handleClick = useCallback(() => {
    const newLocale =
      locale === Locale.Spanish ? Locale.English : Locale.Spanish;
    dispatch(userSessionActions.setLocale(newLocale));
  }, [dispatch, locale]);
  return (
    <button type="button" className={styles.buttonLink} onClick={handleClick}>
      <span className={locale === Locale.Spanish ? styles.buttonActive : ''}>
        {startCase(Locale.Spanish)}
      </span>{' '}
      /{' '}
      <span className={locale === Locale.English ? styles.buttonActive : ''}>
        {startCase(Locale.English)}
      </span>
    </button>
  );
};

export default LanguageSwitcher;
