/* eslint-disable consistent-return */
/* eslint-disable default-case */
/* eslint-disable no-param-reassign */

import {
  AppAction,
  CLEAR_HISTORY_STATE,
  CLEAR_USER_SESSION,
  DOWNLOAD_INTERVIEWS_REQUEST,
  INITIALIZE_HISTORY,
  SET_ALL_INTERVIEWS_SELECTION,
  SET_DOWNLOADING_INTERVIEWS,
  SET_FETCHING_HISTORY,
  SET_HISTORY_ADDITIONAL_FILTERS,
  SET_HISTORY_EXPANDED,
  SET_HISTORY_FILTERS,
  SET_HISTORY_INTERVIEWS,
  SET_HISTORY_PAGE,
  SET_HISTORY_SORT_COLUMN,
  SET_HISTORY_SORT_DIRECTION,
  SET_ONLY_PENDING,
  TOGGLE_INTERVIEW_SELECTED,
} from '../actions/actionTypes';
import { SortDirection } from '../enums';
import {
  immerProduce,
  isInterviewDownloadable,
} from '../helpers/util-functions';
import { HistoryState } from '../types';

const initState: HistoryState = {
  initialized: false,
  fetching: false,
  downloading: false,
  expanded: false,
  onlyPending: false,
  // Filters
  page: 0,
  sortColumn: 'createdAt',
  sortDirection: SortDirection.Descending,
  filters: {
    nameOrId: '',
    from: null,
    until: null,
  },
  additionalFilters: {},
  // Values
  interviews: [],
  count: 0,
};

const historyReducer = (state = initState, action: AppAction): HistoryState =>
  immerProduce(state, (draft) => {
    switch (action.type) {
      case INITIALIZE_HISTORY:
        draft.initialized = true;
        return;

      case SET_HISTORY_PAGE:
        draft.page = action.page;
        draft.fetching = true;
        draft.interviews = [];
        return;

      case SET_HISTORY_SORT_COLUMN:
        draft.sortColumn = action.sortColumn;
        draft.fetching = true;
        draft.interviews = [];
        return;

      case SET_HISTORY_SORT_DIRECTION:
        draft.sortDirection = action.sortDirection;
        draft.fetching = true;
        draft.interviews = [];
        return;

      case SET_HISTORY_FILTERS:
        draft.filters = action.filters;
        draft.fetching = true;
        draft.interviews = [];
        return;

      case SET_HISTORY_ADDITIONAL_FILTERS:
        draft.additionalFilters = action.additionalFilters;
        draft.fetching = true;
        draft.interviews = [];
        return;

      case SET_ONLY_PENDING:
        draft.onlyPending = action.onlyPending;
        return;

      case SET_HISTORY_EXPANDED:
        draft.expanded = action.expanded;
        return;

      case SET_FETCHING_HISTORY:
        draft.fetching = action.fetching;
        return;

      case SET_HISTORY_INTERVIEWS:
        draft.initialized = true;
        draft.interviews = action.interviews.slice(0, 10).map((interview) => ({
          selected: false,
          ...interview,
        }));
        draft.count = action.count;
        draft.fetching = false;
        return;

      case TOGGLE_INTERVIEW_SELECTED:
        draft.interviews.forEach((interview) => {
          if (interview.id !== action.id) return;
          interview.selected = !interview.selected;
        });
        return;

      case SET_ALL_INTERVIEWS_SELECTION:
        draft.interviews.forEach((interview) => {
          if (!isInterviewDownloadable(interview.status)) return;
          interview.selected = action.selected;
        });
        return;

      case DOWNLOAD_INTERVIEWS_REQUEST:
        draft.downloading = true;
        return;

      case SET_DOWNLOADING_INTERVIEWS:
        draft.downloading = action.downloading;
        return;

      case CLEAR_USER_SESSION:
      case CLEAR_HISTORY_STATE:
        return initState;
    }
  });

export default historyReducer;
