import { GetApp as GetAppIcon } from '@material-ui/icons';
import html2canvas from 'html2canvas';
import React, { ReactElement, useCallback, useMemo, useRef } from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import ReactWordcloud from 'react-wordcloud';
import LoadingSpinner from '../../components/LoadingSpinner';
import LoadingPage from '../../components/LoadingSpinner/LoadingPage';
import MainButton from '../../components/MainButton';
import { Breakpoint, EExitType, QuestionaryType } from '../../enums';
import {
  downloadWithFileURL,
  setInlineStyles,
} from '../../helpers/util-functions';
import { useFetchQuestions, useWindowWidth } from '../../hooks';
import { TranslationKey } from '../../i18n/translations';
import { wordCloudSelectors } from '../../selectors';
import styles from './WordCloud.module.scss';

const colors = [
  '#A01818',
  '#D64333',
  '#F7553A',
  '#E87D76',
  '#F27A1E',
  '#FABA30',
  '#3C3C3C',
  '#6F6F6F',
  '#001DD6',
  '#3E40EE',
];

const WordCloudChart = (): ReactElement | null => {
  const windowWidth = useWindowWidth();

  const smallViewport = windowWidth > Breakpoint.Small;
  const chartWidth = smallViewport ? 700 : 350;
  const chartHeight = smallViewport ? 300 : 150;

  const wordCloudRef = useRef<HTMLDivElement>(null);

  const handleDownloadClick = useCallback(async () => {
    if (!wordCloudRef.current) return;
    // Element must be visible in order to render correctly
    wordCloudRef.current.scrollIntoView({
      block: 'center',
    });

    // Styles must be inline for `html2canvas`
    setInlineStyles(wordCloudRef.current);

    // Generate canvas
    const area = wordCloudRef.current.getBoundingClientRect();
    const canvas = await html2canvas(wordCloudRef.current, {
      scrollX: 0,
      scrollY: 0,
      width: area.width,
      height: area.height,
    });
    downloadWithFileURL('word-cloud.png', canvas.toDataURL());
  }, []);

  const intl = useIntl();
  const fetching = useSelector(wordCloudSelectors.getFetching);
  const data = useSelector(wordCloudSelectors.getData);
  const count = useSelector(wordCloudSelectors.getCount);

  const { from, until, fieldPrefix, exitType } =
    useSelector(wordCloudSelectors.getWordCloudConfig) || {};
  const [fetchingQuestions, questions] = useFetchQuestions(
    QuestionaryType.Interview,
    intl,
    exitType === EExitType.Voluntary,
  );
  const translatedQuestion = useMemo(
    () => questions.find((q) => q.fieldPrefix === fieldPrefix)?.question || '',
    [fieldPrefix, questions],
  );

  if (!data.length && !fetching) return null;
  return fetching ? (
    <LoadingSpinner />
  ) : (
    <>
      <div ref={wordCloudRef} className={styles.chartContainer}>
        <h2 className={styles.title}>
          <FormattedMessage id={TranslationKey.WORD_CLOUD} />
        </h2>

        <h6 className={styles.dates}>
          <FormattedMessage id={TranslationKey.FROM} />{' '}
          <FormattedDate value={from} />{' '}
          {intl
            .formatMessage({
              id: TranslationKey.UNTIL,
            })
            .toLowerCase()}{' '}
          <FormattedDate value={until} />
        </h6>

        <h6 className={styles.question}>
          {fetchingQuestions ? (
            <LoadingSpinner size="3em" thickness={3} />
          ) : (
            translatedQuestion
          )}
        </h6>

        <div
          className={styles.wordCloudChart}
          style={{
            width: chartWidth,
            height: chartHeight,
          }}
        >
          <ReactWordcloud
            words={data}
            options={{
              colors,
              enableTooltip: false,
              deterministic: false,
              fontFamily: 'impact',
              fontSizes: [5, 60],
              fontStyle: 'normal',
              fontWeight: 'bold',
              padding: 1,
              rotations: 2,
              rotationAngles: [0, 90],
              scale: 'sqrt',
              spiral: 'archimedean',
            }}
          />
        </div>

        <h6 className={styles.question}>
          {!!count && (
            <FormattedMessage
              id={TranslationKey.WORD_CLOUD_INTERVIEW_COUNT}
              values={{
                count,
              }}
            />
          )}
        </h6>
      </div>

      <MainButton
        type="button"
        appButtonType="download"
        appColorType="primary"
        onClick={handleDownloadClick}
      >
        <GetAppIcon
          style={{
            fontSize: 16,
          }}
        />
        <FormattedMessage id={TranslationKey.DOWNLOAD_IMAGE} />
      </MainButton>
    </>
  );
};

export default WordCloudChart;
