import { CircularProgress, Container, Grid, Icon } from '@material-ui/core';
import { Form, Formik } from 'formik';
import React, {
  FunctionComponent,
  ReactElement,
  RefObject,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { interviewActions } from '../../actions';
import ABIButton from '../../components/ABIButton';
import BodyInnerHTML from '../../components/BodyInnerHTML';
import ConfirmModal from '../../components/ConfirmModal';
import ContentTitle from '../../components/ContentTitle';
import { FormRadioField, FormTextField } from '../../components/FormFields';
import LoadingSpinner from '../../components/LoadingSpinner';
import Question from '../../components/Question';
import {
  BooleanSelection,
  QuestionaryFieldPrefix,
  QuestionaryType,
  QuestionType,
} from '../../enums';
import { useFetchQuestions } from '../../hooks';
import { TranslationKey } from '../../i18n/translations';
import { commonSelectors, interviewSelectors } from '../../selectors';
import { QuestionaryValues } from '../../types';
import TranslateFormikErrors from '../TranslateFormikErrors';
import FetchStoredQuestionary from './FetchStoredQuestionary';
import FocusErrors from './FocusErrors';
import styles from './Questionary.module.scss';
import SavePartialQuestionary from './SavePartialQuestionary';
import getValidationSchema from './schema';

const initialValues = Object.values(QuestionaryFieldPrefix).reduce(
  (values, fieldPrefix) => ({
    ...values,
    [fieldPrefix]: {
      answer: '',
      comments: '',
    },
  }),
  {} as QuestionaryValues,
);

interface QuestionaryProps {
  contentRef: RefObject<HTMLDivElement>;
}

const Questionary: FunctionComponent<QuestionaryProps> = ({
  contentRef,
}): ReactElement => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const canEditInterview = useSelector(commonSelectors.canEditInterview);

  const valuesRef = useRef<QuestionaryValues>();
  const [openedModal, setOpenedModal] = useState(false);

  const handleNoClick = useCallback(() => {
    setOpenedModal(false);
  }, []);

  const handleYesClick = useCallback(() => {
    if (!valuesRef.current) return;
    setOpenedModal(false);
    dispatch(interviewActions.saveQuestionaryRequest(valuesRef.current));
  }, [dispatch]);

  const validationSchema = useMemo(() => getValidationSchema(intl), [intl]);

  const handleSubmit = useCallback((values: QuestionaryValues) => {
    valuesRef.current = values;
    setOpenedModal(true);
  }, []);

  const [fetchingQuestions, setFetchingQuestions] = useState(true);
  const sending = useSelector(interviewSelectors.getSending);

  const [fetchingQuestionary, questions] = useFetchQuestions(
    QuestionaryType.Interview,
    intl,
  );
  const filteredQuestions = useMemo(
    () =>
      questions.filter(
        (question) =>
          question.fieldPrefix !== QuestionaryFieldPrefix.MainExitMotive,
      ),
    [questions],
  );

  const criticalStatusQuestion = useMemo(
    () =>
      filteredQuestions.find(
        ({ fieldPrefix }) => fieldPrefix === QuestionaryFieldPrefix.Critical,
      ),
    [filteredQuestions],
  );

  const booleanOptions = useMemo(
    () =>
      Object.values(BooleanSelection).map((value) => ({
        value,
        label: intl.formatMessage({
          id: value,
        }),
      })),
    [intl],
  );

  return (
    <div ref={contentRef} className={styles.container}>
      <ConfirmModal isOpen={openedModal}>
        <h2 className={styles.modalTitle}>
          <FormattedMessage id={TranslationKey.ARE_YOU_SURE} />
        </h2>
        <p className={styles.modalText}>
          <FormattedMessage id={TranslationKey.QUESTIONARY_CONFIRMATION} />
        </p>
        <div className={styles.modalContainer}>
          <ABIButton buttonType="cancel" onClick={handleNoClick}>
            <FormattedMessage id={TranslationKey.NO} />
          </ABIButton>
          <ABIButton onClick={handleYesClick}>
            <FormattedMessage id={TranslationKey.YES} />
          </ABIButton>
        </div>
      </ConfirmModal>
      <Container>
        <Formik
          initialValues={initialValues}
          initialErrors={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          autoComplete="off"
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({ values }) => (
            <Form noValidate autoComplete="off">
              <TranslateFormikErrors />
              <FocusErrors questions={filteredQuestions} />

              <FetchStoredQuestionary setFetching={setFetchingQuestions} />
              {!fetchingQuestions && <SavePartialQuestionary />}

              <Grid container justify="center" alignItems="center" spacing={2}>
                <div className={styles.formContainer}>
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={1}
                  >
                    <ContentTitle className={styles.title}>
                      <FormattedMessage id={TranslationKey.QUESTIONARY_TITLE} />
                    </ContentTitle>
                  </Grid>
                  <Grid item xs={12}>
                    <p className={styles.text}>
                      <FormattedMessage
                        id={TranslationKey.QUESTIONARY_EXPLANATION}
                      />
                    </p>
                  </Grid>

                  {fetchingQuestionary ? (
                    <Grid
                      container
                      alignItems="center"
                      justify="center"
                      spacing={0}
                      className={styles.spinnerContainer}
                    >
                      <LoadingSpinner />
                    </Grid>
                  ) : (
                    <>
                      {filteredQuestions
                        .filter(({ type }) => type !== QuestionType.Additional)
                        .map((question, index) => (
                          <Question
                            key={question.fieldPrefix}
                            {...question}
                            number={index + 1}
                            disabled={!canEditInterview}
                          />
                        ))}
                    </>
                  )}
                  {!fetchingQuestionary && (
                    <>
                      <Grid container item xs={12} className={styles.container}>
                        <Grid item xs={12} className={styles.question}>
                          <p>
                            <FormattedMessage
                              id={TranslationKey.ADDITIONAL_INFO_INSTRUCTIONS1}
                            />
                            <small>
                              <em>
                                <FormattedMessage
                                  id={
                                    TranslationKey.ADDITIONAL_INFO_INSTRUCTIONS2
                                  }
                                />
                              </em>
                            </small>
                          </p>
                        </Grid>
                      </Grid>
                      <Grid container item className={styles.container}>
                        <Grid item xs={12} sm={6} md={4}>
                          <FormTextField
                            id={`${QuestionaryFieldPrefix.Email}-answer`}
                            name={`${QuestionaryFieldPrefix.Email}.answer`}
                            type="email"
                            label={intl.formatMessage({
                              id: TranslationKey.PERSONAL_EMAIL,
                            })}
                            placeholder="Email"
                            disabled={!canEditInterview}
                          />
                        </Grid>
                      </Grid>
                      <Grid container item className={styles.container}>
                        <Grid item xs={12} sm={6} md={4}>
                          <FormTextField
                            id={`${QuestionaryFieldPrefix.PhoneNumber}-answer`}
                            name={`${QuestionaryFieldPrefix.PhoneNumber}.answer`}
                            label={intl.formatMessage({
                              id: TranslationKey.PERSONAL_PHONE,
                            })}
                            placeholder={intl.formatMessage({
                              id: TranslationKey.PHONE,
                            })}
                            disabled={!canEditInterview}
                          />
                        </Grid>
                      </Grid>
                    </>
                  )}
                </div>

                <div
                  className={`${styles.formContainer} ${styles.backgroundGray}`}
                >
                  {!fetchingQuestionary && (
                    <>
                      <Grid
                        container
                        item
                        className={`${styles.container} ${styles.bpExplanation}`}
                      >
                        <Grid item>
                          <h3>Business Partner:</h3>
                          <p>{criticalStatusQuestion?.explanation || ''}</p>
                          <p>
                            {criticalStatusQuestion?.question || ''}{' '}
                            <span className={styles.required}>*</span>
                          </p>
                        </Grid>
                        <Grid container className={styles.conteinerCritical}>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            className={styles.alertCritical}
                          >
                            <div>
                              <Icon fontSize="inherit">report_problem</Icon>
                            </div>
                            <div>
                              <BodyInnerHTML
                                id={TranslationKey.MESSAGE_CRITICAL}
                              />
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormRadioField
                              id={`${QuestionaryFieldPrefix.Critical}-answer`}
                              name={`${QuestionaryFieldPrefix.Critical}.answer`}
                              options={booleanOptions}
                              disabled={!canEditInterview}
                              required
                            />
                          </Grid>
                        </Grid>

                        {values[QuestionaryFieldPrefix.Critical].answer ===
                          BooleanSelection.Yes && (
                          <Grid item xs={12} sm={8}>
                            <FormTextField
                              id={`${QuestionaryFieldPrefix.Critical}-comments`}
                              name={`${QuestionaryFieldPrefix.Critical}.comments`}
                              label={`${intl.formatMessage({
                                id: TranslationKey.COMMENTS,
                              })}:`}
                              type="textarea"
                              multiline
                              rows={4}
                              disabled={!canEditInterview}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </>
                  )}
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={styles.buttonContainer}>
                  <ABIButton
                    type="submit"
                    disabled={
                      fetchingQuestionary || fetchingQuestions || sending
                    }
                  >
                    <FormattedMessage id={TranslationKey.FINISH} />
                  </ABIButton>
                </div>
              </Grid>
              {sending && (
                <Grid
                  container
                  item
                  xs={12}
                  justify="center"
                  alignItems="center"
                  className={styles.buttonContainer}
                >
                  <CircularProgress color="primary" thickness={6} size="5em" />
                </Grid>
              )}
            </Form>
          )}
        </Formik>
      </Container>
    </div>
  );
};

export default Questionary;
