import { combineEpics } from 'redux-observable';
import admin from './admin';
import history from './history';
import interview from './interview';
import reports from './reports';
import userSession from './userSession';
import wordCloud from './wordCloud';

export default combineEpics(
  admin,
  history,
  userSession,
  interview,
  reports,
  wordCloud,
);
