/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { shouldPolyfill } from '@formatjs/intl-displaynames/should-polyfill';

export default async function polyfill(): Promise<void> {
  if (shouldPolyfill()) {
    // Load the polyfill 1st BEFORE loading data
    await import('@formatjs/intl-displaynames/polyfill');
  }

  // @ts-ignore
  if (Intl.DisplayNames.polyfilled) {
    // @ts-ignore
    await import('@formatjs/intl-displaynames/locale-data/en');
    // @ts-ignore
    await import('@formatjs/intl-displaynames/locale-data/en');
  }
}
