import React, {
  FunctionComponent,
  HTMLProps,
  ReactElement,
  ReactNode,
} from 'react';
import styles from './ContentTitle.module.scss';

interface ContentTitleProps extends HTMLProps<HTMLHeadingElement> {
  children: ReactNode;
}

const ContentTitle: FunctionComponent<ContentTitleProps> = ({
  children,
  className,
  ...props
}): ReactElement => (
  <h2 className={`${styles.title} ${className || ''}`} {...props}>
    {children}
  </h2>
);

export default ContentTitle;
