import { useFormikContext } from 'formik';
import { FunctionComponent, useCallback, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { map } from 'rxjs/operators';
import { interviewAPI } from '../../api';
import toastConfig from '../../config/toast';
import { QuestionaryType } from '../../enums';
import { answersMapper } from '../../helpers/util-functions';
import { TranslationKey } from '../../i18n/translations';
import { interviewSelectors } from '../../selectors';
import { logger } from '../../services';
import { IAnswer } from '../../types';

interface FetchStoredFeedbackProps {
  setFetching: (fetching: boolean) => void;
}

const FetchStoredFeedback: FunctionComponent<FetchStoredFeedbackProps> = ({
  setFetching,
}): null => {
  const interviewId = useSelector(interviewSelectors.getId);

  const { setFieldValue } = useFormikContext();
  const updateFormValues = useCallback(
    (storedFeedback) => {
      Object.entries(storedFeedback).forEach(([field, value]) => {
        setFieldValue(field, value, true);
      });
    },
    [setFieldValue],
  );

  const intl = useIntl();
  // Stores the translated error in a ref
  const formattedError = useRef(
    intl.formatMessage({
      id: TranslationKey.ERROR_FETCHING_STORED_FEEDBACK,
    }),
  );
  useEffect(() => {
    formattedError.current = intl.formatMessage({
      id: TranslationKey.ERROR_FETCHING_STORED_FEEDBACK,
    });
  }, [intl]);

  useEffect(() => {
    if (!interviewId) {
      setFetching(false);
      return undefined;
    }

    const subscription$ = interviewAPI
      .fetchStoredQuestions(interviewId, QuestionaryType.Feedback)
      .pipe(
        map(({ response }: { response: { questionary: IAnswer[] } }) =>
          answersMapper(response.questionary),
        ),
      )
      .subscribe(
        (storedAnswers) => {
          logger.info(
            `Fetched stored feedback for interviewId: ${interviewId}.`,
            storedAnswers,
          );
          updateFormValues(storedAnswers);
          setFetching(false);
        },
        (err) => {
          logger.error(
            `Error fetching stored feedback for interviewId: ${interviewId}.`,
            err,
          );
          toast.error(formattedError.current, toastConfig);
          setFetching(false);
        },
      );
    return () => {
      subscription$.unsubscribe();
    };
  }, [interviewId, setFetching, updateFormValues]);

  return null;
};

export default FetchStoredFeedback;
