import React, {
  FunctionComponent,
  HTMLProps,
  ReactElement,
  ReactNode,
} from 'react';
import { ArrowBack as ReturnIcon } from '@material-ui/icons';

import styles from './ABIButton.module.scss';

type HTMLButtonType = 'button' | 'submit' | 'reset';

interface ABIButtonProps extends HTMLProps<HTMLButtonElement> {
  type?: HTMLButtonType;
  buttonType?: 'cancel' | 'return';
  children: ReactNode;
}

const ABIButton: FunctionComponent<ABIButtonProps> = ({
  children,
  className = '',
  buttonType = '',
  ...props
}): ReactElement => (
  <button
    type="button"
    {...props}
    className={`${styles.abiButton} ${
      buttonType ? styles[buttonType] : ''
    } ${className}`}
  >
    {buttonType === 'return' && <ReturnIcon />}
    {children}
  </button>
);

export default ABIButton;
