import { Grid, LinearProgress, Typography } from '@material-ui/core';
import bytes from 'bytes';
import { DropzoneArea } from 'material-ui-dropzone';
import React, {
  FunctionComponent,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { Subscriber } from 'rxjs';
import { adminAPI } from '../../api';
import ABIButton from '../../components/ABIButton';
import MainButton from '../../components/MainButton';
import toastConfig from '../../config/toast';
import { TranslationKey } from '../../i18n/translations';
import { logger } from '../../services';
import styles from './Admin.module.scss';

// 20Mb
const MAX_FILE_SIZE = 2.1e7;

interface ReportDropzoneProps {
  title: string | ReactNode;
  isHeadCount?: boolean;
}

const ReportDropzone: FunctionComponent<ReportDropzoneProps> = ({
  title,
  isHeadCount = false,
}) => {
  const intl = useIntl();
  const [uploading, setUploading] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [progress, setProgress] = useState(0);
  const [dropzoneKey, setDropzoneKey] = useState(0);

  const handleChange = useCallback((newFiles: File[]) => {
    setFile(newFiles?.[0]);
  }, []);

  const handleUploadClick = useCallback(() => {
    setUploading(true);
  }, []);

  useEffect(() => {
    if (!uploading || !file) return undefined;

    const progressSubscriber$ = new Subscriber<ProgressEvent>(
      (event: ProgressEvent) => {
        const newProgress = (event.loaded / event.total) * 100;
        setProgress(newProgress);
      },
    );

    const formData = new FormData();
    formData.append('report', file, 'report.xlsb');
    logger.info('Uploading report file', file);

    const request$ = adminAPI
      .uploadReport(formData, progressSubscriber$, isHeadCount)
      .subscribe(
        (response) => {
          setFile(null);
          setDropzoneKey((currentKey) => currentKey + 1);
          setUploading(false);
          setProgress(0);
          logger.info('Correctly uploaded report.', response);
          toast(
            intl.formatMessage({
              id: TranslationKey.REPORT_UPLOAD_SUCCESS,
            }),
            toastConfig,
          );
        },
        (error) => {
          logger.error('Error uploading report.', error);
          setUploading(false);
          setProgress(0);
          toast.error(
            intl.formatMessage({
              id: TranslationKey.REPORT_UPLOAD_ERROR,
            }),
            toastConfig,
          );
        },
      );

    return () => {
      progressSubscriber$.unsubscribe();
      request$.unsubscribe();
    };
  }, [file, intl, isHeadCount, uploading]);

  const handleDropRejected = useCallback(
    (files: File[]) => {
      files.forEach((rejectedFile) => {
        if (rejectedFile.size > MAX_FILE_SIZE) {
          logger.error('File too large, rejected upload.', rejectedFile);
          toast.error(
            intl.formatMessage(
              {
                id: TranslationKey.ERROR_DROPZONE_FILE_TOO_LARGE,
              },
              {
                fileSize: bytes(MAX_FILE_SIZE, {
                  decimalPlaces: 0,
                }),
              },
            ),
            toastConfig,
          );
        }
      });
    },
    [intl],
  );

  return (
    <Grid xs={12} md={6} item>
      <div className={styles.dropzoneContainer}>
        <Typography variant="h6">{title}</Typography>

        <DropzoneArea
          classes={{
            root: styles.dropArea,
            textContainer: styles.dropAreaTextContainer,
          }}
          previewGridClasses={{ container: styles.previewContainer }}
          key={dropzoneKey}
          acceptedFiles={[
            'application/vnd.ms-excel',
            'application/vnd.ms-excel.sheet.macroEnabled.12',
            'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          ]}
          filesLimit={1}
          maxFileSize={MAX_FILE_SIZE}
          showFileNames
          showAlerts={false}
          dropzoneText={intl.formatMessage({
            id: TranslationKey.REPORT_LOAD_INSTRUCTIONS,
          })}
          onChange={handleChange}
          onDropRejected={handleDropRejected}
        />

        {uploading && <LinearProgress variant="determinate" value={progress} />}

        <div className={styles.dropzoneButtonContainer}>
          <ABIButton
            className={styles.uploadButton}
            disabled={!file}
            onClick={handleUploadClick}
          >
            <FormattedMessage id={TranslationKey.UPLOAD_REPORT} />
          </ABIButton>
        </div>
      </div>
    </Grid>
  );
};

export default ReportDropzone;
