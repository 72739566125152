/* eslint-disable consistent-return */
/* eslint-disable default-case */
/* eslint-disable no-param-reassign */

import {
  AppAction,
  CLEAR_WORD_CLOUD_STATE,
  FETCH_WORD_CLOUD_DATA,
  SET_WORD_CLOUD_CONFIG,
  SET_WORD_CLOUD_DATA,
  SET_WORD_CLOUD_FETCHING,
} from '../actions/actionTypes';
import { immerProduce } from '../helpers/util-functions';
import { WordCloudState } from '../types';

const initState: WordCloudState = {
  fetching: false,
  data: [],
  count: 0,
  config: null,
};

const wordCloudReducer = (
  state = initState,
  action: AppAction,
): WordCloudState =>
  immerProduce(state, (draft) => {
    switch (action.type) {
      case SET_WORD_CLOUD_FETCHING:
        draft.fetching = action.fetching;
        return;

      case SET_WORD_CLOUD_CONFIG:
        draft.config = action.config;
        return;

      case FETCH_WORD_CLOUD_DATA:
        draft.fetching = true;
        return;

      case SET_WORD_CLOUD_DATA:
        draft.fetching = false;
        draft.data = action.data;
        draft.count = action.count;
        return;

      case CLEAR_WORD_CLOUD_STATE:
        return initState;
    }
  });

export default wordCloudReducer;
