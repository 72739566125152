import produce, { enableES5 } from 'immer';
import { each, find, flatten, map, pick, uniq } from 'lodash-es';
import queryString from 'query-string';
import { IntlShape } from 'react-intl';
import {
  AdminTableField,
  BooleanSelection,
  ChartType,
  CommonQuestionOption,
  ExitMotive,
  InterviewStatus,
  InvoluntaryExitMotive,
  Locale,
  NewInterviewField,
  OtherPositionOption,
  QuestionaryFieldPrefix,
  QuestionType,
  RecommendsOption,
  ReportFilterField,
  ReportTickType,
  UserRole,
} from '../enums';
import {
  AdminUser,
  Collaborator,
  IAnswer,
  IQuestion,
  QuestionaryValues,
  RawAdminUser,
  RawCollaborator,
  RawQuestion,
  RawReportData,
  RawUser,
  ReportData,
  ReportFilterValues,
  ReportRecord,
  SelectOption,
  UserInSession,
} from '../types';

export const getLocaleFromBrowser = (): Locale =>
  navigator.language.includes('es') ? Locale.Spanish : Locale.English;

export function immerProduce<T>(
  state: T,
  draft: (val: T) => T | undefined | void,
): T {
  enableES5();
  return produce(state, draft);
}

export const getNumericEnumValues = (enumeration: {
  [key: string]: number | string;
}): number[] =>
  Object.values(enumeration).filter(
    (str) => Number.isNaN(+str) === false,
  ) as number[];

export const getNumericEnumEntries = (enumeration: {
  [key: string]: number | string;
}): string[] =>
  Object.keys(enumeration).filter((str) => Number.isNaN(+str) !== false);

export function testCredentialsError(this: {
  parent: { credentialsError: boolean };
}): boolean {
  return !this.parent.credentialsError;
}

export const serializeQuery = (
  obj: { [key: string]: unknown } | number[],
): string => {
  const isoDateObj = Object.entries(obj).reduce(
    (newObj, [key, value]) => ({
      ...newObj,
      [key]: value instanceof Date ? value.toISOString() : (value as string),
    }),
    {},
  );
  return queryString.stringify(isoDateObj, {
    arrayFormat: 'bracket',
  });
};

export const downloadWithFileURL = (
  fileName: string,
  fileURL: string,
): void => {
  const anchorElem = document.createElement('a');

  anchorElem.href = fileURL;
  anchorElem.target = '_blank';
  anchorElem.download = fileName;
  anchorElem.style.display = 'none';
  document.body.appendChild(anchorElem);
  anchorElem.click();

  setTimeout(() => {
    document.body.removeChild(anchorElem);
    window.URL.revokeObjectURL(fileURL);
  }, 0);
};

export const downloadFile = (fileName: string, blob: Blob): void => {
  const fileURL = window.URL.createObjectURL(blob);
  downloadWithFileURL(fileName, fileURL);
};

export const isInterviewComplete = (status: InterviewStatus): boolean =>
  [
    InterviewStatus.FeedbackAccepted,
    InterviewStatus.FeedbackNotConfirmed,
  ].includes(status);

export const isInterviewDownloadable = (status: InterviewStatus): boolean =>
  [
    InterviewStatus.Registered,
    InterviewStatus.Feedback,
    InterviewStatus.FeedbackSent,
    InterviewStatus.FeedbackRejected,
    InterviewStatus.FeedbackAccepted,
    InterviewStatus.FeedbackNotConfirmed,
  ].includes(status);

export const setInlineStyles = (targetElem: HTMLElement): void => {
  const transformProperties = ['fill', 'color', 'font-size', 'stroke', 'font'];

  function recurseElementChildren(node: SVGSVGElement | HTMLElement): void {
    if (!node.style) return;
    const styles = getComputedStyle(node);
    transformProperties.forEach((property) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      node.style[property] = styles[property]; // eslint-disable-line @typescript-eslint/no-unsafe-assignment, no-param-reassign
    });

    Array.from(node.childNodes).forEach((child) => {
      recurseElementChildren(child as SVGSVGElement);
    });
  }

  Array.from(targetElem.getElementsByTagName('svg')).forEach((svgElement) => {
    recurseElementChildren(svgElement);
  });
};

export const getChartType = (values: ReportFilterValues): ChartType => {
  const allZone =
    values.allZone && !values.vicePresidencies?.length && !values.uens?.length;

  const singleBU =
    (values.businessUnits?.length === 1 || values.allBU) &&
    !values.countries?.length &&
    !values.vicePresidencies?.length &&
    !values.uens?.length;

  const singleCountry =
    values.countries?.length === 1 &&
    !values.businessUnits?.length &&
    !values.vicePresidencies?.length &&
    !values.uens?.length;

  const singleVP = values.vicePresidencies?.length === 1;

  const singleUEN = values.uens?.length === 1;

  return allZone || singleBU || singleCountry || singleVP || singleUEN
    ? ChartType.Donut
    : ChartType.Bar;
};

export const userSessionMapper = (user: RawUser): UserInSession => ({
  ...pick(user, ['id', 'name', 'sharp', 'email', 'country']),
  position: '',
  role: user.visibility_perm as UserRole,
  businessUnit: user.bu,
  lastPasswordChange: new Date(user.last_password_change),
  dh_access: Boolean(user.dh_access),
});

export const collaboratorMapper = (
  collaborator: RawCollaborator,
): Collaborator => ({
  [NewInterviewField.Name]: collaborator.name,
  [NewInterviewField.Sex]: collaborator.gendre,
  [NewInterviewField.LineManager]: {
    id: collaborator.lmID,
    name: collaborator.lm,
  },
  [NewInterviewField.LineManagerEmail]: collaborator.lmEmail,
  [NewInterviewField.Band]: collaborator.band,
  [NewInterviewField.Position]: collaborator.position,
  [NewInterviewField.Country]: collaborator.country,
  [NewInterviewField.UEN]: {
    id: 0,
    name: collaborator.uen,
  },
  [NewInterviewField.Location]: {
    id: 0,
    name: collaborator.location,
  },
  [NewInterviewField.BusinessUnit]: collaborator.bu,
  [NewInterviewField.VicePresidency]: collaborator.vp,
  [NewInterviewField.HiringDate]: collaborator.hiringDate,
});

export const questionsMapper = (
  rawQuestions: RawQuestion[],
  intl: IntlShape,
  voluntary = true,
): IQuestion[] => {
  const exitMotiveOptions: SelectOption[] = Object.values(
    voluntary ? ExitMotive : InvoluntaryExitMotive,
  ).map((value: string) => ({
    value,
    label: intl.formatMessage({
      id: `EXIT_MOTIVE_${value}`,
    }),
  }));

  const otherPositionOptions: SelectOption[] = Object.values(
    OtherPositionOption,
  ).map((value) => ({
    value,
    label: intl.formatMessage({
      id: `OTHER_POSITION_OPTION_${value}`,
    }),
  }));

  const recommendsOptions: SelectOption[] = Object.values(RecommendsOption).map(
    (value) => ({
      value,
      label: intl.formatMessage({
        id: `RECOMMENDS_OPTION_${value}`,
      }),
    }),
  );

  const commonOptions: SelectOption[] = Object.values(CommonQuestionOption).map(
    (value) => ({
      value,
      label: intl.formatMessage({
        id: value,
      }),
    }),
  );

  const booleanOptions: SelectOption[] = Object.values(BooleanSelection).map(
    (value) => ({
      value,
      label: intl.formatMessage({
        id: value,
      }),
    }),
  );

  const optionsMap: Record<QuestionType, SelectOption[] | undefined> = {
    [QuestionType.ExitMotive]: exitMotiveOptions,
    [QuestionType.ExitMotives]: exitMotiveOptions,
    [QuestionType.OtherPosition]: otherPositionOptions,
    [QuestionType.Recommends]: recommendsOptions,
    [QuestionType.Common]: commonOptions,
    [QuestionType.OpenText]: undefined,
    [QuestionType.Additional]: undefined,
    [QuestionType.Boolean]: booleanOptions,
  };

  return rawQuestions.map(
    (question): IQuestion => ({
      fieldPrefix: question.prefix as QuestionaryFieldPrefix,
      type: question.type,
      question: question.question,
      options: optionsMap[question.type],
      explanation: question.explanation,
      required: !!question.required,
    }),
  );
};

export const answersMapper = (answers: IAnswer[]): Partial<QuestionaryValues> =>
  answers.reduce((obj, prev) => {
    const [field, value] = Object.entries(prev)[0];
    return {
      ...obj,
      [field]: value || '',
    };
  }, {});

export const userAdminMapper = (rawUsers: RawAdminUser[]): AdminUser[] =>
  rawUsers.map((rawUser) => ({
    [AdminTableField.Selected]: false,
    [AdminTableField.Id]: rawUser.sharp,
    [AdminTableField.Interviewer]: rawUser.name,
    [AdminTableField.Email]: rawUser.email,
    [AdminTableField.UEN]: rawUser.uen,
    [AdminTableField.Role]: rawUser.role || UserRole.Regular,
    [AdminTableField.Exists]: !!rawUser.exists,
    // eslint-disable-next-line camelcase
    [AdminTableField.DhAccess]: !!rawUser.dh_access,
  }));

export const reportDataMapper = (rawData: RawReportData): ReportData =>
  Object.entries(rawData).reduce(
    (prev, [field, value]) => ({
      ...prev,
      [field]: Object.entries(value).map(([dataField, dataValue]) => ({
        field: dataField,
        value: dataValue,
      })),
    }),
    {},
  );

export const getReportTickType = (
  values: ReportFilterValues,
): ReportTickType | undefined => {
  if ((values[ReportFilterField.UENs]?.length || 0) > 1) {
    return ReportTickType.Location;
  }
  if ((values[ReportFilterField.VicePresidencies]?.length || 0) > 1) {
    return ReportTickType.VicePresidency;
  }
  if ((values[ReportFilterField.Countries]?.length || 0) > 1) {
    return ReportTickType.Country;
  }
  if ((values[ReportFilterField.BusinessUnits]?.length || 0) > 1) {
    return ReportTickType.BusinessUnit;
  }
  return undefined;
};

export const mapSLAReportData = (
  reportData?: ReportData,
): ReportData | undefined => {
  if (!reportData) return undefined;

  function remap(innerReportData: ReportData): ReportData {
    const newMap: Record<string, ReportRecord[]> = {};
    const innerFields = uniq(
      map(flatten(Object.values(innerReportData)), 'field'),
    );
    each(innerFields, (innerField) => {
      newMap[innerField] = [];
      const outerFields = Object.keys(innerReportData);
      each(outerFields, (outerField) => {
        const record = find(innerReportData[outerField], { field: innerField });
        const recordValue = record ? record.value : 0;
        newMap[innerField] = [
          ...newMap[innerField],
          { field: outerField, value: recordValue },
        ];
      });
    });
    return newMap;
  }
  function sumSeparation(innerReportData: ReportData): ReportData {
    const MAX_MOTIVES = 4;
    const sortedMap = Object.entries(innerReportData).reduce(
      (prev, [field, values]) => {
        const sorted = values.sort((a, b) => b.value - a.value);
        const maxMotives = sorted.slice(0, MAX_MOTIVES);
        const rawToSum = sorted.slice(MAX_MOTIVES);
        const toSum = rawToSum.length
          ? [
              rawToSum.reduce(
                (innerPrev, curr) => ({
                  field: 'SUM_OTHERS',
                  value: innerPrev.value + curr.value,
                }),
                { value: 0 },
              ),
            ]
          : [];
        const newArray = [...maxMotives, ...toSum];
        return {
          ...prev,
          [field]: newArray,
        };
      },
      {},
    );
    return sortedMap;
  }
  const mappedForSum = remap(reportData);
  const summed = sumSeparation(mappedForSum);
  return remap(summed);
};
