/* eslint-disable no-param-reassign */
/* eslint-disable default-case */

import {
  AppAction,
  CREATE_INTERVIEW_REQUEST,
  SAVE_FEEDBACK_REQUEST,
  SAVE_QUESTIONARY_REQUEST,
  SET_INTERVIEW_VALUES,
  SET_SAVING_QUESTIONS,
  SET_SENDING,
} from '../actions/actionTypes';
import { immerProduce } from '../helpers/util-functions';
import { InterviewState } from '../types';

const initState: InterviewState = {
  sending: false,
  saving: false,
  id: undefined,
  interviewerId: undefined,
  collaborator: undefined,
  status: undefined,
};

const uiReducer = (state = initState, action: AppAction): InterviewState =>
  immerProduce(state, (draft) => {
    switch (action.type) {
      case CREATE_INTERVIEW_REQUEST:
        draft.sending = true;
        return;

      case SET_INTERVIEW_VALUES:
        draft.sending = false;
        draft.id = action.id;
        draft.interviewerId = action.interviewerId;
        draft.collaborator = action.collaborator;
        draft.status = action.status;
        return;

      case SET_SAVING_QUESTIONS:
        draft.saving = action.saving;
        return;

      case SAVE_QUESTIONARY_REQUEST:
      case SAVE_FEEDBACK_REQUEST:
        draft.sending = true;
        return;

      case SET_SENDING:
        draft.sending = action.sending;
    }
  });

export default uiReducer;
