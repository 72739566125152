import { Grid } from '@material-ui/core';
import queryString from 'query-string';
import React, { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import noticeConfirmed from '../../../assets/images/early-ending-background.svg';
import { useSendLMFeedback } from '../../../hooks';
import { TranslationKey } from '../../../i18n/translations';
import { userSessionSelectors } from '../../../selectors';
import LoadingSpinner from '../../LoadingSpinner';
import MainButton from '../../MainButton';
import styles from './NoticeDeclined.module.scss';

const NoticeDeclined = (): ReactElement => {
  const location = useLocation();
  const { interviewId } = queryString.parse(location.search) as {
    interviewId: string;
  };

  const sending = useSendLMFeedback(false, Number.parseInt(interviewId, 10));
  const hasUser = !!useSelector(userSessionSelectors.getUser);
  const redirectPath = hasUser ? '/' : '/session/login';

  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignContent="center"
      className={styles.container}
    >
      <Grid
        container
        item
        xs={12}
        spacing={1}
        direction="row"
        justify="center"
        alignContent="center"
      >
        {sending ? (
          <LoadingSpinner />
        ) : (
          <>
            <Grid
              item
              container
              xs={12}
              sm={7}
              md={6}
              justify="center"
              alignContent="center"
              direction="column"
            >
              <Grid item xs={12} className={styles.textContainer}>
                <p>
                  <FormattedMessage
                    id={TranslationKey.NOTICE_DECLINED_PARAGRAPH_1}
                  />
                </p>

                <p>
                  <FormattedMessage
                    id={TranslationKey.NOTICE_DECLINED_PARAGRAPH_2}
                  />
                </p>

                <h4>
                  <FormattedMessage
                    id={TranslationKey.NOTICE_DECLINED_THANKS}
                  />
                </h4>
              </Grid>
            </Grid>

            <Grid
              item
              container
              xs={12}
              sm={5}
              md={6}
              justify="center"
              alignContent="center"
            >
              <img
                src={noticeConfirmed}
                alt="Paper plane"
                className={styles.img}
              />
            </Grid>
          </>
        )}
      </Grid>

      {!sending && (
        <Grid
          container
          item
          xs={12}
          justify="center"
          alignContent="center"
          className={styles.buttonContainer}
        >
          <Link to={redirectPath}>
            <MainButton type="button">
              <FormattedMessage id={TranslationKey.ACCEPT} />
            </MainButton>
          </Link>
        </Grid>
      )}
    </Grid>
  );
};

export default NoticeDeclined;
