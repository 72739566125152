import React, { FunctionComponent, ReactElement, ReactNode } from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root');

const customStyles: Modal.Styles = {
  content: {
    alignItems: 'center',
    backgroundColor: 'white',
    border: 0,
    borderRadius: '16px',
    bottom: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    left: '50%',
    marginRight: '-50%',
    maxWidth: 370,
    padding: 30,
    right: 'auto',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },

  overlay: {
    backgroundColor: '#00000069',
    zIndex: 10,
  },
};

interface ConfirmModalProps {
  children: ReactNode;
  isOpen: boolean;
}

const ConfirmModal: FunctionComponent<ConfirmModalProps> = ({
  children,
  isOpen,
}): ReactElement => (
  <Modal isOpen={isOpen} style={customStyles}>
    {children}
  </Modal>
);

export default ConfirmModal;
