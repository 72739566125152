import * as HttpStatus from 'http-status-codes';
import { useEffect, useMemo, useRef, useState } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { fromEvent, interval, merge } from 'rxjs';
import { AjaxError, AjaxRequest, AjaxResponse } from 'rxjs/ajax';
import {
  delay,
  map,
  skipWhile,
  switchMap,
  take,
  tap,
  throttleTime,
} from 'rxjs/operators';
import xhook from 'xhook';
import { userSessionActions } from '../actions';
import { interviewAPI } from '../api';
import toastConfig from '../config/toast';
import {
  MAX_INACTIVITY_SECONDS,
  SECONDS_BEFORE_INACTIVITY_LOGOUT,
} from '../constants';
import { Locale, QuestionaryType } from '../enums';
import { questionsMapper } from '../helpers/util-functions';
import history from '../history';
import { TranslationKey } from '../i18n/translations';
import { userSessionSelectors } from '../selectors';
import { logger } from '../services';
import { IQuestion, RawQuestion } from '../types';

/**
 * Helpers
 */
export const usePrevious = <T>(value: T): T | undefined => {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef<T>();

  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
};

export const useDebounce = <T>(value: T, debounceDelay: number): T => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, debounceDelay);

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, debounceDelay], // Only re-call effect if value or delay changes
  );

  return debouncedValue;
};

const dateFormatPartMap: {
  [key: string]: 'M' | 'd' | 'y';
} = {
  month: 'M',
  day: 'd',
  year: 'y',
};

export const useDateFormatString = (): string => {
  const intl = useIntl();
  const locale = useSelector(userSessionSelectors.getLocale);

  const formatParts = intl.formatters.getDateTimeFormat(locale).formatToParts();
  return formatParts.reduce((dateFormat, next) => {
    const mappedChar = dateFormatPartMap[next.type] || next.value;
    const newStr = Array.from(next.value)
      .map(() => mappedChar)
      .join('');
    return `${dateFormat}${newStr}`;
  }, '');
};

export const useWindowWidth = (): number => {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const sub = fromEvent(window, 'resize')
      .pipe(delay(300))
      .subscribe(() => {
        setWidth(window.innerWidth);
      });
    return () => {
      sub.unsubscribe();
    };
  }, []);
  return width;
};

/**
 * Session
 */
export const useUnauthorizedSessionIntercept = (): void => {
  const dispatch = useDispatch();
  useEffect(() => {
    xhook.after((req: AjaxRequest, res: AjaxResponse) => {
      if (
        res.status === HttpStatus.UNAUTHORIZED &&
        !req.url?.includes('/api/session/password-recovery')
      ) {
        logger.info('Logged user out of session', req, res);
        dispatch(userSessionActions.clearUserSession());
        history.push({
          pathname: '/session/login',
          search: '',
        });
      }
    });
  }, [dispatch]);
};

export const useInactivityTimer = (): void => {
  const user = useSelector(userSessionSelectors.getUser);
  const intl = useIntl();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!user) return undefined;

    let toastId: string | number | undefined;

    function hideWarning() {
      if (toastId !== undefined) {
        toast.dismiss(toastId);
        toastId = undefined;
      }
    }

    function showWarning() {
      const minutes = Math.floor(SECONDS_BEFORE_INACTIVITY_LOGOUT / 60);
      toastId = toast.error(
        intl.formatMessage(
          {
            id: TranslationKey.INACTIVITY_WARNING,
          },
          {
            minutes,
          },
        ),
        {
          position: 'bottom-right',
          draggable: false,
          pauseOnFocusLoss: false,
          autoClose: false,
          closeOnClick: false,
          pauseOnHover: false,
          hideProgressBar: true,
        },
      );
    }

    const subscription = merge(
      fromEvent(document, 'click'),
      fromEvent(document, 'wheel'),
      fromEvent(document, 'scroll'),
      fromEvent(document, 'mousemove'),
      fromEvent(document, 'keyup'),
      fromEvent(window, 'resize'),
      fromEvent(window, 'scroll'),
      fromEvent(window, 'mousemove'),
    )
      .pipe(
        throttleTime(300),
        tap(hideWarning),
        switchMap(() => interval(1000).pipe(take(MAX_INACTIVITY_SECONDS + 1))),
        tap((inactivitySeconds) => {
          const secondsLeft = MAX_INACTIVITY_SECONDS - inactivitySeconds;
          if (
            secondsLeft <= SECONDS_BEFORE_INACTIVITY_LOGOUT &&
            toastId === undefined
          ) {
            showWarning();
          }
        }),
        skipWhile(
          (inactivitySeconds) => inactivitySeconds < MAX_INACTIVITY_SECONDS,
        ),
      )
      .subscribe(() => {
        hideWarning();
        logger.info('Logged user out of session from inactivity.');
        dispatch(userSessionActions.logoutUserRequest());
      });

    return () => {
      subscription.unsubscribe();
    };
  }, [dispatch, intl, user]);
};

/**
 * Interviews
 */
export const useFetchQuestions = (
  questionaryType: QuestionaryType,
  intl: IntlShape,
  voluntary = true,
): [boolean, IQuestion[]] => {
  const [fetching, setFetching] = useState(true);
  const [questions, setQuestions] = useState<RawQuestion[]>([]);

  useEffect(() => {
    setFetching(true);

    const sub = interviewAPI
      .fetchQuestions(questionaryType, intl.locale as Locale)
      .pipe(
        map(
          ({ response }: { response: { questionary: RawQuestion[] } }) =>
            response.questionary,
        ),
      )
      .subscribe((fetchedQuestions) => {
        setQuestions(fetchedQuestions);
        setFetching(false);
      });

    return () => {
      sub.unsubscribe();
    };
  }, [intl, questionaryType]);

  const mappedQuestions = useMemo(
    () => questionsMapper(questions, intl, voluntary),
    [intl, questions, voluntary],
  );

  return [fetching, mappedQuestions];
};

export const useSendLMFeedback = (
  accepted: boolean,
  interviewId: number,
): boolean => {
  const [sending, setSending] = useState(true);
  const intl = useIntl();

  useEffect(() => {
    const sub = interviewAPI
      .sendLineManagerFeedback(accepted, interviewId)
      .subscribe(
        (response) => {
          setSending(false);
          logger.info(response);
          toast(
            intl.formatMessage({
              id: TranslationKey.LM_FEEDBACK_SENT_SUCCESS,
            }),
            toastConfig,
          );
        },
        (error: AjaxError) => {
          logger.error('Error sending line manager feedback', error);
          if (error.status !== HttpStatus.NOT_ACCEPTABLE) {
            toast.error(
              intl.formatMessage({
                id: TranslationKey.LM_FEEDBACK_SENT_ERROR,
              }),
              toastConfig,
            );
            return;
          }

          toast.error(
            intl.formatMessage({
              id: TranslationKey.LM_FEEDBACK_ALREADY_SENT_ERROR,
            }),
            toastConfig,
          );
          history.push({
            pathname: '/',
            search: '',
          });
        },
      );

    return () => {
      sub.unsubscribe();
    };
  }, [accepted, interviewId, intl]);

  return sending;
};

export const useHelpMailLink = (): string => {
  const mainAddress = 'wendy.herrera@ab-inbev.com';
  const intl = useIntl();
  return `mailto:${mainAddress}?subject=${encodeURIComponent(
    intl.formatMessage({
      id: TranslationKey.HELP_MAIL_SUBJECT,
    }),
  )}&body=${encodeURIComponent(
    intl.formatMessage({
      id: TranslationKey.HELP_MAIL_BODY,
    }),
  )}`;
};
