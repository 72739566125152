import { Grid } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { Route, Routes } from 'react-router';
import { Link } from 'react-router-dom';
import abiLogo from '../../assets/General/Logo_ABI.svg';
import mazPeopleLogo from '../../assets/Login/Logo Offboarding_Login.svg';
import { URL_SUPPORT } from '../../config/environment';
import { TranslationKey } from '../../i18n/translations';
import LanguageSwitcher from '../LanguageSwitcher';
import ForgotPassword from './ForgotPassword';
import Login from './Login';
import PasswordRecovery from './PasswordRecovery';
import styles from './Session.module.scss';

const Session = (): ReactElement => (
  <Grid
    container
    direction="row"
    justify="flex-end"
    alignItems="center"
    className={styles.container}
  >
    <Grid container item xs={12} sm={5} className={styles.panel}>
      <div className={styles.loginContainer}>
        <Link to="/session/login" tabIndex={-1} className={styles.appLogoLink}>
          <img
            className={styles.appLogo}
            src={mazPeopleLogo}
            alt="Exit Interview Logo"
          />
        </Link>
        <p className={styles.welcomeMsg}>
          <FormattedMessage id={TranslationKey.WELCOME_MSG} />
        </p>
        <div className={styles.formContainer}>
          <Routes>
            <Route path="login" element={<Login />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="password-recovery" element={<PasswordRecovery />} />
          </Routes>

          <div className={styles.languageSwitcher}>
            <a
              href={URL_SUPPORT}
              target="_blank"
              rel="noreferrer"
              className={styles.helpLink}
            >
              <FormattedMessage id={TranslationKey.REQUEST_HELP} />
            </a>
            <LanguageSwitcher />
          </div>
        </div>
        <img className={styles.abiLogo} src={abiLogo} alt="AbInBev Logo" />
      </div>
    </Grid>
  </Grid>
);

export default Session;
