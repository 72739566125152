import { Observable } from 'rxjs';
import { ajax, AjaxResponse } from 'rxjs/ajax';
import { apiURL } from '../config/environment';
import {
  ExcelReportType,
  HttpMethod,
  Locale,
  ReportFilterField,
} from '../enums';
import { ReportFilterValues } from '../types';

const contentTypeJSONHeader = {
  'Content-Type': 'application/json',
};
const acceptJSONHeader = {
  Accept: 'application/json; charset=UTF-8',
};

export const fetchReportData = (
  filters: ReportFilterValues,
): Observable<AjaxResponse> =>
  ajax({
    url: `${apiURL}/auth/reports/generate`,
    method: HttpMethod.Post,
    body: {
      ...filters,
      uens: filters[ReportFilterField.UENs]?.map(({ name }) => name),
    },
    headers: {
      ...contentTypeJSONHeader,
      ...acceptJSONHeader,
    },
    withCredentials: true,
  });

export const downloadReportData = (
  filters: ReportFilterValues,
  locale: Locale,
  reportType: ExcelReportType,
): Observable<AjaxResponse> =>
  ajax({
    url:
      reportType === ExcelReportType.ExitInterview
        ? `${apiURL}/auth/reports/download`
        : `${apiURL}/auth/reports/withdrawals`,
    method: HttpMethod.Post,
    body: {
      ...filters,
      uens: filters[ReportFilterField.UENs]?.map(({ name }) => name),
    },
    responseType: 'blob',
    headers: {
      ...contentTypeJSONHeader,
      Accept:
        'application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8',
      'Accept-Language': locale,
    },
    withCredentials: true,
  });
