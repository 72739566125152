import { CLIENT_ID, URL_AUTORIZE, SCOPE, URL_CALLBACK } from '../../config/environment';


const generateRandomString = (length: number) => {
    let text = '';
    const possible =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < length; i += 1) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
};


const generateState = () => {
    const randomState = generateRandomString(40);
    sessionStorage.setItem('state', randomState);
    return randomState;
}

export const redirectSSO = () => {
    const parameters = new URLSearchParams({
        response_type: "code",
        client_id: CLIENT_ID,
        redirect_uri: URL_CALLBACK,
        state: generateState(),
        scope: SCOPE,
    });
    window.location.href = URL_AUTORIZE + parameters.toString();
}