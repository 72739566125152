import { AdminTableField, AdminTask, SortDirection, UserRole } from '../enums';
import { AdminUser } from '../types';
import * as types from './actionTypes';

export const setFetching = (
  fetching: boolean,
): types.SetAdminFetchingAction => ({
  type: types.SET_ADMIN_FETCHING,
  fetching,
});

export const setSubmitting = (
  submitting: boolean,
): types.SetAdminSubmittingAction => ({
  type: types.SET_ADMIN_SUBMITTING,
  submitting,
});

export const setTask = (task: AdminTask): types.SetAdminTaskAction => ({
  type: types.SET_ADMIN_TASK,
  task,
});

export const setPage = (page: number): types.SetAdminPageAction => ({
  type: types.SET_ADMIN_PAGE,
  page,
});

export const setSortColumn = (
  sortColumn: AdminTableField,
): types.SetAdminSortColumnAction => ({
  type: types.SET_ADMIN_SORT_COLUMN,
  sortColumn,
});

export const setSortDirection = (
  sortDirection: SortDirection,
): types.SetAdminSortDirectionAction => ({
  type: types.SET_ADMIN_SORT_DIRECTION,
  sortDirection,
});

export const setTextFilter = (
  textFilter: string,
): types.SetAdminTextFilterAction => ({
  type: types.SET_ADMIN_TEXT_FILTER,
  textFilter,
});

export const setUsers = (
  users: AdminUser[],
  count: number,
): types.SetAdminUsersAction => ({
  type: types.SET_ADMIN_USERS,
  users,
  count,
});

export const toggleUserSelected = (
  id: number,
): types.ToggleUserSelectedAction => ({
  type: types.TOGGLE_USER_SELECTED,
  id,
});

export const setUserEmail = (
  id: number,
  email: string,
): types.SetAdminUserEmail => ({
  type: types.SET_ADMIN_USER_EMAIL,
  id,
  email,
});

export const setUserRole = (
  id: number,
  role: UserRole,
): types.SetAdminUserRoleAction => ({
  type: types.SET_ADMIN_USER_ROLE,
  id,
  role,
});

export const setUserDHAccess = (
  id: number,
  dhaccess: boolean,
): types.SetAdminUserDhAccess => ({
  type: types.SET_ADMIN_USER_DH_ACCESS,
  id,
  // eslint-disable-next-line camelcase
  dh_access: dhaccess,
});

export const setAllUsersSelected = (
  selected: boolean,
): types.SetAllUsersSelectedAction => ({
  type: types.SET_ALL_USERS_SELECTED,
  selected,
});

export const userChangesRequest = (): types.UserChangesRequestAction => ({
  type: types.USER_CHANGES_REQUEST,
});

export const clearState = (): types.ClearAdminStateAction => ({
  type: types.CLEAR_ADMIN_STATE,
});
