import { useFormikContext } from 'formik';
import { FunctionComponent, useCallback, useEffect } from 'react';
import { map } from 'rxjs/operators';
import { interviewAPI } from '../../api';
import { NewInterviewField } from '../../enums';
import { collaboratorMapper } from '../../helpers/util-functions';
import { useDebounce } from '../../hooks';
import { logger } from '../../services';
import { Collaborator, RawCollaborator } from '../../types';

interface FetchCollaboratorProps {
  setFetching: (fetching: boolean) => void;
}

interface PartialNewInterviewValues {
  id: number;
}

const emptyCollaborator: Collaborator = {
  [NewInterviewField.Name]: '',
  [NewInterviewField.Sex]: '',
  [NewInterviewField.LineManager]: '',
  [NewInterviewField.Band]: '',
  [NewInterviewField.Position]: '',
  [NewInterviewField.Country]: '',
  [NewInterviewField.UEN]: '',
  [NewInterviewField.BusinessUnit]: '',
  [NewInterviewField.VicePresidency]: '',
  [NewInterviewField.HiringDate]: '',
};

let mounted = false;
const FetchCollaborator: FunctionComponent<FetchCollaboratorProps> = ({
  setFetching,
}): null => {
  const {
    values,
    setFieldValue,
  } = useFormikContext<PartialNewInterviewValues>();

  const updateFormValues = useCallback(
    (fetchedCollaborator) => {
      Object.entries(fetchedCollaborator).forEach(([field, value]) => {
        setFieldValue(field, value, true);
      });
    },
    [setFieldValue],
  );

  const WAIT_TIME = 300;
  const debouncedId = useDebounce(values.id, WAIT_TIME);
  /**
   * Fetches collaborator data on `id` change
   */
  useEffect(() => {
    if (!mounted || !debouncedId) {
      mounted = true;
      return undefined;
    }

    setFetching(true);

    const subscription$ = interviewAPI
      .fetchCandidateData(debouncedId)
      .pipe(
        map(({ response }: { response: { element: RawCollaborator } }) =>
          collaboratorMapper(response.element),
        ),
      )
      .subscribe(
        (collaborator) => {
          logger.info('Fetched collaborator.', collaborator);
          updateFormValues(collaborator);
          setFetching(false);
        },
        (err) => {
          logger.error('Error fetching collaborator.', err);
          updateFormValues(emptyCollaborator);
          setFetching(false);
        },
      );

    return () => {
      setFetching(false);
      subscription$.unsubscribe();
    };
  }, [debouncedId, setFetching, updateFormValues]);

  return null;
};

export default FetchCollaborator;
