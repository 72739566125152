import { Grid } from '@material-ui/core';
import Container from '@material-ui/core/Container/Container';
import React, { ReactElement, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Route, Routes, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { adminActions } from '../../actions';
import { TranslationKey } from '../../i18n/translations';
import styles from './Admin.module.scss';
import AdminUsers from './AdminUsers';
import ReportLoad from './ReportLoad';

const Admin = (): ReactElement => {
  const dispatch = useDispatch();

  // Cleanup state on un-mounting
  useEffect(
    () => () => {
      dispatch(adminActions.clearState());
    },
    [dispatch],
  );

  const location = useLocation();

  return (
    <div className={styles.container}>
      <Container>
        <Grid container>
          <Grid item xs={12} className={styles.subMenuContainer}>
            <nav className={styles.subMenu}>
              <ul>
                <li
                  className={
                    location.pathname === '/admin' ? styles.menuSelected : ''
                  }
                >
                  <Link to="/admin" className={styles.menuLink}>
                    <FormattedMessage id={TranslationKey.USERS} />
                  </Link>
                </li>

                <li
                  className={
                    location.pathname === '/admin/report-load'
                      ? styles.menuSelected
                      : ''
                  }
                >
                  <Link to="/admin/report-load" className={styles.menuLink}>
                    <FormattedMessage id={TranslationKey.REPORT_LOAD} />
                  </Link>
                </li>
              </ul>
            </nav>
          </Grid>

          <Routes>
            <Route path="" element={<AdminUsers />} />
            <Route path="report-load" element={<ReportLoad />} />
          </Routes>
        </Grid>
      </Container>
    </div>
  );
};

export default Admin;
