import { StatusCodes } from 'http-status-codes';
import { toast } from 'react-toastify';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { interviewActions } from '../actions';
import {
  AppAction,
  CreateInterviewRequestAction,
  CREATE_INTERVIEW_REQUEST,
  SaveFeedbackRequestAction,
  SaveQuestionaryRequestAction,
  SAVE_FEEDBACK_REQUEST,
  SAVE_QUESTIONARY_REQUEST,
} from '../actions/actionTypes';
import { interviewAPI } from '../api';
import toastConfig from '../config/toast';
import {
  BooleanSelection,
  InterviewStatus,
  NewInterviewCreationError,
  NewInterviewField,
  QuestionaryFieldPrefix,
  QuestionaryType,
} from '../enums';
import history from '../history';
import intlHelper from '../i18n/intlHelper';
import { TranslationKey } from '../i18n/translations';
import { interviewSelectors, userSessionSelectors } from '../selectors';
import { getLocale } from '../selectors/userSession';
import { logger } from '../services';
import { AppState, IAnswer, NewInterviewError } from '../types';

let resInterviewId: number;
export const createInterviewEpic: Epic<AppAction, AppAction, AppState> = (
  action$,
  state$,
) =>
  action$.pipe(
    ofType<AppAction, CreateInterviewRequestAction>(CREATE_INTERVIEW_REQUEST),
    mergeMap((action) =>
      interviewAPI.createInterview(action.newInterview).pipe(
        mergeMap(({ response }) => {
          logger.info('Created interview.', response);
          resInterviewId = response.interview_id;
          return interviewAPI.savePartialQuestionary(resInterviewId, {
            [QuestionaryFieldPrefix.MainExitMotive]: action.newInterview[
              QuestionaryFieldPrefix.MainExitMotive
            ] as IAnswer,
          });
        }),
        map(() => {
          // Show success message
          const intl = intlHelper.getIntl();
          toast(
            intl?.formatMessage({
              id: TranslationKey.SUCCESS_CREATING_INTERVIEW,
            }),
            toastConfig,
          );

          const newLocation =
            action.newInterview[NewInterviewField.AgreedToInterview] ===
            BooleanSelection.Yes
              ? '/interview/instructions'
              : '/interview/early-ending';
          history.push(newLocation);

          const status =
            action.newInterview[NewInterviewField.AgreedToInterview] ===
            BooleanSelection.Yes
              ? InterviewStatus.Interview
              : InterviewStatus.Registered;

          return interviewActions.setInterviewValues(
            resInterviewId,
            userSessionSelectors.getUser(state$.value)?.id,
            action.newInterview.name,
            status,
          );
        }),
        catchError((error: NewInterviewError) => {
          if (error.status !== StatusCodes.CONFLICT) throw error;
          logger.error('Interview for collaborator already exists', error);
          // Show error message
          const intl = intlHelper.getIntl();
          const transKeyMap: Record<NewInterviewCreationError, string> = {
            [NewInterviewCreationError.InterviewExists]:
              TranslationKey.ERROR_INTERVIEW_EXISTS,
            [NewInterviewCreationError.OutOfScope]:
              TranslationKey.ERROR_INTERVIEW_OUT_OF_SCOPE,
          };
          const errorMessage = intl?.formatMessage(
            {
              id: transKeyMap[error.response.motive] || '',
            },
            {
              sharpId: action.newInterview.id,
            },
          );
          toast.error(errorMessage, toastConfig);
          return of(interviewActions.setInterviewValues());
        }),
        catchError((error) => {
          logger.error('Error creating interview.', error);

          // Show error message
          const intl = intlHelper.getIntl();
          toast.error(
            intl?.formatMessage({
              id: TranslationKey.ERROR_CREATING_INTERVIEW,
            }),
            toastConfig,
          );

          return of(interviewActions.setInterviewValues());
        }),
      ),
    ),
  );

export const saveQuestionaryEpic: Epic<AppAction, AppAction, AppState> = (
  action$,
  state$,
) =>
  action$.pipe(
    ofType<AppAction, SaveQuestionaryRequestAction>(SAVE_QUESTIONARY_REQUEST),
    withLatestFrom(state$.pipe(map(interviewSelectors.getId))),
    mergeMap(([action, interviewId]) =>
      interviewAPI
        .saveQuestionary(
          interviewId as number,
          action.questionary,
          QuestionaryType.Interview,
          getLocale(state$.value),
        )
        .pipe(
          map(({ response }) => {
            logger.info('Saved questionary.', response);

            // Show success message
            const intl = intlHelper.getIntl();
            toast(
              intl?.formatMessage({
                id: TranslationKey.SUCCESS_SAVING_QUESTIONS,
              }),
              toastConfig,
            );

            history.push('/interview/pre-feedback');

            return interviewActions.setSending(false);
          }),
          catchError((err) => {
            logger.error('Error saving questions.', err);

            // Show success message
            const intl = intlHelper.getIntl();
            toast.error(
              intl?.formatMessage({
                id: TranslationKey.ERROR_SAVING_QUESTIONS,
              }),
              toastConfig,
            );

            return of(interviewActions.setSending(false));
          }),
        ),
    ),
  );

export const saveFeedbackEpic: Epic<AppAction, AppAction, AppState> = (
  action$,
  state$,
) =>
  action$.pipe(
    ofType<AppAction, SaveFeedbackRequestAction>(SAVE_FEEDBACK_REQUEST),
    withLatestFrom(state$.pipe(map(interviewSelectors.getId))),
    mergeMap(([action, interviewId]) =>
      interviewAPI
        .saveQuestionary(
          interviewId as number,
          action.feedback,
          QuestionaryType.Feedback,
          getLocale(state$.value),
        )
        .pipe(
          map(({ response }) => {
            logger.info('Saved feedback.', response);

            // Show success message
            const intl = intlHelper.getIntl();
            toast(
              intl?.formatMessage({
                id: TranslationKey.SUCCESS_SAVING_FEEDBACK,
              }),
              toastConfig,
            );

            history.push('/interview/feedback-sent');

            return interviewActions.setSending(false);
          }),
          catchError((err) => {
            logger.error('Error saving feedback', err);

            // Show success message
            const intl = intlHelper.getIntl();
            toast.error(
              intl?.formatMessage({
                id: TranslationKey.ERROR_SAVING_FEEDBACK,
              }),
              toastConfig,
            );

            return of(interviewActions.setSending(false));
          }),
        ),
    ),
  );

export default combineEpics(
  createInterviewEpic,
  saveQuestionaryEpic,
  saveFeedbackEpic,
);
