import React, { useState, useEffect } from 'react';
import { ReactComponent as Logo } from '../../assets/Loading/Logo Offboarding_Carga.svg';

import style from './LoadingSpinner.module.scss';

const MazLoading = () => {
  const [bar, setBar] = useState('0');

  useEffect(() => {
    const interval = setInterval(() => {
      setBar((prevBar) => (+prevBar < 100 ? `${+prevBar + 1}` : prevBar));
    }, 300);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className={style.loginLoader}>
      <Logo />
      <div className={style.loaderProgressBar}>
        <div style={{ width: `${bar}%` }} />
      </div>
    </div>
  );
};

export default MazLoading;
