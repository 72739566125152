import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { userSessionActions } from '../../actions';
import LoadingPage from '../../components/LoadingSpinner/LoadingPage';

const Callback = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    const state = sessionStorage.getItem('state');

    if (params.state === state) {
      dispatch(
        userSessionActions.loginUserRequest(params?.code, params?.state),
      );
    }
  }, [dispatch]);
  return <LoadingPage />;
};

export default Callback;
