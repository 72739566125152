import React from 'react';
import MazLoading from './MazLoading';

import styles from './LoadingSpinner.module.scss';

const LoadingPage = () => {
  return (
    <div className={styles.backgroundLoad}>
      <MazLoading />
    </div>
  );
};

export default LoadingPage;
