import { createSelector } from 'reselect';
import { AppModules } from '../enums';
import { AdminState, AppState } from '../types';

export const getModuleState = (state: AppState): AdminState =>
  state[AppModules.Admin];

export const getFetching = createSelector(
  getModuleState,
  (state) => state.fetching,
);
export const getSubmitting = createSelector(
  getModuleState,
  (state) => state.submitting,
);

export const getTask = createSelector(getModuleState, (state) => state.task);

export const getPage = createSelector(getModuleState, (state) => state.page);

export const getSortColumn = createSelector(
  getModuleState,
  (state) => state.sortColumn,
);

export const getSortDirection = createSelector(
  getModuleState,
  (state) => state.sortDirection,
);

export const getTextFilter = createSelector(
  getModuleState,
  (state) => state.textFilter,
);

export const getUsers = createSelector(getModuleState, (state) => state.users);

export const getSelectedUsers = createSelector(getUsers, (users) =>
  users.filter(({ selected }) => selected),
);

export const getCount = createSelector(getModuleState, (state) => state.count);
