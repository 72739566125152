import { BusinessUnitCode } from './enums';

export const SELECT_NONE = 'none';

export const bigBUs = [
  BusinessUnitCode.Mexico,
  BusinessUnitCode.Colombia,
  BusinessUnitCode.Peru,
  BusinessUnitCode.Ecuador,
];

export const smallBUs = [
  BusinessUnitCode.CAC,
  BusinessUnitCode.HondurasAndSalvador,
];

export const ALL = 'ALL';

export const MAX_INACTIVITY_SECONDS = 15 * 60;
export const SECONDS_BEFORE_INACTIVITY_LOGOUT = 2 * 60;

export const PASSWORD_CHANGE_WARNING_DAYS = 7;
