import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingSpinner from '../../components/LoadingSpinner';
import { userSessionActions } from '../../actions';
import { redirectSSO } from './validateSSO';
import { userSessionSelectors } from '../../selectors';
import styles from './Session.module.scss';
import LoadingPage from '../../components/LoadingSpinner/LoadingPage';

const SessionSSO = () => {
  const hasUser = !!useSelector(userSessionSelectors.getUser);
  const fetching = useSelector(userSessionSelectors.getFetching);
  const dispatch = useDispatch();

  // Fetch user session on startup
  useEffect(() => {
    if (fetching && !hasUser) {
      dispatch(userSessionActions.fetchUserSession());
    } else {
      redirectSSO();
    }
  }, [dispatch, fetching]);

  return (
    // <div className={styles.fullSpinnerContainer}>
    //     <LoadingSpinner />
    // </div>
    <LoadingPage />
  );
};

export default SessionSSO;
