import {
  AppColor,
  ExitMotive,
  InvoluntaryExitMotive,
  KPIField,
} from '../../enums';

export const exitMotiveColors: Record<
  ExitMotive | InvoluntaryExitMotive | 'SUM_OTHERS' | 'UPDATING',
  string
> = {
  [ExitMotive.BasePay]: '#3c3c3c',
  [ExitMotive.BenefitsPackage]: '#6f6f6f',
  [ExitMotive.BonusIncentiveRewards]: '#000089',
  [ExitMotive.CareerAdvancement]: '#0000d6',
  [ExitMotive.CurrentRoleCareerExpectations]: '#5300e8',
  [ExitMotive.CompanyStrategyCulture]: '#d4bff9',
  [ExitMotive.FamilyObligations]: '#680c0c',
  [ExitMotive.LearningAndDevelopment]: '#a01818',
  [ExitMotive.LineManagerRelationship]: '#d64333',
  [ExitMotive.OutsideCareerOpportunity]: '#f7553a',
  [ExitMotive.Relocating]: '#e87d76',
  [ExitMotive.RespectAndInclusion]: '#f27a1e',
  [ExitMotive.ReturnToSchool]: '#faba30',
  [ExitMotive.SeniorLeadership]: '#ffe53b',
  [ExitMotive.ToolsAndResources]: '#ffec75',
  [ExitMotive.WorkingEnvironmentAndSafety]: 'teal',
  [ExitMotive.WorkLifeBalance]: '#fff7c3',
  [InvoluntaryExitMotive.PositiveAlcoholTest]: '#3c3c3c',
  [InvoluntaryExitMotive.PositiveDrugTest]: '#6f6f6f',
  [InvoluntaryExitMotive.Stealing]: '#000089',
  [InvoluntaryExitMotive.FightDuringWorkHours]: '#0000d6',
  [InvoluntaryExitMotive.Absenteeism]: '#5300e8',
  [InvoluntaryExitMotive.NotFollowingSafetyProtocols]: '#d4bff9',
  [InvoluntaryExitMotive.WorkplaceHarassment]: '#680c0c',
  [InvoluntaryExitMotive.SexualHarassment]: '#a01818',
  [InvoluntaryExitMotive.OPR]: '#d64333',
  [InvoluntaryExitMotive.NotFollowingCovidProtocols]: '#f7553a',
  [InvoluntaryExitMotive.NotFollowingSalesProcedures]: '#e87d76',
  [InvoluntaryExitMotive.ShareConfidentialInformation]: '#f27a1e',
  [InvoluntaryExitMotive.LowPerformance]: '#faba30',
  [InvoluntaryExitMotive.BadBehavior]: '#ffe53b',
  [InvoluntaryExitMotive.FailedTrialPeriod]: '#ffec75',
  [InvoluntaryExitMotive.Restructure]: 'teal',
  [InvoluntaryExitMotive.TemporaryContractTermination]: '#fff7c3',
  [InvoluntaryExitMotive.LossOfTrust]: '#3c3c3c',
  [InvoluntaryExitMotive.Insubordination]: '#6f6f6f',
  [InvoluntaryExitMotive.InternalMovement]: '#000089',
  [InvoluntaryExitMotive.InformationManipulation]: '#0000d6',
  SUM_OTHERS: 'lightgrey',
  UPDATING: '#fff7c3',
};

export const KPIColors: Record<KPIField, AppColor> = {
  [KPIField.Completed]: AppColor.BrightPurple,
  [KPIField.InProgress]: AppColor.LightPurple,
  [KPIField.UnAnswered]: AppColor.BarGrey,
  [KPIField.Missed]: AppColor.BarOrange,
};
