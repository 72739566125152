/* eslint-disable import/first */
// organize-imports-ignore
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { enableES5 } from 'immer';

enableES5();

import { ThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import IntlWrapper from './containers/IntlWrapper';
import RouterWrapper from './containers/RouterWrapper';
import './index.scss';
import store from './store';
import theme from './theme';

const render = () => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-var-requires,global-require
  const App = require('./containers/App').default;

  ReactDOM.render(
    // <React.StrictMode>
    <Provider store={store}>
      <IntlWrapper>
        <RouterWrapper>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </RouterWrapper>
      </IntlWrapper>
    </Provider>,
    document.getElementById('root'),
  );
};

render();

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./containers/App', render);
}
