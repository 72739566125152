import { Observable } from 'rxjs';
import { ajax, AjaxResponse } from 'rxjs/ajax';
import { apiURL } from '../config/environment';
import { HttpMethod, Locale, QuestionaryType } from '../enums';
import { serializeQuery } from '../helpers/util-functions';
import { HistoryFilters } from '../types';

const acceptJSONHeader = {
  Accept: 'application/json; charset=UTF-8',
};

export const fetchInterviewsHistory = (
  filters: HistoryFilters,
): Observable<AjaxResponse> =>
  ajax({
    url: `${apiURL}/auth/history?${serializeQuery({
      ...filters,
      uen: filters.uen?.name,
    })}`,
    method: HttpMethod.Get,
    headers: acceptJSONHeader,
    withCredentials: true,
  });

export const downloadInterviews = (
  interviewIds: number[],
  locale: Locale,
): Observable<AjaxResponse> =>
  ajax({
    url: `${apiURL}/auth/interviews/download?${serializeQuery({
      interviewIds,
    })}`,
    method: HttpMethod.Get,
    responseType: 'blob',
    headers: {
      Accept: 'application/zip',
      'Accept-Language': locale,
    },
    withCredentials: true,
  });

export const fetchInterviewDetails = (
  interviewId: number,
): Observable<AjaxResponse> =>
  ajax({
    url: `${apiURL}/auth/questionaries/${interviewId}?${serializeQuery({
      questionaryType: QuestionaryType.Interview,
    })}`,
    method: HttpMethod.Get,
    headers: acceptJSONHeader,
    withCredentials: true,
  });
