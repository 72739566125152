import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  Radio,
  RadioGroup,
  RadioGroupProps,
} from '@material-ui/core';
import { useField } from 'formik';
import React, { FunctionComponent, ReactElement, useMemo } from 'react';
import { SelectOption } from '../../types';

import styles from './FormFields.module.scss';

interface FormRadioFieldProps extends Partial<RadioGroupProps> {
  name: string;
  label?: string;
  options: SelectOption[];
  required?: boolean;
  formClassName?: string;
  disabled?: boolean;
}

const FormRadioField: FunctionComponent<FormRadioFieldProps> = ({
  name,
  label,
  options,
  required = false,
  formClassName,
  disabled = false,
  ...props
}): ReactElement => {
  const [field, meta] = useField<string>(name);

  const radioOptions = useMemo(
    () =>
      options.map((option) => (
        <FormControlLabel
          key={option.value}
          value={option.value}
          label={<span className={styles.inputLabel}>{option.label}</span>}
          control={<Radio color="primary" disabled={disabled} />}
        />
      )),
    [disabled, options],
  );

  const hasError = meta.touched && !!meta.error;

  return (
    <FormControl
      size="small"
      required={required}
      fullWidth
      error={hasError}
      className={formClassName}
    >
      {label && (
        <InputLabel className={styles.inputLabel} shrink={false}>
          {label}
        </InputLabel>
      )}

      <RadioGroup
        name={name}
        value={field.value || ''}
        onChange={field.onChange}
        row
        {...props}
      >
        {radioOptions}
      </RadioGroup>

      <FormHelperText>{hasError ? meta.error : ' '}</FormHelperText>
    </FormControl>
  );
};

export default FormRadioField;
