import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';

import React, { HTMLProps, ReactElement } from 'react';
import { Stepper, StepConnector } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { v4 } from 'uuid';
import { TranslationKey } from '../../i18n/translations';

import styles from './Stepper.module.scss';

function getSteps() {
  return [
    TranslationKey.INT_STEP_1,
    TranslationKey.INT_STEP_2,
    TranslationKey.INT_STEP_3,
  ];
}

interface StepperComponentProps extends HTMLProps<HTMLDivElement> {
  className?: string;
  activeStep?: number;
}

const StepperComponent: React.FC<StepperComponentProps> = ({
  className = '',
  activeStep = 0,
}): ReactElement => {
  const [skipped, setSkipped] = React.useState(new Set<number>());

  const steps = getSteps();

  const isStepSkipped = (step: number) => skipped.has(step);

  return (
    <Stepper
      {...{ activeStep }}
      classes={{ root: styles.stepperComponent }}
      connector={<StepConnector className={styles.stepperConnector} />}
    >
      {steps.map((label, index) => {
        const stepProps: { completed?: boolean } = {};
        const labelProps: { optional?: React.ReactNode } = {};
        const id = v4();
        if (isStepSkipped(index)) {
          stepProps.completed = false;
        }
        return (
          <Step key={id} {...stepProps}>
            <StepLabel
              classes={{
                root: styles.stepperLabel,
                active: styles.stepperLabelActive,
              }}
              StepIconProps={{
                classes: {
                  root: styles.stepperIcon,
                  active: styles.stepperIconActive,
                  completed: styles.stepperIconActive,
                },
              }}
              {...labelProps}
            >
              <FormattedMessage id={label} />
            </StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
};

export default StepperComponent;
