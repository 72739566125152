import {
  Checkbox,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@material-ui/core';
import Container from '@material-ui/core/Container/Container';
import {
  ErrorOutline as ErrorOutlineIcon,
  GetApp as GetAppIcon,
  Search as SearchIcon,
} from '@material-ui/icons';
import { Form, Formik } from 'formik';
import { snakeCase } from 'lodash-es';
import React, {
  ChangeEvent,
  InputHTMLAttributes,
  MouseEvent,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { map } from 'rxjs/operators';
import { historyActions, interviewActions } from '../../actions';
import { historyAPI } from '../../api';
import historyBackground from '../../assets/images/history-bg.png';
import ABIButton from '../../components/ABIButton';
import ContentTitle from '../../components/ContentTitle';
import { FormDateField, FormTextField } from '../../components/FormFields';
import LoadingSpinner from '../../components/LoadingSpinner';
import MainButton from '../../components/MainButton';
import {
  Breakpoint,
  CountryCode,
  HistoryFilterField,
  HistoryInterviewField,
  InterviewStatus,
  SortDirection,
  UserRole,
  VicePresidencyCode,
} from '../../enums';
import {
  isInterviewComplete,
  isInterviewDownloadable,
} from '../../helpers/util-functions';
import { useWindowWidth } from '../../hooks';
import { TranslationKey } from '../../i18n/translations';
import { cursorStatusMap } from '../../maps';
import { historySelectors, userSessionSelectors } from '../../selectors';
import { logger } from '../../services';
import isoCountries from '../../services/isoCountries';
import {
  HistoryColumn,
  HistoryFilterValues,
  HistoryInterview,
} from '../../types';
import TranslateFormikErrors from '../TranslateFormikErrors';
import AdditionalFilters from './AdditionalFilters';
import DateAdjuster from './DateAdjuster';
import styles from './History.module.scss';

const colorStatusMap: Record<InterviewStatus, string> = {
  [InterviewStatus.Registered]: styles.red,
  [InterviewStatus.Interview]: styles.orange,
  [InterviewStatus.Feedback]: styles.orange,
  [InterviewStatus.FeedbackSent]: styles.red,
  [InterviewStatus.FeedbackAccepted]: styles.green,
  [InterviewStatus.FeedbackRejected]: styles.orange,
  [InterviewStatus.FeedbackNotConfirmed]: styles.green,
};

const History = (): ReactElement => {
  const dispatch = useDispatch();
  const intl = useIntl();

  useEffect(() => {
    dispatch(historyActions.setOnlyPending(false));
  }, [dispatch]);

  /**
   * Interview Count
   */
  const [fetchingCount, setFetchingCount] = useState(true);
  const [interviewCount, setInterviewCount] = useState(0);
  useEffect(() => {
    const subscription = historyAPI
      .fetchInterviewsHistory({
        nameOrId: '',
        onlyPending: false,
        page: 0,
        sortDirection: SortDirection.Ascending,
        sortColumn: 'sharp',
      })
      .pipe(
        map(({ response }: { response: { count: number } }) => response.count),
      )
      .subscribe(
        (newCount) => {
          setInterviewCount(newCount);
          setFetchingCount(false);
        },
        (error) => {
          logger.error(error);
          setInterviewCount(0);
          setFetchingCount(false);
        },
      );
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  /**
   * State from store
   */
  const initialized = useSelector(historySelectors.getInitialized);
  const fetching = useSelector(historySelectors.getFetching);
  const downloading = useSelector(historySelectors.getDownloading);
  const filters = useSelector(historySelectors.getFilters);
  const page = useSelector(historySelectors.getPage);
  const sortColumn = useSelector(historySelectors.getSortColumn);
  const sortDirection = useSelector(historySelectors.getSortDirection);
  const interviews = useSelector(historySelectors.getInterviews);
  const count = useSelector(historySelectors.getCount);
  const selectedCount = useSelector(
    historySelectors.getSelectedInterviewsCount,
  );
  const selectableCount = useSelector(
    historySelectors.getSelectableInterviewsCount,
  );
  const userRole = useSelector(userSessionSelectors.getRole);
  const hasAdminFields = [
    UserRole.Admin,
    UserRole.Owner,
    UserRole.CountryOwner,
  ].includes(userRole);

  const windowWidth = useWindowWidth();
  const showMobile = windowWidth < Breakpoint.Medium;

  /**
   * Handle interactions
   */
  const handleSubmit = useCallback(
    (values: HistoryFilterValues) => {
      dispatch(historyActions.setFilters(values));
      dispatch(historyActions.initializeHistory());
    },
    [dispatch],
  );

  const handleAllSelectionChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.currentTarget;
      dispatch(historyActions.setAllInterviewSelection(checked));
    },
    [dispatch],
  );

  const handleInterviewSelectChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      event.stopPropagation();

      const id = Number.parseInt(
        (event.currentTarget.dataset as { id: string }).id,
        10,
      );
      dispatch(historyActions.toggleInterviewSelected(id));
    },
    [dispatch],
  );

  const handleSortClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      const { column } = (event.currentTarget as HTMLButtonElement).dataset as {
        column: string;
      };
      let newSortDirection = sortDirection;
      if (column === sortColumn) {
        newSortDirection =
          sortDirection === SortDirection.Ascending
            ? SortDirection.Descending
            : SortDirection.Ascending;
      } else {
        newSortDirection = sortDirection;
      }

      dispatch(historyActions.setSortColumn(column));
      dispatch(historyActions.setSortDirection(newSortDirection));
    },
    [dispatch, sortColumn, sortDirection],
  );

  const navigate = useNavigate();
  const handleRowClick = useCallback(
    (event: MouseEvent<HTMLTableRowElement>) => {
      if (event.target instanceof HTMLInputElement) return;

      const { id, interviewerId, status, employee } = event.currentTarget
        .dataset as {
        id: string;
        interviewerId: string;
        status: InterviewStatus;
        employee: string;
      };

      if (isInterviewComplete(status)) {
        navigate(`/history/${id}`);
        return;
      }

      if (status === InterviewStatus.Interview) {
        dispatch(
          interviewActions.setInterviewValues(
            Number(id),
            Number(interviewerId),
            employee,
            status,
          ),
        );
        navigate('/interview/instructions');
      } else if (
        status === InterviewStatus.Feedback ||
        status === InterviewStatus.FeedbackRejected
      ) {
        dispatch(
          interviewActions.setInterviewValues(
            Number(id),
            Number(interviewerId),
            employee,
            status,
          ),
        );
        navigate('/interview/pre-feedback');
      }
    },
    [dispatch, navigate],
  );

  const handlePageClick = useCallback(
    (_event, newPage: number) => {
      dispatch(historyActions.setPage(newPage));
    },
    [dispatch],
  );

  const handleDownloadClick = useCallback(() => {
    dispatch(historyActions.downloadInterviewsRequest());
  }, [dispatch]);

  const historyColumns = useMemo(
    (): HistoryColumn[] =>
      [
        {
          field: HistoryInterviewField.Selected,
          headerElement: (
            <Checkbox
              indeterminate={!!selectedCount && selectedCount < selectableCount}
              checked={selectedCount === selectableCount && selectableCount > 0}
              onChange={handleAllSelectionChange}
              color="primary"
            />
          ),
          mobile: true,
          render: (value: boolean, interview: HistoryInterview) =>
            isInterviewDownloadable(interview.status) ? (
              <Checkbox
                checked={value}
                onChange={handleInterviewSelectChange}
                inputProps={
                  {
                    'data-id': interview.id,
                  } as InputHTMLAttributes<HTMLInputElement>
                }
                color="primary"
              />
            ) : null,
        },
        {
          field: HistoryInterviewField.Sharp,
        },
        {
          field: HistoryInterviewField.Employee,
          mobile: true,
          render: (value: string, interview: HistoryInterview) =>
            showMobile ? (
              <>
                <div>
                  {interview[HistoryInterviewField.Sharp]}{' '}
                  {interview[HistoryInterviewField.IsCritical] && (
                    <span className={styles.criticIcon}>
                      <ErrorOutlineIcon color="inherit" />
                    </span>
                  )}
                </div>
                <div className={styles.employeeText}>{value}</div>
              </>
            ) : (
              <>
                {value}{' '}
                {interview[HistoryInterviewField.IsCritical] && (
                  <span className={styles.criticIcon}>
                    <ErrorOutlineIcon color="inherit" />
                  </span>
                )}
              </>
            ),
        },
        {
          field: HistoryInterviewField.LineManager,
        },
        {
          field: HistoryInterviewField.CreatedAt,
          render: (value: Date) => <FormattedDate value={value} />,
        },
        {
          field: HistoryInterviewField.Interviewer,
          admin: true,
        },
        {
          field: HistoryInterviewField.Country,
          admin: true,
          render: (value: CountryCode) =>
            isoCountries.getName(value, intl.locale),
        },
        {
          field: HistoryInterviewField.VicePresidency,
          render: (value: VicePresidencyCode) => (
            <FormattedMessage id={`VP_${value as string}`} />
          ),
        },
        {
          field: HistoryInterviewField.UEN,
        },
        {
          field: HistoryInterviewField.Status,
          mobile: true,
          render: (value: InterviewStatus) => (
            <>
              <FormattedMessage id={`INTERVIEW_STATUS_${value}`} />{' '}
              <div className={`${styles.dot} ${colorStatusMap[value]}`} />
            </>
          ),
        },
      ].filter(({ admin, mobile }) => {
        if (showMobile) {
          return !!mobile;
        }
        return hasAdminFields ? true : !admin;
      }),
    [
      handleAllSelectionChange,
      handleInterviewSelectChange,
      hasAdminFields,
      intl.locale,
      selectableCount,
      selectedCount,
      showMobile,
    ],
  );

  return (
    <div className={styles.container}>
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} className={styles.textContainer}>
            <ContentTitle>
              <FormattedMessage id={TranslationKey.HISTORY} />
            </ContentTitle>

            {!initialized && !fetching && (
              <>
                <h4>
                  {fetchingCount ? (
                    <LoadingSpinner size="2em" />
                  ) : (
                    <FormattedMessage
                      id={TranslationKey.INTERVIEW_COUNT}
                      values={{
                        interviewCount,
                      }}
                    />
                  )}
                </h4>
                <p>
                  <FormattedMessage id={TranslationKey.HISTORY_INSTRUCTIONS} />
                </p>
              </>
            )}

            <Formik
              initialValues={filters}
              onSubmit={handleSubmit}
              autoComplete="off"
            >
              <Form noValidate className={styles.form} autoComplete="off">
                <TranslateFormikErrors />
                <DateAdjuster />

                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={6} sm={3}>
                    <FormDateField
                      name={HistoryFilterField.from}
                      label={intl.formatMessage({
                        id: TranslationKey.FROM,
                      })}
                      maxDate={new Date()}
                    />
                  </Grid>

                  <Grid item xs={6} sm={3}>
                    <FormDateField
                      name={HistoryFilterField.until}
                      label={intl.formatMessage({
                        id: TranslationKey.UNTIL,
                      })}
                      maxDate={new Date()}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4} md={4}>
                    <FormTextField
                      name={HistoryFilterField.nameOrId}
                      label={intl.formatMessage({
                        id: TranslationKey.NAME_OR_ID,
                      })}
                    />
                  </Grid>

                  <Grid item xs={12} sm={2} md={1}>
                    <MainButton type="submit" className={styles.searchButton}>
                      <SearchIcon />
                    </MainButton>
                  </Grid>
                </Grid>
              </Form>
            </Formik>
          </Grid>

          {!initialized && !fetching && (
            <Grid
              container
              item
              xs={12}
              md={3}
              alignItems="center"
              justify="center"
            >
              <img
                src={historyBackground}
                className={styles.picture}
                alt="History background"
              />
            </Grid>
          )}

          {fetching && (
            <Grid
              container
              item
              xs={12}
              alignItems="center"
              justify="center"
              direction="column"
              className={styles.spinner}
            >
              <LoadingSpinner />
            </Grid>
          )}

          {!fetching && initialized && (
            <>
              <Grid item xs={12} className={styles.tableContainer}>
                <TableContainer component={Paper}>
                  <AdditionalFilters />

                  <Table>
                    <TableHead>
                      <TableRow>
                        {!!interviews[0] &&
                          historyColumns.map(({ field, headerElement }) => (
                            <TableCell
                              className={styles.headerCell}
                              key={field}
                              padding={
                                field === HistoryInterviewField.Selected
                                  ? 'checkbox'
                                  : undefined
                              }
                            >
                              {headerElement || (
                                <TableSortLabel
                                  active={field === sortColumn}
                                  direction={sortDirection}
                                  onClick={handleSortClick}
                                  data-column={field}
                                >
                                  <FormattedMessage
                                    id={`HISTORY_COLUMN_${snakeCase(
                                      field,
                                    ).toUpperCase()}`}
                                  />
                                </TableSortLabel>
                              )}
                            </TableCell>
                          ))}
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {interviews.map((interview) => (
                        <TableRow
                          key={interview.id}
                          onClick={handleRowClick}
                          data-id={interview.id}
                          data-interviewer-id={interview.interviewerId}
                          data-status={interview.status}
                          data-employee={interview.employee}
                          style={{
                            cursor: cursorStatusMap[interview.status],
                            height: 43,
                          }}
                        >
                          {historyColumns.map(({ field, render }) => (
                            <TableCell
                              className={styles.bodyCell}
                              key={field}
                              padding={
                                field === HistoryInterviewField.Selected
                                  ? 'checkbox'
                                  : undefined
                              }
                            >
                              {render
                                ? render(interview[field], interview)
                                : interview[field]}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>

                  <TablePagination
                    component="div"
                    count={count}
                    rowsPerPage={10}
                    page={page}
                    onPageChange={handlePageClick}
                    labelRowsPerPage=""
                    labelDisplayedRows={({ from, to, ...rest }) => (
                      <FormattedMessage
                        id={TranslationKey.HISTORY_ROW_DISPLAY}
                        values={{
                          from,
                          to,
                          count: rest.count,
                        }}
                      />
                    )}
                    classes={{
                      select: styles.rowSelect,
                      selectIcon: styles.rowSelect,
                    }}
                  />
                </TableContainer>
              </Grid>

              <Grid
                container
                item
                xs={12}
                direction="row-reverse"
                spacing={1}
                className={styles.buttonContainer}
              >
                <ABIButton
                  disabled={!selectedCount || downloading}
                  onClick={handleDownloadClick}
                >
                  {downloading ? (
                    <CircularProgress
                      size="14px"
                      color="inherit"
                      thickness={6}
                      className={styles.buttonSpinner}
                    />
                  ) : (
                    <GetAppIcon
                      style={{
                        fontSize: 16,
                      }}
                    />
                  )}

                  <FormattedMessage
                    id={
                      downloading
                        ? TranslationKey.GENERATING_DOWNLOAD
                        : TranslationKey.DOWNLOAD
                    }
                  />
                </ABIButton>
              </Grid>
            </>
          )}
        </Grid>
      </Container>
    </div>
  );
};

export default History;
