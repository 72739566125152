import { Grid, makeStyles } from '@material-ui/core';
import React, { FunctionComponent, ReactElement, ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { QuestionType } from '../../enums';
import { TranslationKey } from '../../i18n/translations';
import { SelectOption } from '../../types';
import {
  FormMultipleCheckboxField,
  FormRadioGroupField,
  FormSelectField,
  FormTextField,
} from '../FormFields';
import styles from './Question.module.scss';

const useFormStyle = makeStyles(() => ({
  root: {
    marginTop: 0,
  },
}));

interface QuestionProps {
  fieldPrefix: string;
  type: QuestionType;
  question: string | ReactNode;
  number?: number;
  explanation?: string | ReactNode;
  options?: SelectOption[];
  required?: boolean;
  requiredComments?: boolean;
  withComments?: boolean;
  postExplanation?: string;
  disabled?: boolean;
  loading?: boolean;
  blackDisabled?: boolean;
}

const Question: FunctionComponent<QuestionProps> = React.memo(
  ({
    fieldPrefix,
    type,
    question,
    number,
    explanation,
    options,
    required = false,
    requiredComments = false,
    withComments = true,
    postExplanation = false,
    disabled = false,
    loading = false,
    blackDisabled = false,
  }): ReactElement => {
    const intl = useIntl();
    const formClasses = useFormStyle();

    return (
      <Grid container item className={styles.container}>
        <Grid item xs={12} className={styles.question}>
          <p>
            <span className={styles.questionNumber}>{number}.</span> {question}{' '}
            {required && <span className={styles.required}>*</span>}
          </p>
        </Grid>

        {options && (
          <Grid item xs={10} sm={6} md={4}>
            {[QuestionType.ExitMotive, QuestionType.Boolean].includes(type) && (
              <FormSelectField
                id={`${fieldPrefix}-answer`}
                name={`${fieldPrefix}.answer`}
                label={intl.formatMessage({
                  id: TranslationKey.SELECT,
                })}
                options={options}
                className={`${styles.select} ${
                  blackDisabled ? styles.blackDisabled : ''
                }`}
                required={required}
                disabled={disabled}
                loading={loading}
              />
            )}

            {[QuestionType.ExitMotives, QuestionType.OtherPosition].includes(
              type,
            ) && (
              <FormMultipleCheckboxField
                id={`${fieldPrefix}-answer`}
                name={`${fieldPrefix}.answer`}
                options={options}
                required={required}
                disabled={disabled}
              />
            )}

            {[QuestionType.Recommends, QuestionType.Common].includes(type) && (
              <FormRadioGroupField
                id={`${fieldPrefix}-answer`}
                name={`${fieldPrefix}.answer`}
                options={options}
                required={required}
                disabled={disabled}
              />
            )}
          </Grid>
        )}

        {withComments && (
          <Grid item xs={12}>
            {explanation && type !== QuestionType.OpenText && (
              <p className={styles.explanation}>{explanation}</p>
            )}

            <FormTextField
              id={`${fieldPrefix}-comments`}
              name={`${fieldPrefix}.comments`}
              label=""
              type="textarea"
              multiline
              rows={4}
              className={`${styles.textArea} ${
                blackDisabled ? styles.blackDisabled : ''
              }`}
              classes={formClasses}
              disabled={disabled}
              loading={loading}
              required={requiredComments}
            />
          </Grid>
        )}

        {postExplanation && (
          <Grid item xs={12}>
            <p className={styles.explanation}>{postExplanation}</p>
          </Grid>
        )}
      </Grid>
    );
  },
);

Question.displayName = 'Question';

export default Question;
