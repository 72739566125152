import { ExcelReportType } from '../enums';
import { ReportConfig, ReportData, ReportFilterValues } from '../types';
import * as types from './actionTypes';

export const setFetching = (
  fetching: boolean,
): types.SetReportsFetchingAction => ({
  type: types.SET_REPORTS_FETCHING,
  fetching,
});

export const setReportConfig = (
  config: ReportConfig,
): types.SetReportConfigAction => ({
  type: types.SET_REPORT_CONFIG,
  config,
});

export const fetchReportData = (
  filters: ReportFilterValues,
): types.FetchReportDataAction => ({
  type: types.FETCH_REPORT_DATA,
  filters,
});

export const setReportData = (
  reportData?: ReportData,
): types.SetReportDataAction => ({
  type: types.SET_REPORT_DATA,
  reportData,
});

export const serReportDownloading = (
  downloading: boolean,
): types.SetReportsDownloadingAction => ({
  type: types.SET_REPORTS_DOWNLOADING,
  downloading,
});

export const downloadReportData = (
  filters: ReportFilterValues,
  reportType: ExcelReportType,
): types.DownloadReportDataAction => ({
  type: types.DOWNLOAD_REPORT_DATA,
  filters,
  reportType,
});

export const clearState = (): types.ClearReportsStateAction => ({
  type: types.CLEAR_REPORTS_STATE,
});
