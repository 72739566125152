/* eslint-disable @typescript-eslint/ban-ts-comment */
import { shouldPolyfill } from '@formatjs/intl-relativetimeformat/should-polyfill';

export default async function polyfill(): Promise<void> {
  if (shouldPolyfill()) {
    // Load the polyfill 1st BEFORE loading data
    await import('@formatjs/intl-relativetimeformat/polyfill');
  }

  // @ts-ignore
  if (Intl.RelativeTimeFormat.polyfilled) {
    // @ts-ignore
    await import('@formatjs/intl-relativetimeformat/locale-data/es');
    // @ts-ignore
    await import('@formatjs/intl-relativetimeformat/locale-data/en');
  }
}
