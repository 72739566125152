import { Observable } from 'rxjs';
import { ajax, AjaxResponse } from 'rxjs/ajax';
import { apiURL } from '../config/environment';
import { HttpMethod, WordCloudFilterField } from '../enums';
import { WordCloudFilterValues, WordCloudRecord } from '../types';

const contentTypeJSONHeader = {
  'Content-Type': 'application/json',
};
const acceptJSONHeader = {
  Accept: 'application/json; charset=UTF-8',
};

interface FetchWordCloudDataResponse extends AjaxResponse {
  response: {
    data: WordCloudRecord[];
    count: number;
  };
}
export const fetchWordCloudData = (
  filters: WordCloudFilterValues,
): Observable<FetchWordCloudDataResponse> =>
  ajax({
    url: `${apiURL}/auth/reports/cloud`,
    method: HttpMethod.Post,
    body: {
      ...filters,
      questions: [filters[WordCloudFilterField.Question]],
      uens: filters[WordCloudFilterField.UENs]?.map(({ name }) => name),
    },
    headers: {
      ...contentTypeJSONHeader,
      ...acceptJSONHeader,
    },
    withCredentials: true,
  });
