/**
 * General
 */
export enum HttpMethod {
  Get = 'GET',
  Post = 'POST',
  Put = 'PUT',
  Delete = 'DELETE',
}

export enum Locale {
  Spanish = 'es',
  English = 'en',
}

export enum HTMLInputType {
  Text = 'text',
  Email = 'email',
  Password = 'password',
}

export enum AppColor {
  BrightOrange = '#f7553a',
  BrightPurple = '#6002ee',
  LightPurple = '#9965f4',
  BarGrey = '#b7b7b7',
  BarOrange = '#ff8a39',
  Placeholder = '#9a9a9a',
  MenuGrey = 'rgba(0, 0, 0, 0.59)',
  Error = 'red',
}

export enum Breakpoint {
  Small = 600,
  Medium = 960,
  Large = 1280,
  ExtraLarge = 1920,
}

export enum BooleanSelection {
  Yes = 'YES',
  No = 'NO',
}

export enum SortDirection {
  Ascending = 'asc',
  Descending = 'desc',
}

/**
 * Business Logic
 */
export enum UserRole {
  Admin = 'ADMINISTRATOR',
  CountryOwner = 'COUNTRY_OWNER',
  Owner = 'OWNER',
  Regular = 'REGULAR',
}

export enum BusinessUnitCode {
  CAC = 'CAC',
  Colombia = 'CO',
  Ecuador = 'EC',
  HondurasAndSalvador = 'HN / SV',
  Mexico = 'MEX',
  Peru = 'PE',
}

export enum CountryCode {
  Barbados = 'BB',
  Colombia = 'CO',
  Dominica = 'DM',
  DominicanRepublic = 'DO',
  Ecuador = 'EC',
  Guatemala = 'GT',
  Honduras = 'HN',
  Mexico = 'MX',
  Panama = 'PA',
  Peru = 'PE',
  Salvador = 'SV',
  SaintVincent = 'VC',
}

export enum VicePresidencyCode {
  Sales = 'SALES',
  Finance = 'FINANCE',
  Legal = 'LEGAL / CORPORATE AFFAIRS',
  Logistics = 'LOGISTICS',
  Supply = 'SUPPLY',
  Solutions = 'SOLUTIONS',
  Marketing = 'MARKETING',
  Procurement = 'PROCUREMENT',
  People = 'PEOPLE',
}

export enum OPRRating {
  Newcomer = 'Newcomer',
  UnderPerformer = 'Underperformer',
  Excelling = 'Excelling',
  Mover = 'Mover',
}

export enum Gender {
  Male = 'M',
  Female = 'F',
}

export enum KPIField {
  Completed = 'COMPLETED',
  UnAnswered = 'UNANSWERED',
  InProgress = 'IN_PROGRESS',
  Missed = 'MISSED',
}

export enum EExitType {
  Voluntary = 'VOLUNTARY',
  Involuntary = 'INVOLUNTARY',
}

export enum InterviewStatus {
  // Declined
  Registered = 'REGISTERED',
  // In Progress
  Interview = 'INTERVIEW',
  Feedback = 'FEEDBACK',
  FeedbackSent = 'FEEDBACK_SENT',
  FeedbackRejected = 'FEEDBACK_REJECTED',
  // Completed
  FeedbackAccepted = 'FEEDBACK_ACCEPTED',
  FeedbackNotConfirmed = 'FEEDBACK_NOT_CONFIRMED',
}

export enum AppModules {
  UserSession = 'userSession',
  Interview = 'interview',
  History = 'history',
  Reports = 'reports',
  Admin = 'admin',
  WordCloud = 'wordCloud',
}

/**
 * New Interview
 */
export enum NewInterviewField {
  ApplicationDate = 'applicationDate',
  ID = 'id',
  Name = 'name',
  Sex = 'sex',
  LineManager = 'lineManager',
  LineManagerEmail = 'lineManagerEmail',
  Band = 'band',
  Position = 'position',
  Country = 'country',
  UEN = 'uen',
  Location = 'location',
  BusinessUnit = 'businessUnit',
  VicePresidency = 'vicePresidency',
  HiringDate = 'hiringDate',
  OprRating = 'oprRating',
  ExitDate = 'exitDate',
  ExitType = 'exitType',
  AgreedToInterview = 'agreedToInterview',
}

export enum NewInterviewCreationError {
  InterviewExists = 'INTERVIEW_EXISTS',
  OutOfScope = 'INTERVIEW_OUT_OF_SCOPE',
}

/**
 * User Session
 */
export enum PasswordChangeFields {
  OldPassword = 'oldPassword',
  NewPassword = 'newPassword',
  RepeatNewPassword = 'repeatNewPassword',
}

/**
 * Questionary
 */
export enum QuestionaryType {
  Interview = 'interview',
  Feedback = 'feedback',
}

export enum QuestionType {
  ExitMotive = 'exitMotive',
  ExitMotives = 'exitMotives',
  OtherPosition = 'otherPosition',
  Recommends = 'recommends',
  Common = 'common',
  OpenText = 'openText',
  Additional = 'additional',
  Boolean = 'boolean',
}

export enum QuestionaryFieldPrefix {
  MainExitMotive = 'mainExitMotive',
  AdditionalExitMotives = 'additionalExitMotives',
  NewPositionImprovements = 'newPositionImprovements',
  RecommendsABI = 'recommendsABI',
  ProfessionalGrowth = 'motivationLevel',
  MotivationLevel = 'motivationLevel',
  BurnoutLevel = 'burnoutLevel',
  WorkArea = 'workArea',
  RewardsCommunication = 'rewardsCommunication',
  UnderstoodJobResponsibilities = 'understoodJobResponsibilities',
  TargetAchievementImpact = 'targetAchievementImpact',
  RecognizedByContributions = 'recognizedByContributions',
  TrainingAndDevelopment = 'trainingAndDevelopment',
  CompanyMatters = 'companyMatters',
  NoGrowthDiscrimination = 'noGrowthDiscrimination',
  RespectedOpinions = 'respectedOpinions',
  AllowedSmartRisks = 'allowedSmartRisks',
  LeadershipFeedbackResponse = 'leadershipFeedbackResponse',
  WhatCouldWeHaveDone = 'whatCouldWeHaveDone',
  NewJobDifference = 'newJobDifference',
  NewInstitutionName = 'newInstitutionName',
  NewJobPackage = 'newJobPackage',
  AnythingElse = 'anythingElse',
  Email = 'email',
  PhoneNumber = 'phoneNumber',
  Critical = 'criticStatus',
}

export enum CommonQuestionOption {
  StronglyAgree = 'STRONGLY_AGREE',
  Agree = 'AGREE',
  NeitherAgreeOrDisagree = 'NEITHER_AGREE_OR_DISAGREE',
  Disagree = 'DISAGREE',
  StronglyDisagree = 'STRONGLY_DISAGREE',
}

export enum ExitMotive {
  BasePay = 'BASE_PAY',
  BenefitsPackage = 'BENEFITS_PACKAGE',
  BonusIncentiveRewards = 'BONUS_INCENTIVE_REWARDS',
  CareerAdvancement = 'CAREER_ADVANCEMENT',
  CurrentRoleCareerExpectations = 'CURRENT_ROLE_CAREER_EXPECTATIONS',
  CompanyStrategyCulture = 'COMPANY_STRATEGY_CULTURE',
  FamilyObligations = 'FAMILY_OBLIGATIONS',
  LearningAndDevelopment = 'LEARNING_AND_DEVELOPMENT',
  LineManagerRelationship = 'LINE_MANAGER_RELATIONSHIP',
  OutsideCareerOpportunity = 'OUTSIDE_CAREER_OPPORTUNITY',
  Relocating = 'RELOCATING',
  RespectAndInclusion = 'RESPECT_AND_INCLUSION',
  ReturnToSchool = 'RETURN_TO_SCHOOL',
  SeniorLeadership = 'SENIOR_LEADERSHIP',
  ToolsAndResources = 'TOOLS_AND_RESOURCES',
  WorkingEnvironmentAndSafety = 'WORKING_ENVIRONMENT_AND_SAFETY',
  WorkLifeBalance = 'WORK_LIFE_BALANCE',
}

export enum InvoluntaryExitMotive {
  PositiveAlcoholTest = 'POSITIVE_ALCOHOL_TEST',
  PositiveDrugTest = 'POSITIVE_DRUG_TEST',
  Stealing = 'STEALING',
  FightDuringWorkHours = 'FIGHT_DURING_WORK_HOURS',
  Absenteeism = 'ABSENTEEISM',
  NotFollowingSafetyProtocols = 'NOT_FOLLOWING_SAFETY_PROTOCOLS',
  WorkplaceHarassment = 'WORKPLACE_HARASSMENT',
  SexualHarassment = 'SEXUAL_HARASSMENT',
  OPR = 'OPR',
  NotFollowingCovidProtocols = 'NOT_FOLLOWING_COVID_PROTOCOLS',
  NotFollowingSalesProcedures = 'NOT_FOLLOWING_SALES_PROCEDURES',
  ShareConfidentialInformation = 'SHARE_CONFIDENTIAL_INFORMATION',
  LowPerformance = 'LOW_PERFORMANCE',
  BadBehavior = 'BAD_BEHAVIOR',
  FailedTrialPeriod = 'FAILED_TRIAL_PERIOD',
  Restructure = 'RESTRUCTURE',
  TemporaryContractTermination = 'TEMPORARY_CONTRACT_TERMINATION',
  LossOfTrust = 'LOSS_OF_TRUST',
  Insubordination = 'INSUBORDINATION',
  InternalMovement = 'INTERNAL_MOVEMENT',
  InformationManipulation = 'INFORMATION_MANIPULATION',
}

export enum OtherPositionOption {
  IncreasedSalary = 'INCREASED_SALARY',
  IncreasedResponsibilities = 'INCREASED_RESPONSIBILITIES',
  IncreasedAdvancementOpportunities = 'INCREASED_ADVANCEMENT_OPPORTUNITIES',
  IncreasedPrestige = 'INCREASED_PRESTIGE',
  ImprovedFormalBenefits = 'INCREASED_FORMAL_BENEFITS',
  ImprovedLifestyleBenefits = 'INCREASED_LIFESTYLE_BENEFITS',
  ValuesAlignment = 'VALUES_ALIGNMENT',
  BetterLocation = 'BETTER_LOCATION',
  LessTravelRequired = 'LESS_TRAVEL_REQUIRED',
  MoreTravelOpportunities = 'MORE_TRAVEL_OPPORTUNITIES',
}

export enum RecommendsOption {
  YesDefinitely = 'YES_DEFINITELY',
  YesMaybe = 'YES_MAYBE',
  ProbablyNot = 'PROBABLY_NOT',
  DefinitelyNot = 'DEFINITELY_NOT',
}

/**
 * Feedback
 */
export enum FeedbackFieldPrefix {
  GeneralFeedback = 'generalFeedback',
  ActionPlan = 'actionPlan',
}

/**
 * History
 */
export enum HistoryFilterField {
  from = 'from',
  until = 'until',
  nameOrId = 'nameOrId',
}

export enum HistoryInterviewField {
  Selected = 'selected',
  ID = 'id',
  Sharp = 'sharp',
  Interviewer = 'interviewer',
  InterviewerId = 'interviewerId',
  Employee = 'employee',
  LineManager = 'lineManager',
  CreatedAt = 'createdAt',
  VicePresidency = 'vicePresidency',
  UEN = 'uen',
  Location = 'location',
  Country = 'country',
  Status = 'status',
  IsCritical = 'isCritic',
}

/**
 * Reports
 */
export enum ReportFilterField {
  FilterType = 'filterType',
  ReportType = 'reportType',
  ExitType = 'exitType',
  From = 'from',
  Until = 'until',
  AllZone = 'allZone',
  AllBU = 'allBU',
  BusinessUnits = 'businessUnits',
  Countries = 'countries',
  VicePresidencies = 'vicePresidencies',
  UENs = 'uens',
}

export enum ChartReportType {
  KPI = 'KPI',
  SLA = 'SLA',
}

export enum ReportFilterType {
  Chart = 'CHART',
  Excel = 'EXCEL',
}

export enum ChartType {
  Bar = 'BAR',
  Donut = 'DONUT',
}

export enum ReportTickType {
  BusinessUnit = 'BUSINESS_UNIT',
  Country = 'COUNTRY',
  VicePresidency = 'VICE_PRESIDENCY',
  Location = 'LOCATION',
}

export enum ExcelReportType {
  ExitInterview = 'EXIT_INTERVIEW',
  Withdrawals = 'WITHDRAWALS',
}

/**
 * WordCloud
 */
export enum WordCloudFilterField {
  Question = 'question',
  ExitType = 'exitType',
  From = 'from',
  Until = 'until',
  AllZone = 'allZone',
  AllBU = 'allBU',
  BusinessUnits = 'businessUnits',
  Countries = 'countries',
  VicePresidencies = 'vicePresidencies',
  UENs = 'uens',
}

/**
 * Admin
 */
export enum AdminTask {
  Add = 'ADD',
  Edit = 'EDIT',
  Delete = 'DELETE',
}

export enum AdminTableField {
  Selected = 'selected',
  Id = 'id',
  Interviewer = 'interviewer',
  Email = 'email',
  UEN = 'uen',
  Role = 'role',
  Exists = 'exists',
  DhAccess = 'dh_access',
}
