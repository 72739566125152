import { toast } from 'react-toastify';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, debounceTime, map, mergeMap, tap } from 'rxjs/operators';
import { historyActions } from '../actions';
import {
  AppAction,
  DownloadInterviewsRequestAction,
  DOWNLOAD_INTERVIEWS_REQUEST,
  SetHistoryAdditionalFiltersAction,
  SetHistoryFiltersAction,
  SetHistoryPageAction,
  SetHistorySortColumnAction,
  SetHistorySortDirectionAction,
  SET_HISTORY_ADDITIONAL_FILTERS,
  SET_HISTORY_FILTERS,
  SET_HISTORY_PAGE,
  SET_HISTORY_SORT_COLUMN,
  SET_HISTORY_SORT_DIRECTION,
} from '../actions/actionTypes';
import { historyAPI } from '../api';
import toastConfig from '../config/toast';
import { downloadFile, serializeQuery } from '../helpers/util-functions';
import { historySelectors, userSessionSelectors } from '../selectors';
import { logger } from '../services';
import { AppState, HistoryInterviewResponse } from '../types';

export const interviewHistoryRequestEpic: Epic<
  AppAction,
  AppAction,
  AppState
> = (action$, state$) =>
  action$.pipe(
    ofType<
      AppAction,
      | SetHistoryPageAction
      | SetHistorySortColumnAction
      | SetHistorySortDirectionAction
      | SetHistoryFiltersAction
      | SetHistoryAdditionalFiltersAction
    >(
      SET_HISTORY_PAGE,
      SET_HISTORY_SORT_COLUMN,
      SET_HISTORY_SORT_DIRECTION,
      SET_HISTORY_FILTERS,
      SET_HISTORY_ADDITIONAL_FILTERS,
    ),
    map(() => ({
      page: historySelectors.getPage(state$.value),
      sortColumn: historySelectors.getSortColumn(state$.value),
      sortDirection: historySelectors.getSortDirection(state$.value),
      ...historySelectors.getFilters(state$.value),
      ...historySelectors.getAdditionalFilters(state$.value),
      onlyPending: historySelectors.getOnlyPending(state$.value),
    })),
    debounceTime(500),
    tap((filters) => {
      logger.info(
        'Fetching interviews history with: ',
        `?${serializeQuery(filters)}`,
      );
    }),
    mergeMap((filters) =>
      historyAPI.fetchInterviewsHistory(filters).pipe(
        map(({ response }: { response: HistoryInterviewResponse }) => ({
          count: response.count,
          data: response.data.map(({ createdAt, status, ...rest }) => ({
            ...rest,
            createdAt: new Date(createdAt),
            status,
          })),
        })),
        map(({ data, count }) => {
          logger.info('Fetched interviews history.', data, count);
          return historyActions.setHistoryInterviews(data, count);
        }),
        catchError((error) => {
          logger.error('Error fetching interviews history.', error);
          toast.error('Error fetching interviews history.', toastConfig);
          return of(historyActions.setFetchingHistory(false));
        }),
      ),
    ),
  );

export const downloadInterviewsRequestEpic: Epic<
  AppAction,
  AppAction,
  AppState
> = (action$, state$) =>
  action$.pipe(
    ofType<AppAction, DownloadInterviewsRequestAction>(
      DOWNLOAD_INTERVIEWS_REQUEST,
    ),
    map(() => historySelectors.getSelectedInterviewIds(state$.value)),
    tap((interviewIds) => {
      logger.info(
        'Downloading interviews with: ',
        `?${serializeQuery({
          interviewIds,
        })}`,
      );
    }),
    mergeMap((interviewIds) =>
      historyAPI
        .downloadInterviews(
          interviewIds,
          userSessionSelectors.getLocale(state$.value),
        )
        .pipe(
          map(({ response }) => {
            logger.info(
              `Downloaded interviews with ids: ${interviewIds.join(', ')}`,
              response,
            );
            downloadFile('interviews.zip', response);
            return historyActions.setDownloadingInterviews(false);
          }),
          catchError((error) => {
            logger.error(
              `Error downloading interviews with ids: ${interviewIds.join(
                ', ',
              )}`,
              error,
            );
            toast.error('Error downloading interviews', toastConfig);
            return of(historyActions.setDownloadingInterviews(false));
          }),
        ),
    ),
  );

export default combineEpics(
  interviewHistoryRequestEpic,
  downloadInterviewsRequestEpic,
);
