import { CircularProgress, Grid, IconButton } from '@material-ui/core';
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@material-ui/icons';
import { Form, Formik } from 'formik';
import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { userSessionActions } from '../../actions';
import { FormTextField } from '../../components/FormFields';
import MainButton from '../../components/MainButton';
import { PasswordChangeFields, UserRole } from '../../enums';
import { TranslationKey } from '../../i18n/translations';
import { userSessionSelectors } from '../../selectors';
import { PasswordChangeValues } from '../../types';
import CredentialsError from '../Session/CredentialsError';
import TranslateFormikErrors from '../TranslateFormikErrors';
import styles from './PasswordChange.module.scss';
import getValidationSchema from './schema';

const initValues = {
  credentialsError: false,
  oldPassword: '',
  newPassword: '',
  repeatNewPassword: '',
};

const PasswordChange = (): ReactElement => {
  const intl = useIntl();
  const sending = useSelector(userSessionSelectors.getSending);
  const dispatch = useDispatch();
  const isAdmin = useSelector(userSessionSelectors.getRole) === UserRole.Admin;

  const [showingPassword, setShowingPassword] = useState(false);
  const handleTogglePasswordClick = useCallback(() => {
    setShowingPassword(!showingPassword);
  }, [showingPassword]);

  const handleSubmit = useCallback(
    ({ newPassword, oldPassword }: PasswordChangeValues) => {
      dispatch(
        userSessionActions.passwordRecoveryRequest(newPassword, oldPassword),
      );
    },
    [dispatch],
  );

  const validationSchema = useMemo(() => getValidationSchema(intl, isAdmin), [
    intl,
    isAdmin,
  ]);

  const inputType = !showingPassword ? 'password' : 'text';
  const togglePasswordButton = (
    <IconButton
      className={styles.togglePasswordButton}
      onClick={handleTogglePasswordClick}
    >
      {!showingPassword ? (
        <VisibilityIcon color="inherit" />
      ) : (
        <VisibilityOffIcon color="inherit" />
      )}
    </IconButton>
  );

  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <Formik
          initialValues={initValues}
          initialErrors={{
            ...initValues,
            credentialsError: '',
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          autoComplete="off"
        >
          <Form autoComplete="off">
            <TranslateFormikErrors />
            <CredentialsError />

            <Grid
              className={styles.formContainer}
              container
              justify="center"
              alignContent="center"
            >
              <Grid item xs={12}>
                <FormTextField
                  name={PasswordChangeFields.OldPassword}
                  label={intl.formatMessage({
                    id: TranslationKey.OLD_PASSWORD,
                  })}
                  type={inputType}
                  endElement={togglePasswordButton}
                />
              </Grid>

              <Grid item xs={12}>
                <FormTextField
                  name={PasswordChangeFields.NewPassword}
                  label={intl.formatMessage({
                    id: TranslationKey.NEW_PASSWORD,
                  })}
                  type={inputType}
                  endElement={togglePasswordButton}
                />
              </Grid>

              <Grid item xs={12}>
                <FormTextField
                  name={PasswordChangeFields.RepeatNewPassword}
                  label={intl.formatMessage({
                    id: TranslationKey.REPEAT_NEW_PASSWORD,
                  })}
                  type={inputType}
                  endElement={togglePasswordButton}
                />
              </Grid>

              <Grid item xs={12} className={styles.buttonContainer}>
                <MainButton
                  type="submit"
                  appButtonType="main"
                  appColorType="primary"
                  disabled={sending}
                >
                  {sending && (
                    <CircularProgress
                      size="14px"
                      color="inherit"
                      thickness={6}
                      className={styles.buttonSpinner}
                    />
                  )}
                  <FormattedMessage
                    id={
                      sending
                        ? TranslationKey.SAVING
                        : TranslationKey.SAVE_PASSWORD
                    }
                  />
                </MainButton>
              </Grid>
            </Grid>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default PasswordChange;
