/* eslint-disable no-param-reassign */
/* eslint-disable default-case */

import {
  AppAction,
  CLEAR_USER_SESSION,
  FETCH_USER_SESSION,
  FORGOT_PASSWORD_REQUEST,
  LOGIN_USER_REQUEST,
  NOTIFY_ROUTE_CHANGE,
  PASSWORD_RECOVERY_REQUEST,
  SET_CREDENTIALS_ERROR,
  SET_LOCALE,
  SET_USER_SESSION,
} from '../actions/actionTypes';
import { getLocaleFromBrowser, immerProduce } from '../helpers/util-functions';
import { UserSessionState } from '../types';

const initState: UserSessionState = {
  fetchingSession: true,
  sendingRequest: false,
  locale: getLocaleFromBrowser(),
  user: null,
  credentialsError: false,
};

const userSessionReducer = (
  state = initState,
  action: AppAction,
): UserSessionState =>
  immerProduce(state, (draft) => {
    switch (action.type) {
      case FETCH_USER_SESSION:
        draft.fetchingSession = true;
        return;

      case LOGIN_USER_REQUEST:
      case FORGOT_PASSWORD_REQUEST:
      case PASSWORD_RECOVERY_REQUEST:
        draft.sendingRequest = true;
        draft.credentialsError = false;
        return;

      case SET_CREDENTIALS_ERROR:
        draft.sendingRequest = false;
        draft.credentialsError = action.credentialsError;
        return;

      case SET_USER_SESSION:
        draft.fetchingSession = false;
        draft.sendingRequest = false;
        draft.credentialsError = false;
        draft.user = action.user;
        return;

      case CLEAR_USER_SESSION:
        Object.entries(initState).forEach(([key, value]) => {
          if (key === 'locale') return;
          draft[key] = value;
        });
        draft.fetchingSession = false;
        draft.sendingRequest = false;
        draft.credentialsError = false;
        return;

      case SET_LOCALE:
        draft.locale = action.locale;
        return;

      case NOTIFY_ROUTE_CHANGE:
        draft.sendingRequest = false;
        draft.credentialsError = false;
    }
  });

export default userSessionReducer;
