import { Observable } from 'rxjs';
import { ajax, AjaxResponse } from 'rxjs/ajax';
import { apiURL } from '../config/environment';
import { HttpMethod, Locale, QuestionaryType } from '../enums';
import { serializeQuery } from '../helpers/util-functions';
import { IAnswer, IOption, NewInterviewValues } from '../types';

const contentTypeJSONHeader = {
  'Content-Type': 'application/json',
};
const acceptJSONHeader = {
  Accept: 'application/json; charset=UTF-8',
};

export const fetchCandidateData = (id: number): Observable<AjaxResponse> =>
  ajax({
    url: `${apiURL}/auth/collaborators/${id}`,
    method: HttpMethod.Get,
    headers: acceptJSONHeader,
    withCredentials: true,
  });

interface CreateInterviewResponse extends AjaxResponse {
  response: {
    // eslint-disable-next-line camelcase
    interview_id: number;
  };
}
export const createInterview = (
  newInterview: NewInterviewValues,
): Observable<CreateInterviewResponse> =>
  ajax({
    url: `${apiURL}/auth/interviews`,
    method: HttpMethod.Post,
    body: {
      ...newInterview,
      lineManagerID: (newInterview.lineManager as IOption).id,
      lineManager: (newInterview.lineManager as IOption).name,
      uen: (newInterview.uen as IOption).name,
      location: (newInterview.location as IOption).name,
    },
    headers: {
      ...contentTypeJSONHeader,
      ...acceptJSONHeader,
    },
    withCredentials: true,
  });

export const fetchQuestions = (
  questionaryType: QuestionaryType,
  locale: Locale,
): Observable<AjaxResponse> =>
  ajax({
    url: `${apiURL}/auth/questionary?${serializeQuery({
      questionaryType,
    })}`,
    method: HttpMethod.Get,
    headers: {
      ...acceptJSONHeader,
      'Accept-Language': locale,
    },
    withCredentials: true,
  });

export const fetchStoredQuestions = (
  interviewId: number,
  questionaryType: QuestionaryType,
): Observable<AjaxResponse> =>
  ajax({
    url: `${apiURL}/auth/questionaries/${interviewId}?${serializeQuery({
      questionaryType,
    })}`,
    method: HttpMethod.Get,
    headers: acceptJSONHeader,
    withCredentials: true,
  });

export const savePartialQuestionary = (
  id: number,
  questionary: Record<string, IAnswer>,
): Observable<AjaxResponse> =>
  ajax({
    url: `${apiURL}/auth/questionaries`,
    method: HttpMethod.Put,
    body: {
      id,
      questionary,
    },
    headers: {
      ...contentTypeJSONHeader,
      ...acceptJSONHeader,
    },
    withCredentials: true,
  });

export const saveQuestionary = (
  id: number,
  questionary: Record<string, IAnswer>,
  questionaryType: QuestionaryType,
  locale: Locale,
): Observable<AjaxResponse> =>
  ajax({
    url: `${apiURL}/auth/questionaries`,
    method: HttpMethod.Post,
    body: {
      id,
      questionary,
      questionaryType,
    },
    headers: {
      ...contentTypeJSONHeader,
      ...acceptJSONHeader,
      'Accept-Language': locale,
    },
    withCredentials: true,
  });

export const sendLineManagerFeedback = (
  accepted: boolean,
  interviewId: number,
): Observable<AjaxResponse> =>
  ajax({
    url: `${apiURL}/line-manager-feedback`,
    method: HttpMethod.Post,
    body: {
      accepted,
      interviewId,
    },
    headers: {
      ...contentTypeJSONHeader,
      ...acceptJSONHeader,
    },
    withCredentials: true,
  });
