import {
  AdminTableField,
  AdminTask,
  ExcelReportType,
  InterviewStatus,
  Locale,
  SortDirection,
  UserRole,
} from '../enums';
import {
  AdminUser,
  FeedbackValues,
  HistoryFilterValues,
  HistoryInterview,
  IAdditionalFilters,
  NewInterviewValues,
  QuestionaryValues,
  ReportConfig,
  ReportData,
  ReportFilterValues,
  UserInSession,
  WordCloudConfig,
  WordCloudFilterValues,
  WordCloudRecord,
} from '../types';

/**
 * Common
 */
export const NOTIFY_ROUTE_CHANGE = '@common/NOTIFY_ROUTE_CHANGE';
export interface NotifyRouteChangeAction {
  type: typeof NOTIFY_ROUTE_CHANGE;
  newPath: string;
}

/**
 * User Session
 */
export const FETCH_USER_SESSION = '@userSession/FETCH_USER_SESSION';
export interface FetchUserSessionAction {
  type: typeof FETCH_USER_SESSION;
}

export const LOGIN_USER_REQUEST = '@userSession/LOGIN_USER_REQUEST';
export interface LoginUserRequestAction {
  type: typeof LOGIN_USER_REQUEST;
  code: string;
  state: string;
}

export const SET_CREDENTIALS_ERROR = '@userSession/SET_CREDENTIALS_ERROR';
export interface SetCredentialsErrorAction {
  type: typeof SET_CREDENTIALS_ERROR;
  credentialsError: boolean;
}

export const LOGOUT_USER_REQUEST = '@userSession/LOGOUT_USER_REQUEST';
export interface LogoutUSerRequestAction {
  type: typeof LOGOUT_USER_REQUEST;
}

export const FORGOT_PASSWORD_REQUEST = '@userSession/FORGOT_PASSWORD_REQUEST';
export interface ForgotPasswordRequestAction {
  type: typeof FORGOT_PASSWORD_REQUEST;
  email: string;
}

export const PASSWORD_RECOVERY_REQUEST =
  '@userSession/PASSWORD_RECOVERY_REQUEST';
export interface PasswordRecoveryRequestAction {
  type: typeof PASSWORD_RECOVERY_REQUEST;
  newPassword: string;
  currentPassword?: string;
  token?: string;
}

export const SET_USER_SESSION = '@userSession/SET_USER_SESSION';
export interface SetUserSessionAction {
  type: typeof SET_USER_SESSION;
  user: UserInSession;
}

export const CLEAR_USER_SESSION = '@userSession/CLEAR_USER_SESSION';
export interface ClearUserSessionAction {
  type: typeof CLEAR_USER_SESSION;
}

export const SET_LOCALE = '@userSession/SET_LOCALE';
export interface SetLocaleAction {
  type: typeof SET_LOCALE;
  locale: Locale;
}

/**
 * Interview
 */
export const CREATE_INTERVIEW_REQUEST = '@interview/CREATE_INTERVIEW_REQUEST';
export interface CreateInterviewRequestAction {
  type: typeof CREATE_INTERVIEW_REQUEST;
  newInterview: NewInterviewValues;
}

export const SET_INTERVIEW_VALUES = '@interview/SET_INTERVIEW_VALUES';
export interface SetInterviewValues {
  type: typeof SET_INTERVIEW_VALUES;
  id?: number;
  interviewerId?: number;
  collaborator?: string;
  status?: InterviewStatus;
}

export const SET_SAVING_QUESTIONS = '@interview/SET_SAVING_QUESTIONS';
export interface SetSavingQuestionsAction {
  type: typeof SET_SAVING_QUESTIONS;
  saving: boolean;
}

export const SAVE_QUESTIONARY_REQUEST = '@interview/SAVE_QUESTIONARY_REQUEST';
export interface SaveQuestionaryRequestAction {
  type: typeof SAVE_QUESTIONARY_REQUEST;
  questionary: QuestionaryValues;
}

export const SET_SENDING = '@interview/SET_SENDING';
export interface SetSendingAction {
  type: typeof SET_SENDING;
  sending: boolean;
}

export const SAVE_FEEDBACK_REQUEST = '@interview/SAVE_FEEDBACK_REQUEST';
export interface SaveFeedbackRequestAction {
  type: typeof SAVE_FEEDBACK_REQUEST;
  feedback: FeedbackValues;
}

/**
 * History
 */
export const INITIALIZE_HISTORY = '@history/INITIALIZE_HISTORY';
export interface InitializeHistoryAction {
  type: typeof INITIALIZE_HISTORY;
}

export const SET_HISTORY_PAGE = '@history/SET_HISTORY_PAGE';
export interface SetHistoryPageAction {
  type: typeof SET_HISTORY_PAGE;
  page: number;
}

export const SET_HISTORY_SORT_COLUMN = '@history/SET_HISTORY_SORT_COLUMN ';
export interface SetHistorySortColumnAction {
  type: typeof SET_HISTORY_SORT_COLUMN;
  sortColumn: string;
}

export const SET_HISTORY_SORT_DIRECTION = '@history/SET_HISTORY_SORT_DIRECTION';
export interface SetHistorySortDirectionAction {
  type: typeof SET_HISTORY_SORT_DIRECTION;
  sortDirection: SortDirection;
}

export const SET_HISTORY_FILTERS = '@history/SET_HISTORY_FILTERS';
export interface SetHistoryFiltersAction {
  type: typeof SET_HISTORY_FILTERS;
  filters: HistoryFilterValues;
}

export const SET_HISTORY_ADDITIONAL_FILTERS =
  '@history/SET_HISTORY_ADDITIONAL_FILTERS';
export interface SetHistoryAdditionalFiltersAction {
  type: typeof SET_HISTORY_ADDITIONAL_FILTERS;
  additionalFilters: IAdditionalFilters;
}

export const SET_ONLY_PENDING = '@history/SET_ONLY_PENDING';
export interface SetOnlyPendingAction {
  type: typeof SET_ONLY_PENDING;
  onlyPending: boolean;
}

export const SET_HISTORY_EXPANDED = '@history/SET_HISTORY_EXPANDED';
export interface SetHistoryExpandedAction {
  type: typeof SET_HISTORY_EXPANDED;
  expanded: boolean;
}

export const SET_FETCHING_HISTORY = '@history/SET_FETCHING_HISTORY';
export interface SetFetchingHistoryAction {
  type: typeof SET_FETCHING_HISTORY;
  fetching: boolean;
}

export const SET_HISTORY_INTERVIEWS = '@history/SET_HISTORY_INTERVIEWS';
export interface SetHistoryInterviewsAction {
  type: typeof SET_HISTORY_INTERVIEWS;
  interviews: HistoryInterview[];
  count: number;
}

export const TOGGLE_INTERVIEW_SELECTED = '@history/TOGGLE_INTERVIEW_SELECTED';
export interface ToggleInterviewSelectedAction {
  type: typeof TOGGLE_INTERVIEW_SELECTED;
  id: number;
}

export const SET_ALL_INTERVIEWS_SELECTION =
  '@history/SET_ALL_INTERVIEWS_SELECTION';
export interface SetAllInterviewsSelectionAction {
  type: typeof SET_ALL_INTERVIEWS_SELECTION;
  selected: boolean;
}

export const DOWNLOAD_INTERVIEWS_REQUEST =
  '@history/DOWNLOAD_INTERVIEWS_REQUEST';
export interface DownloadInterviewsRequestAction {
  type: typeof DOWNLOAD_INTERVIEWS_REQUEST;
}

export const SET_DOWNLOADING_INTERVIEWS = '@history/SET_DOWNLOADING_INTERVIEWS';
export interface SetDownloadingInterviewsAction {
  type: typeof SET_DOWNLOADING_INTERVIEWS;
  downloading: boolean;
}

export const CLEAR_HISTORY_STATE = '@history/CLEAR_HISTORY_STATE';
export interface ClearHistoryStateAction {
  type: typeof CLEAR_HISTORY_STATE;
}

/**
 * Reports
 */
export const SET_REPORTS_FETCHING = '@reports/SET_REPORTS_FETCHING';
export interface SetReportsFetchingAction {
  type: typeof SET_REPORTS_FETCHING;
  fetching: boolean;
}

export const SET_REPORT_CONFIG = '@reports/SET_REPORT_CONFIG';
export interface SetReportConfigAction {
  type: typeof SET_REPORT_CONFIG;
  config: ReportConfig;
}

export const FETCH_REPORT_DATA = '@reports/FETCH_REPORT_DATA';
export interface FetchReportDataAction {
  type: typeof FETCH_REPORT_DATA;
  filters: ReportFilterValues;
}

export const SET_REPORT_DATA = '@reports/SET_REPORT_DATA';
export interface SetReportDataAction {
  type: typeof SET_REPORT_DATA;
  reportData?: ReportData;
}

export const SET_REPORTS_DOWNLOADING = '@reports/SET_REPORTS_DOWNLOADING';
export interface SetReportsDownloadingAction {
  type: typeof SET_REPORTS_DOWNLOADING;
  downloading: boolean;
}

export const DOWNLOAD_REPORT_DATA = '@reports/DOWNLOAD_REPORT_DATA';
export interface DownloadReportDataAction {
  type: typeof DOWNLOAD_REPORT_DATA;
  filters: ReportFilterValues;
  reportType: ExcelReportType;
}

export const CLEAR_REPORTS_STATE = '@reports/CLEAR_REPORTS_STATE';
export interface ClearReportsStateAction {
  type: typeof CLEAR_REPORTS_STATE;
}

/**
 * WordCloud
 */
export const SET_WORD_CLOUD_FETCHING = '@wordCloud/SET_WORD_CLOUD_FETCHING';
export interface SetWordCloudFetchingAction {
  type: typeof SET_WORD_CLOUD_FETCHING;
  fetching: boolean;
}

export const SET_WORD_CLOUD_CONFIG = '@wordCloud/SET_WORD_CLOUD_CONFIG';
export interface SetWordCloudConfigAction {
  type: typeof SET_WORD_CLOUD_CONFIG;
  config: WordCloudConfig;
}

export const FETCH_WORD_CLOUD_DATA = '@wordCloud/FETCH_WORD_CLOUD_DATA';
export interface FetchWordCloudDataAction {
  type: typeof FETCH_WORD_CLOUD_DATA;
  filters: WordCloudFilterValues;
}

export const SET_WORD_CLOUD_DATA = '@wordCloud/SET_WORD_CLOUD_DATA';
export interface SetWordCloudDataAction {
  type: typeof SET_WORD_CLOUD_DATA;
  data: WordCloudRecord[];
  count: number;
}

export const CLEAR_WORD_CLOUD_STATE = '@wordCloud/CLEAR_WORD_CLOUD_STATE';
export interface ClearWordCloudStateAction {
  type: typeof CLEAR_WORD_CLOUD_STATE;
}

/**
 * Admin
 */
export const SET_ADMIN_TASK = '@admin/SET_ADMIN_TASK';
export interface SetAdminTaskAction {
  type: typeof SET_ADMIN_TASK;
  task: AdminTask;
}

export const SET_ADMIN_PAGE = '@admin/SET_ADMIN_PAGE';
export interface SetAdminPageAction {
  type: typeof SET_ADMIN_PAGE;
  page: number;
}

export const SET_ADMIN_SORT_COLUMN = '@admin/SET_ADMIN_SORT_COLUMN';
export interface SetAdminSortColumnAction {
  type: typeof SET_ADMIN_SORT_COLUMN;
  sortColumn: AdminTableField;
}

export const SET_ADMIN_SORT_DIRECTION = '@admin/SET_ADMIN_SORT_DIRECTION';
export interface SetAdminSortDirectionAction {
  type: typeof SET_ADMIN_SORT_DIRECTION;
  sortDirection: SortDirection;
}

export const SET_ADMIN_TEXT_FILTER = '@admin/SET_ADMIN_TEXT_FILTER';
export interface SetAdminTextFilterAction {
  type: typeof SET_ADMIN_TEXT_FILTER;
  textFilter: string;
}

export const SET_ADMIN_FETCHING = '@admin/SET_ADMIN_FETCHING';
export interface SetAdminFetchingAction {
  type: typeof SET_ADMIN_FETCHING;
  fetching: boolean;
}

export const SET_ADMIN_USERS = '@admin/SET_ADMIN_USERS';
export interface SetAdminUsersAction {
  type: typeof SET_ADMIN_USERS;
  users: AdminUser[];
  count: number;
}

export const SET_ADMIN_SUBMITTING = '@admin/SET_ADMIN_SUBMITTING';
export interface SetAdminSubmittingAction {
  type: typeof SET_ADMIN_SUBMITTING;
  submitting: boolean;
}

export const TOGGLE_USER_SELECTED = '@admin/TOGGLE_USER_SELECTED';
export interface ToggleUserSelectedAction {
  type: typeof TOGGLE_USER_SELECTED;
  id: number;
}

export const SET_ADMIN_USER_EMAIL = '@admin/SET_ADMIN_USER_EMAIL';
export interface SetAdminUserEmail {
  type: typeof SET_ADMIN_USER_EMAIL;
  id: number;
  email: string;
}

export const SET_ADMIN_USER_ROLE = '@admin/SET_ADMIN_USER_ROLE';
export interface SetAdminUserRoleAction {
  type: typeof SET_ADMIN_USER_ROLE;
  id: number;
  role: UserRole;
}

export const SET_ADMIN_USER_DH_ACCESS = '@admin/SET_ADMIN_USER_DH_ACCESS';
export interface SetAdminUserDhAccess {
  type: typeof SET_ADMIN_USER_DH_ACCESS;
  id: number;
  // eslint-disable-next-line camelcase
  dh_access: boolean;
}

export const SET_ALL_USERS_SELECTED = '@admin/SET_ALL_USERS_SELECTED';
export interface SetAllUsersSelectedAction {
  type: typeof SET_ALL_USERS_SELECTED;
  selected: boolean;
}

export const USER_CHANGES_REQUEST = '@admin/USER_CHANGES_REQUEST';
export interface UserChangesRequestAction {
  type: typeof USER_CHANGES_REQUEST;
}

export const CLEAR_ADMIN_STATE = '@admin/CLEAR_ADMIN_STATE';
export interface ClearAdminStateAction {
  type: typeof CLEAR_ADMIN_STATE;
}

export type AppAction =
  // Common
  | NotifyRouteChangeAction
  // User Session
  | FetchUserSessionAction
  | LoginUserRequestAction
  | SetCredentialsErrorAction
  | LogoutUSerRequestAction
  | ForgotPasswordRequestAction
  | PasswordRecoveryRequestAction
  | SetUserSessionAction
  | ClearUserSessionAction
  | SetLocaleAction
  // Interview
  | CreateInterviewRequestAction
  | SetInterviewValues
  | SetSavingQuestionsAction
  | SaveQuestionaryRequestAction
  | SetSendingAction
  | SaveFeedbackRequestAction
  // History
  | InitializeHistoryAction
  | SetHistoryPageAction
  | SetHistorySortColumnAction
  | SetHistorySortDirectionAction
  | SetHistoryFiltersAction
  | SetHistoryAdditionalFiltersAction
  | SetOnlyPendingAction
  | SetHistoryExpandedAction
  | SetFetchingHistoryAction
  | SetHistoryInterviewsAction
  | ToggleInterviewSelectedAction
  | SetAllInterviewsSelectionAction
  | DownloadInterviewsRequestAction
  | SetDownloadingInterviewsAction
  | ClearHistoryStateAction
  // Reports
  | SetReportsFetchingAction
  | SetReportConfigAction
  | FetchReportDataAction
  | SetReportDataAction
  | SetReportsDownloadingAction
  | DownloadReportDataAction
  | ClearReportsStateAction
  // WordCloud
  | SetWordCloudFetchingAction
  | SetWordCloudConfigAction
  | FetchWordCloudDataAction
  | SetWordCloudDataAction
  | ClearWordCloudStateAction
  // Admin
  | SetAdminTaskAction
  | SetAdminPageAction
  | SetAdminSortColumnAction
  | SetAdminSortDirectionAction
  | SetAdminTextFilterAction
  | SetAdminFetchingAction
  | SetAdminUsersAction
  | SetAdminSubmittingAction
  | ToggleUserSelectedAction
  | SetAdminUserEmail
  | SetAdminUserRoleAction
  | SetAllUsersSelectedAction
  | UserChangesRequestAction
  | ClearAdminStateAction
  | SetAdminUserDhAccess;
