import {
  Checkbox,
  CircularProgress,
  Grid,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
} from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { Search as SearchIcon } from '@material-ui/icons';
import { filter, pick, snakeCase } from 'lodash-es';
import React, {
  ChangeEvent,
  InputHTMLAttributes,
  KeyboardEvent,
  MouseEvent,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { adminActions } from '../../actions';
import { getUserReport } from '../../api/admin';
import ABIButton from '../../components/ABIButton';
import ContentTitle from '../../components/ContentTitle';
import LoadingSpinner from '../../components/LoadingSpinner';
import MainButton from '../../components/MainButton';
import toastConfig from '../../config/toast';
import {
  AdminTableField,
  AdminTask,
  Breakpoint,
  Locale,
  SortDirection,
  UserRole,
} from '../../enums';
import { downloadFile } from '../../helpers/util-functions';
import { useWindowWidth } from '../../hooks';
import { TranslationKey } from '../../i18n/translations';
import { adminSelectors } from '../../selectors';
import { logger } from '../../services';
import styles from './Admin.module.scss';

const Admin = (): ReactElement => {
  const intl = useIntl();
  const dispatch = useDispatch();

  // Cleanup state on un-mounting
  useEffect(
    () => () => {
      dispatch(adminActions.clearState());
    },
    [dispatch],
  );

  /**
   * State from store
   */
  const fetching = useSelector(adminSelectors.getFetching);
  const submitting = useSelector(adminSelectors.getSubmitting);
  const task = useSelector(adminSelectors.getTask);
  const page = useSelector(adminSelectors.getPage);
  const sortColumn = useSelector(adminSelectors.getSortColumn);
  const sortDirection = useSelector(adminSelectors.getSortDirection);
  const users = useSelector(adminSelectors.getUsers);
  const count = useSelector(adminSelectors.getCount);

  /**
   * Handle Interactions
   */
  const handleSearchKeyDown = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        const newValue = (event.target as HTMLInputElement).value;
        dispatch(adminActions.setTextFilter(newValue));
      }
    },
    [dispatch],
  );

  const handleAllSelectionChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.currentTarget;
      dispatch(adminActions.setAllUsersSelected(checked));
    },
    [dispatch],
  );

  const handleUserSelectChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      event.stopPropagation();

      const id = Number.parseInt(
        (event.currentTarget.dataset as { id: string }).id,
        10,
      );
      dispatch(adminActions.toggleUserSelected(id));
    },
    [dispatch],
  );

  const handleSortClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      const { column } = (event.currentTarget as HTMLButtonElement).dataset as {
        column: AdminTableField;
      };
      if (column === AdminTableField.Selected) return;

      let newSortDirection = sortDirection;
      if (column === sortColumn) {
        newSortDirection =
          sortDirection === SortDirection.Ascending
            ? SortDirection.Descending
            : SortDirection.Ascending;
      } else {
        newSortDirection = sortDirection;
      }

      dispatch(adminActions.setSortColumn(column));
      dispatch(adminActions.setSortDirection(newSortDirection));
    },
    [dispatch, sortColumn, sortDirection],
  );

  const handlePageClick = useCallback(
    (_event, newPage: number) => {
      dispatch(adminActions.setPage(newPage));
    },
    [dispatch],
  );

  const handleEmailChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const userId = Number(event.target.dataset.id);
      const newEmail = event.target.value;
      dispatch(adminActions.setUserEmail(userId, newEmail));
    },
    [dispatch],
  );

  const handleRoleChange = useCallback(
    (event: ChangeEvent<{ value: unknown }>) => {
      const { id, role } = JSON.parse(event.target.value as string) as {
        id: number;
        role: UserRole;
      };
      dispatch(adminActions.setUserRole(id, role));
    },
    [dispatch],
  );

  const handleRequestClick = useCallback(() => {
    const emailInputs = Array.from(
      document.getElementsByClassName('user-email'),
    ) as HTMLInputElement[];
    const hasInvalidEmail = emailInputs.some((input) => !input.checkValidity());
    if (hasInvalidEmail) {
      toast.error(
        intl.formatMessage(
          {
            id: TranslationKey.ADMIN_INVALID_EMAIL,
          },
          {
            action: intl.formatMessage({
              id: `ADMIN_${task}`,
            }),
          },
        ),
        toastConfig,
      );
      return;
    }
    dispatch(adminActions.userChangesRequest());
  }, [dispatch, intl, task]);

  const [fetchingReport, setFetchingReport] = useState(false);
  const handleDownloadReportClick = useCallback(() => {
    setFetchingReport(true);
    getUserReport(intl.locale as Locale).subscribe(
      ({ response }) => {
        setFetchingReport(false);
        downloadFile('user-report.xlsx', response);
      },
      (error) => {
        setFetchingReport(false);
        logger.error(error);
        toast.error('', toastConfig);
      },
      () => {
        setFetchingReport(false);
      },
    );
  }, [intl.locale]);

  const windowWidth = useWindowWidth();
  const showTitle = windowWidth > Breakpoint.Small;

  const handleOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const userId = Number(event.target.value);
      const newDhAccess = event.target.checked;
      dispatch(adminActions.setUserDHAccess(userId, newDhAccess));
    },
    [dispatch],
  );

  return (
    <>
      {showTitle && (
        <Grid item xs={12} className={styles.textContainer}>
          <ContentTitle>
            <FormattedMessage id={TranslationKey.USERS} />
          </ContentTitle>
        </Grid>
      )}

      <Grid item xs={12} sm={2} className={styles.menuContainer}>
        {Object.values(AdminTask).map((tab) => (
          <div
            key={tab}
            role="button"
            className={`${styles.menuItem} ${
              task === tab ? styles.menuItemSelected : ''
            }`}
            onClick={() => {
              dispatch(adminActions.setTask(tab));
            }}
            onKeyDown={(event) => {
              if (event.key === ' ' || event.key === 'Enter') {
                dispatch(adminActions.setTask(tab));
              }
            }}
            tabIndex={-1}
          >
            <FormattedMessage id={tab} />
          </div>
        ))}
      </Grid>

      <Grid item xs={12} sm={10} className={styles.tablePanel}>
        <div
          style={{
            minHeight: 50,
            width: '90%',
          }}
        >
          <ABIButton
            buttonType="cancel"
            style={
              {
                float: 'right',
                width: '200px',
              } as CSSProperties
            }
            onClick={handleDownloadReportClick}
            disabled={fetchingReport}
          >
            {fetchingReport && (
              <CircularProgress
                size="14px"
                color="inherit"
                thickness={6}
                className={styles.buttonSpinner}
              />
            )}
            <FormattedMessage id={TranslationKey.DOWNLOAD_REPORT} />
          </ABIButton>
        </div>
        <div className={styles.searchBoxContainer}>
          <p>
            <FormattedMessage
              id={TranslationKey.ADMIN_SEARCH_INSTRUCTIONS}
              values={{
                action: intl.formatMessage({
                  id: `ADMIN_${task}`,
                }),
              }}
            />
          </p>
          <TextField
            className={styles.searchBar}
            onKeyDown={handleSearchKeyDown}
            label=""
            placeholder={intl.formatMessage({
              id: TranslationKey.SEARCH,
            })}
            size="small"
            variant="outlined"
            fullWidth
            disabled={submitting}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" className={styles.searchIcon}>
                  <SearchIcon color="inherit" />
                </InputAdornment>
              ),
            }}
          />
        </div>

        {fetching && (
          <div className={styles.spinnerContainer}>
            <LoadingSpinner />
          </div>
        )}

        <div className={styles.tableContainer}>
          {!!users.length && !fetching && (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    {filter(
                      Object.values(AdminTableField),
                      (field) => field !== AdminTableField.Exists,
                    ).map((field) => (
                      <TableCell
                        key={field}
                        className={styles.headerCell}
                        padding={
                          field === AdminTableField.Selected
                            ? 'checkbox'
                            : undefined
                        }
                        width={field === AdminTableField.Role ? 150 : undefined}
                      >
                        {field === AdminTableField.Selected ? (
                          <Checkbox
                            indeterminate={
                              !!users.length &&
                              users.some(({ selected }) => selected) &&
                              users.filter(({ selected }) => selected).length <
                                users.length
                            }
                            checked={
                              !!users.length &&
                              users.every(({ selected }) => selected)
                            }
                            disabled={submitting}
                            onChange={handleAllSelectionChange}
                            color="primary"
                          />
                        ) : (
                          <TableSortLabel
                            active={field === sortColumn}
                            direction={sortDirection}
                            onClick={handleSortClick}
                            data-column={field}
                          >
                            <FormattedMessage
                              id={`ADMIN_COLUMN_${snakeCase(
                                field,
                              ).toUpperCase()}`}
                            />
                          </TableSortLabel>
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {users.map((user) => (
                    <TableRow
                      key={user.id}
                      style={{
                        height: 43,
                      }}
                    >
                      {Object.entries(
                        pick(
                          user,
                          filter(
                            Object.values(AdminTableField),
                            (field) => field !== AdminTableField.Exists,
                          ),
                        ),
                      ).map(([field, value]) => (
                        <TableCell
                          key={field}
                          className={styles.bodyCell}
                          padding={
                            field === AdminTableField.Selected
                              ? 'checkbox'
                              : undefined
                          }
                          width={
                            field === AdminTableField.Role ? 150 : undefined
                          }
                        >
                          {field === AdminTableField.Selected && !user.exists && (
                            <Checkbox
                              checked={value as boolean}
                              onChange={handleUserSelectChange}
                              disabled={submitting}
                              inputProps={
                                {
                                  'data-id': user.id,
                                } as InputHTMLAttributes<HTMLInputElement>
                              }
                              color="primary"
                            />
                          )}

                          {field === AdminTableField.Email &&
                            (user.selected ? (
                              <TextField
                                type="email"
                                value={user.email}
                                onChange={handleEmailChange}
                                fullWidth
                                inputProps={{
                                  className: 'user-email',
                                  'data-id': user.id,
                                }}
                              />
                            ) : (
                              user.email
                            ))}

                          {field === AdminTableField.Role &&
                            (user.selected ? (
                              <Select
                                value={JSON.stringify({
                                  id: user.id,
                                  role: value,
                                })}
                                onChange={handleRoleChange}
                              >
                                {Object.values(UserRole).map((role) => (
                                  <MenuItem
                                    key={role}
                                    value={JSON.stringify({
                                      id: user.id,
                                      role,
                                    })}
                                  >
                                    <FormattedMessage
                                      id={`USER_ROLE_${role}`}
                                    />
                                  </MenuItem>
                                ))}
                              </Select>
                            ) : (
                              <FormattedMessage
                                id={
                                  !user.exists
                                    ? `USER_ROLE_${user.role}`
                                    : TranslationKey.EXISTING_USER
                                }
                              />
                            ))}

                          {field === AdminTableField.DhAccess &&
                            (user.selected ? (
                              <Checkbox
                                value={user.id}
                                checked={Boolean(user.dh_access)}
                                onChange={(event) => handleOnChange(event)}
                                color="primary"
                              />
                            ) : (
                              <Checkbox
                                checked={Boolean(user.dh_access)}
                                disabled
                                color="primary"
                              />
                            ))}

                          {![
                            AdminTableField.Selected,
                            AdminTableField.Email,
                            AdminTableField.Role,
                            AdminTableField.DhAccess,
                          ].includes(field as AdminTableField) && value}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>

              <TablePagination
                component="div"
                count={count}
                rowsPerPage={10}
                page={page}
                onPageChange={handlePageClick}
                labelRowsPerPage=""
                labelDisplayedRows={({ from, to, ...rest }) => (
                  <FormattedMessage
                    id={TranslationKey.HISTORY_ROW_DISPLAY}
                    values={{
                      from,
                      to,
                      count: rest.count,
                    }}
                  />
                )}
                classes={{
                  select: styles.rowSelect,
                  selectIcon: styles.rowSelect,
                  caption: styles.tableCaption,
                }}
              />
            </TableContainer>
          )}
        </div>

        {!!users.length && !fetching && (
          <div className={styles.buttonContainer}>
            <ABIButton
              disabled={!users.some(({ selected }) => selected) || submitting}
              onClick={handleRequestClick}
            >
              {submitting && (
                <CircularProgress
                  size="14px"
                  color="inherit"
                  thickness={6}
                  className={styles.buttonSpinner}
                />
              )}
              <FormattedMessage id={submitting ? `SUBMITTING_${task}` : task} />
            </ABIButton>
          </div>
        )}
      </Grid>
    </>
  );
};

export default Admin;
