import { Observable } from 'rxjs';
import { ajax, AjaxResponse } from 'rxjs/ajax';
import { apiURL } from '../config/environment';
import { HttpMethod } from '../enums';

const contentTypeJSONHeader = {
  'Content-Type': 'application/json',
};
const acceptJSONHeader = {
  Accept: 'application/json; charset=UTF-8',
};

export const fetchUserSession = (): Observable<AjaxResponse> =>
  ajax({
    url: `${apiURL}/user-session`,
    method: HttpMethod.Get,
    headers: acceptJSONHeader,
    withCredentials: true,
  });

export const refreshUserSession = (): Observable<AjaxResponse> =>
  ajax({
    url: `${apiURL}/refresh`,
    method: HttpMethod.Post,
    headers: acceptJSONHeader,
    withCredentials: true,
  });

export const loginUser = (
  code: string,
  state: string,
): Observable<AjaxResponse> =>
  ajax({
    url: `${apiURL}/sign-in`,
    method: HttpMethod.Post,
    body: {
      code,
      state,
    },
    headers: {
      ...contentTypeJSONHeader,
      ...acceptJSONHeader,
    },
    withCredentials: true,
  });

export const logoutUser = (): Observable<AjaxResponse> =>
  ajax({
    url: `${apiURL}/auth/logout`,
    method: HttpMethod.Get,
    headers: acceptJSONHeader,
    withCredentials: true,
  });

export const forgotPassword = (email: string): Observable<AjaxResponse> =>
  ajax({
    url: `${apiURL}/forgot-password`,
    method: HttpMethod.Post,
    body: {
      email,
    },
    headers: {
      ...contentTypeJSONHeader,
      ...acceptJSONHeader,
    },
    withCredentials: true,
  });

export const verifyPasswordRecoveryToken = (
  token: string,
): Observable<AjaxResponse> =>
  ajax({
    url: `${apiURL}/verify-password-token?token=${token}`,
    method: HttpMethod.Get,
    headers: acceptJSONHeader,
    withCredentials: true,
  });

export const recoverPassword = (
  newPassword: string,
  currentPassword?: string,
  token?: string,
): Observable<AjaxResponse> =>
  ajax({
    url: `${apiURL}/session/password-recovery`,
    method: HttpMethod.Put,
    body: {
      newPassword,
      currentPassword,
      token,
    },
    headers: {
      ...contentTypeJSONHeader,
      ...acceptJSONHeader,
    },
    withCredentials: true,
  });
