import React, { FunctionComponent, ReactElement } from 'react';
import { Route, Routes } from 'react-router';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BannerMorePeople from '../components/BannerMorePeople';
import External from '../components/External';
import Help from '../components/Help';
import { NoticeConfirmed, NoticeDeclined } from '../components/Interview';
import { useUnauthorizedSessionIntercept } from '../hooks';
import Dashboard from './Dashboard';
import Session from './Session';
import Callback from './Session/callback';
import SessionSSO from './Session/SessionSSO';

const App: FunctionComponent = (): ReactElement => {
  useUnauthorizedSessionIntercept();

  return (
    <>
      <BannerMorePeople />
      <Routes>
        <Route path="session/sso" element={<SessionSSO />} />
        <Route path="auth/callback" element={<Callback />} />
        <Route path="*" element={<Dashboard />} />
        <Route path="session/*" element={<Session />} />

        <Route path="external/*" element={<External />}>
          <Route path="notice-confirmed" element={<NoticeConfirmed />} />
          <Route path="notice-declined" element={<NoticeDeclined />} />
          <Route path="help" element={<Help />} />
        </Route>
      </Routes>

      <ToastContainer />
    </>
  );
};

export default App;
