import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { HistoryFilterField } from '../../enums';
import { HistoryFilterValues } from '../../types';

/**
 * Don't allow incoherent date values like
 *
 */
const DateAdjuster = (): null => {
  const formikContext = useFormikContext<HistoryFilterValues>();
  const { values } = formikContext;
  const setFieldValue = formikContext.setFieldValue.bind(formikContext);

  /**
   * Handle `from` after `until`
   */
  useEffect(() => {
    if (!values.from || !values.until) return;

    if (values.from > values.until) {
      setFieldValue(HistoryFilterField.until, values.from);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.from, setFieldValue]);

  /**
   * Handle `until` before `from`
   */
  useEffect(() => {
    if (!values.from || !values.until) return;

    if (values.until < values.from) {
      setFieldValue(HistoryFilterField.from, values.until);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.until, setFieldValue]);

  return null;
};

export default DateAdjuster;
