import { createSelector } from 'reselect';
import { AppModules } from '../enums';
import { AppState, InterviewState, UserSessionState } from '../types';

const getUserSessionModule = (state: AppState): UserSessionState =>
  state[AppModules.UserSession];
const getInterviewModule = (state: AppState): InterviewState =>
  state[AppModules.Interview];

const getUserId = createSelector(getUserSessionModule, ({ user }) => user?.id);
const getInterviewerId = createSelector(
  getInterviewModule,
  (interview) => interview?.interviewerId,
);

export const canEditInterview = createSelector(
  [getUserId, getInterviewerId],
  (userId, interviewerId) => !!interviewerId && interviewerId === userId,
);
