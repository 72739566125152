import {
  Checkbox,
  CheckboxProps,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from '@material-ui/core';
import { useField } from 'formik';
import React, {
  ChangeEvent,
  FunctionComponent,
  ReactElement,
  useCallback,
} from 'react';

interface FormCheckboxFieldProps extends Partial<CheckboxProps> {
  name: string;
  label?: string;
  onValueChange?: (checked: boolean, name: string) => void;
}

const FormCheckboxField: FunctionComponent<FormCheckboxFieldProps> = ({
  name,
  label,
  onValueChange,
}): ReactElement => {
  const [field, meta] = useField<boolean>(name);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      field.onChange(event);
      if (onValueChange) onValueChange(!!event.target.checked, name);
    },
    [field, name, onValueChange],
  );

  const hasError = meta.touched && !!meta.error;

  return (
    <FormControl>
      <FormControlLabel
        label={label}
        labelPlacement="end"
        control={
          <Checkbox
            color="primary"
            {...field}
            checked={!!field.value}
            onChange={handleChange}
          />
        }
      />

      <FormHelperText error={hasError}>
        {hasError ? meta.error : ' '}
      </FormHelperText>
    </FormControl>
  );
};

export default FormCheckboxField;
