import { VideoLibrary } from '@material-ui/icons';
import { Container } from '@material-ui/core';
import { startCase } from 'lodash-es';
import React, { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { ReactComponent as InterviewIcon } from '../../assets/images/interview-icon.svg';
import { ReactComponent as ReportIcon } from '../../assets/images/report-icon.svg';
import wordCloudImg from '../../assets/images/word-cloud.svg';
import PictureLink from '../../components/PictureLink';
import { TranslationKey } from '../../i18n/translations';
import { userSessionSelectors } from '../../selectors';
import { ReactComponent as InterviewLogo } from '../../assets/General/IconoEntrevista_Home.svg';
import { ReactComponent as ReportsLogo } from '../../assets/General/IconoReportes_Home.svg';
import { ReactComponent as CloudLogo } from '../../assets/General/IconoNube_Home.svg';
import { ReactComponent as TutorialLogo } from '../../assets/General/IconoTutoriales_Home.svg';
import styles from './Home.module.scss';

const Home = (): ReactElement => {
  const user = useSelector(userSessionSelectors.getUser);
  const firstName = startCase(
    user?.name?.split(' ').slice(0, 2).join(' ') || '',
  );
  const tutorialsLink =
    'https://anheuserbuschinbev.sharepoint.com/sites/somos_MAZ/simplificaMAZ/SitePages/Exit-Interview.aspx';

  return (
    <div className={styles.container}>
      <div className={styles.text}>
        <Container>
          <h1>
            <FormattedMessage
              id={TranslationKey.GREETING}
              values={{
                firstName,
              }}
            />
          </h1>

          <h2>
            <FormattedMessage id={TranslationKey.INIT_QUESTION} />
          </h2>
        </Container>
      </div>

      <Container className={styles.gridContainer}>
        <div className={styles.content}>
          <div className={styles.buttonsContainer}>
            <PictureLink
              icon={<InterviewLogo />}
              label={<FormattedMessage id={TranslationKey.APPLY_INTERVIEW} />}
              to="/interview/new"
            />
            <PictureLink
              icon={<ReportsLogo />}
              label={<FormattedMessage id={TranslationKey.VIEW_REPORT} />}
              to="/reports"
            />
            <PictureLink
              icon={<CloudLogo />}
              label={<FormattedMessage id={TranslationKey.WORD_CLOUD} />}
              to="/word-cloud"
            />
            <PictureLink
              icon={<TutorialLogo />}
              label={<FormattedMessage id={TranslationKey.VIEW_TUTORIALS} />}
              to={tutorialsLink}
              isExternal
            />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Home;
