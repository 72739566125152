import { IconButton, Typography } from '@material-ui/core';
import React, { ReactElement, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { userSessionActions } from '../../actions';
import ABIButton from '../../components/ABIButton';
import { TranslationKey } from '../../i18n/translations';
import { userSessionSelectors } from '../../selectors';
import { redirectSSO } from './validateSSO';
import styles from './Session.module.scss';

const Login = (): ReactElement => {
  const sending = useSelector(userSessionSelectors.getSending);
  const fetching = useSelector(userSessionSelectors.getFetching);
  const credentialsError = useSelector(
    userSessionSelectors.getCredentialsError,
  );
  const dispatch = useDispatch();
  const state = sessionStorage.getItem('state');

  const handleRedirectSSO = () => {
    redirectSSO();
  };

  // Fetch user session on startup
  useEffect(() => {
    if (state) {
      if (fetching) {
        dispatch(userSessionActions.fetchUserSession());
      }
    }
  }, [dispatch, fetching]);

  return (
    <>
      {credentialsError && (
        <div className={styles.textErrorSession}>
          <Typography variant="subtitle1">
            <FormattedMessage id={TranslationKey.ERROR_SESSION} />
          </Typography>
        </div>
      )}
      <ABIButton
        className={styles.loginButton}
        type="submit"
        disabled={sending}
        onClick={handleRedirectSSO}
      >
        <FormattedMessage id={TranslationKey.INIT_SESSION} />
      </ABIButton>
    </>
  );
};

export default Login;
