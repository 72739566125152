import { CircularProgress, Grid, Tooltip } from '@material-ui/core';
import { Form, Formik } from 'formik';
import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { reportsActions } from '../../actions';
import excelLogo from '../../assets/images/excel-logo.png';
import ABIButton from '../../components/ABIButton';
import {
  FormAutocompleteField,
  FormDateField,
  FormMultipleSelectField,
} from '../../components/FormFields';
import MainButton from '../../components/MainButton';
import { apiURL } from '../../config/environment';
import {
  BusinessUnitCode,
  ExcelReportType,
  ReportFilterField,
  ReportFilterType,
  UserRole,
  VicePresidencyCode,
} from '../../enums';
import { TranslationKey } from '../../i18n/translations';
import { reportsSelectors, userSessionSelectors } from '../../selectors';
import { ReportFilterValues } from '../../types';
import TranslateFormikErrors from '../TranslateFormikErrors';
import FormValueSync from './FormValueSync';
import styles from './Reports.module.scss';
import { getExcelValidationSchema } from './schemas';

const initialValues: ReportFilterValues = {
  [ReportFilterField.FilterType]: ReportFilterType.Excel,
  [ReportFilterField.From]: '',
  [ReportFilterField.Until]: '',
  [ReportFilterField.BusinessUnits]: [],
  [ReportFilterField.VicePresidencies]: [],
  [ReportFilterField.UENs]: [],
};

const rolesWithAccess: UserRole[] = [
  UserRole.Admin,
  UserRole.Owner,
  UserRole.CountryOwner,
];

const tooltipTranslationMap: Record<UserRole, string> = {
  [UserRole.Admin]: TranslationKey.UNREGISTERED_TOOLTIP_ADMINS,
  [UserRole.Owner]: TranslationKey.UNREGISTERED_TOOLTIP_ADMINS,
  [UserRole.CountryOwner]: TranslationKey.UNREGISTERED_TOOLTIP_ADMINS,
  [UserRole.Regular]: TranslationKey.UNREGISTERED_TOOLTIP_REGULAR,
};

const ExcelFilters = (): ReactElement => {
  const intl = useIntl();

  const userRole = useSelector(userSessionSelectors.getRole);

  const [reportType, setReportType] = useState<ExcelReportType>(
    ExcelReportType.ExitInterview,
  );

  const dispatch = useDispatch();
  const handleSubmit = useCallback(
    (values: ReportFilterValues) => {
      dispatch(
        reportsActions.downloadReportData(
          {
            ...values,
            [ReportFilterField.Until]:
              values[ReportFilterField.Until] || new Date(),
          },
          reportType,
        ),
      );
    },
    [dispatch, reportType],
  );

  const validationSchema = useMemo(
    () => getExcelValidationSchema(intl),
    [intl],
  );

  const buOptions = useMemo(
    () =>
      Object.values(BusinessUnitCode).map((code) => ({
        value: code,
        label: intl.formatMessage({
          id: `BUSINESS_UNIT_${code}`,
        }),
      })),
    [intl],
  );

  const vicePresidencyOptions = useMemo(
    () =>
      Object.values(VicePresidencyCode).map((vpCode) => ({
        value: vpCode,
        label: intl.formatMessage({
          id: `VP_${vpCode}`,
        }),
      })),
    [intl],
  );

  const downloading = useSelector(reportsSelectors.getDownloading);

  return (
    <div className={styles.filterCard}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        autoComplete="off"
      >
        {({ submitForm }) => (
          <Form noValidate autoComplete="off">
            <TranslateFormikErrors />

            <FormValueSync />

            <h3 className={styles.filterStep}>
              <FormattedMessage id={TranslationKey.ANOTHER_REPORT} />
            </h3>

            <p className={styles.filterInstruction}>
              <FormattedMessage id={TranslationKey.ANOTHER_REPORT_SUB} />
            </p>

            <hr className={`${styles.separator} ${styles.complete}`} />

            <p className={styles.filterInstruction}>
              <small>
                <FormattedMessage id={TranslationKey.COLLABORATOR_EXIT_DATE} />:
              </small>
            </p>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormDateField
                  name={ReportFilterField.From}
                  label={intl.formatMessage({
                    id: TranslationKey.FROM,
                  })}
                  maxDate={new Date()}
                  disabled={downloading}
                />
              </Grid>

              <Grid item xs={6}>
                <FormDateField
                  name={ReportFilterField.Until}
                  label={intl.formatMessage({
                    id: TranslationKey.UNTIL,
                  })}
                  maxDate={new Date()}
                  disabled={downloading}
                />
              </Grid>
            </Grid>

            {userRole === UserRole.Admin && (
              <>
                <hr className={styles.separator} />
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormMultipleSelectField
                      name={ReportFilterField.BusinessUnits}
                      label="BU"
                      options={buOptions}
                      disabled={downloading}
                    />
                  </Grid>
                </Grid>
              </>
            )}

            {rolesWithAccess.includes(userRole) && (
              <>
                <hr className={styles.separator} />
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormMultipleSelectField
                      name={ReportFilterField.VicePresidencies}
                      label={intl.formatMessage({
                        id: TranslationKey.VP_SHORT,
                      })}
                      options={vicePresidencyOptions}
                      disabled={downloading}
                      showAll
                    />
                  </Grid>
                </Grid>
              </>
            )}

            {rolesWithAccess.includes(userRole) && (
              <>
                <hr className={styles.separator} />
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormAutocompleteField
                      name={ReportFilterField.UENs}
                      label={intl.formatMessage({
                        id: TranslationKey.UEN,
                      })}
                      apiURL={`${apiURL}/auth/autocomplete/uen`}
                      disabled={downloading}
                      multiple
                    />
                  </Grid>
                </Grid>
              </>
            )}

            <hr className={`${styles.separator} ${styles.complete}`} />

            <div className={styles.buttonContainer}>
              <ABIButton
                onClick={async () => {
                  if (downloading) return;
                  setReportType(ExcelReportType.ExitInterview);
                  await submitForm();
                }}
                buttonType="cancel"
                disabled={downloading}
              >
                {downloading && reportType === ExcelReportType.ExitInterview ? (
                  <CircularProgress
                    size="14px"
                    color="inherit"
                    thickness={6}
                    className={styles.buttonSpinner}
                  />
                ) : (
                  <img
                    src={excelLogo}
                    alt="Excel logo"
                    className={styles.excelLogo}
                  />
                )}{' '}
                <FormattedMessage
                  id={
                    downloading && reportType === ExcelReportType.ExitInterview
                      ? TranslationKey.GENERATING_DOWNLOAD
                      : TranslationKey.DOWNLOAD_REPORT
                  }
                />
              </ABIButton>

              <Tooltip
                title={
                  <FormattedMessage id={tooltipTranslationMap[userRole]} />
                }
                arrow
                placement="right"
              >
                <ABIButton
                  onClick={async () => {
                    if (downloading) return;
                    setReportType(ExcelReportType.Withdrawals);
                    await submitForm();
                  }}
                  disabled={downloading}
                  buttonType="cancel"
                >
                  {downloading && reportType === ExcelReportType.Withdrawals ? (
                    <CircularProgress
                      size="14px"
                      color="inherit"
                      thickness={6}
                      className={styles.buttonSpinner}
                    />
                  ) : (
                    <img
                      src={excelLogo}
                      alt="Excel logo"
                      className={styles.excelLogo}
                    />
                  )}{' '}
                  <FormattedMessage
                    id={
                      downloading && reportType === ExcelReportType.Withdrawals
                        ? TranslationKey.GENERATING_DOWNLOAD
                        : TranslationKey.UNREGISTERED_INTERVIEWS
                    }
                  />
                </ABIButton>
              </Tooltip>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ExcelFilters;
