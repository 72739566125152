import {
  WordCloudConfig,
  WordCloudFilterValues,
  WordCloudRecord,
} from '../types';
import * as types from './actionTypes';

export const setFetching = (
  fetching: boolean,
): types.SetWordCloudFetchingAction => ({
  type: types.SET_WORD_CLOUD_FETCHING,
  fetching,
});

export const setWordCloudConfig = (
  config: WordCloudConfig,
): types.SetWordCloudConfigAction => ({
  type: types.SET_WORD_CLOUD_CONFIG,
  config,
});

export const fetchWordCloudData = (
  filters: WordCloudFilterValues,
): types.FetchWordCloudDataAction => ({
  type: types.FETCH_WORD_CLOUD_DATA,
  filters,
});

export const setWordCloudData = (
  data: WordCloudRecord[],
  count: number,
): types.SetWordCloudDataAction => ({
  type: types.SET_WORD_CLOUD_DATA,
  data,
  count,
});

export const clearState = (): types.ClearWordCloudStateAction => ({
  type: types.CLEAR_WORD_CLOUD_STATE,
});
