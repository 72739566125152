import { pick } from 'lodash-es';
import { createSelector } from 'reselect';
import { isInterviewDownloadable } from '../helpers/util-functions';
import { AppState, HistoryInterview, HistoryState } from '../types';

export const getModuleState = (state: AppState): HistoryState => state.history;

export const getInitialized = createSelector(
  getModuleState,
  (state) => state.initialized,
);

export const getPage = createSelector(getModuleState, (state) => state.page);

export const getSortColumn = createSelector(
  getModuleState,
  (state) => state.sortColumn,
);

export const getSortDirection = createSelector(
  getModuleState,
  (state) => state.sortDirection,
);

export const getFilters = createSelector(
  getModuleState,
  (state) => state.filters,
);

export const getAdditionalFilters = createSelector(
  getModuleState,
  (state) => state.additionalFilters,
);

export const getOnlyPending = createSelector(
  getModuleState,
  (state) => state.onlyPending,
);

export const getExpanded = createSelector(
  getModuleState,
  (state) => state.expanded,
);

export const getFetching = createSelector(
  getModuleState,
  (state) => state.fetching,
);

export const getInterviews = createSelector(
  getModuleState,
  (state) => state.interviews,
);
export const getCount = createSelector(getModuleState, (state) => state.count);

const pendingFields: Partial<keyof HistoryInterview>[] = [
  'id',
  'interviewerId',
  'sharp',
  'employee',
  'lineManager',
  'createdAt',
  'uen',
  'status',
  'isCritic',
];

export const getPendingInterviews = createSelector(
  getInterviews,
  (interviews) => interviews.map((interview) => pick(interview, pendingFields)),
);

export const getSelectedInterviewsCount = createSelector(
  getInterviews,
  (interviews) =>
    interviews.reduce((count, { selected }) => count + Number(selected), 0),
);

export const getSelectableInterviewsCount = createSelector(
  getInterviews,
  (interviews) =>
    interviews.reduce(
      (count, { status }) => count + Number(isInterviewDownloadable(status)),
      0,
    ),
);

export const getSelectedInterviewIds = createSelector(
  getInterviews,
  (interviews) =>
    interviews.filter(({ selected }) => selected).map(({ id }) => id),
);

export const getDownloading = createSelector(
  getModuleState,
  (state) => state.downloading,
);
