import React, { ReactElement, useMemo } from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { DiscreteColorLegend, RadialChart } from 'react-vis';
import { ChartReportType, ExitMotive, KPIField } from '../../enums';
import { TranslationKey } from '../../i18n/translations';
import { reportsSelectors } from '../../selectors';
import { ReportConfig, ReportRecord } from '../../types';
import { exitMotiveColors, KPIColors } from './colors';
import styles from './ReportChart.module.scss';

const COLUMN_SIZE = 8;
const SHOW_PERCENTAGE_LIMIT = 3.5;

interface DonutChartProps {
  size: number;
}

const reportRecordsTotalReducer = (prev: number, curr: ReportRecord): number =>
  prev + curr.value;

const DonutChart = React.forwardRef<HTMLDivElement, DonutChartProps>(
  ({ size }, ref): ReactElement => {
    const intl = useIntl();
    const reportData = useSelector(reportsSelectors.getReportData);
    const reportConfig = useSelector(
      reportsSelectors.getConfig,
    ) as ReportConfig;

    const { reportType, from, until } = reportConfig;

    const isKPI = reportType === ChartReportType.KPI;

    const total = Object.values(reportData || {}).reduce(
      (accumulated, reportRecords) =>
        accumulated + reportRecords.reduce(reportRecordsTotalReducer, 0),
      0,
    );

    const mapLabel = useMemo(
      () => ({ field, value }: { field: string; value: number }) => {
        const percentage = `${((value / total) * 100).toFixed(1)}%`;
        const exitMotive = intl.formatMessage({
          id: isKPI ? field : `EXIT_MOTIVE_${field}`,
        });
        return {
          title: `${percentage} - ${exitMotive} (${value})`,
          color: isKPI
            ? KPIColors[field as KPIField]
            : exitMotiveColors[field as ExitMotive],
          strokeWidth: 12,
        };
      },
      [intl, isKPI, total],
    );

    const sortedAccumulatedData = useMemo(
      () =>
        Object.entries(reportData || {})
          .map(([field, reportRecords]) => ({
            field,
            value: reportRecords.reduce(reportRecordsTotalReducer, 0),
          }))
          .filter(({ value }) => !!value)
          .sort((pre, post) => post.value - pre.value),
      [reportData],
    );

    const firstColumnData = useMemo(
      () => sortedAccumulatedData.slice(0, COLUMN_SIZE),
      [sortedAccumulatedData],
    );
    const secondColumnData = useMemo(
      () => sortedAccumulatedData.slice(COLUMN_SIZE),
      [sortedAccumulatedData],
    );

    return (
      <div ref={ref} className={styles.chartContainer}>
        <h2 className={styles.title}>
          <FormattedMessage id={`REPORT_TYPE_${reportType}_TITLE`} />
        </h2>

        {reportType === ChartReportType.KPI && (
          <h4 className={styles.subTitle}>
            <FormattedMessage id={TranslationKey.COMPLIANCE_PERCENTAGE} />
          </h4>
        )}

        <h6 className={styles.dates}>
          <FormattedMessage id={TranslationKey.FROM} />{' '}
          <FormattedDate value={from} />{' '}
          {intl
            .formatMessage({
              id: TranslationKey.UNTIL,
            })
            .toLowerCase()}{' '}
          <FormattedDate value={until} />
        </h6>

        <div className={styles.donutChartContainer}>
          <RadialChart
            className={styles.xyPlot}
            data={sortedAccumulatedData.map(({ field, value }) => {
              const percentage = (value / total) * 100;
              const showingPercentage = percentage >= SHOW_PERCENTAGE_LIMIT;
              return {
                angle: value,
                label:
                  value && showingPercentage ? `${percentage.toFixed(1)}%` : '',
                color: isKPI
                  ? KPIColors[field as KPIField]
                  : exitMotiveColors[field as ExitMotive],
              };
            })}
            height={size}
            width={size}
            innerRadius={100}
            radius={140}
            showLabels
            colorType="literal"
            labelsRadiusMultiplier={0.95}
            labelsStyle={{
              fill: 'white',
              fontSize: 11,
              textShadow: '1px 1px 4px black',
            }}
          >
            <DiscreteColorLegend
              className={styles.chartLegend}
              orientation="vertical"
              items={firstColumnData.map(mapLabel)}
            />

            {!!secondColumnData.length && (
              <DiscreteColorLegend
                className={styles.chartLegend}
                orientation="vertical"
                items={secondColumnData.map(mapLabel)}
              />
            )}
          </RadialChart>
        </div>
        <h6 className={styles.dates}>
          <FormattedMessage
            id={TranslationKey.TOTAL_INTERVIEWS}
            values={{
              total,
            }}
          />
        </h6>
      </div>
    );
  },
);

export default DonutChart;
