import { InterviewStatus } from '../enums';
import {
  FeedbackValues,
  NewInterviewValues,
  QuestionaryValues,
} from '../types';
import * as types from './actionTypes';

export const createInterviewRequest = (
  newInterview: NewInterviewValues,
): types.CreateInterviewRequestAction => ({
  type: types.CREATE_INTERVIEW_REQUEST,
  newInterview,
});

export const setInterviewValues = (
  id?: number,
  interviewerId?: number,
  collaborator?: string,
  status?: InterviewStatus,
): types.SetInterviewValues => ({
  type: types.SET_INTERVIEW_VALUES,
  id,
  interviewerId,
  collaborator,
  status,
});

export const setSaving = (saving: boolean): types.SetSavingQuestionsAction => ({
  type: types.SET_SAVING_QUESTIONS,
  saving,
});

export const saveQuestionaryRequest = (
  questionary: QuestionaryValues,
): types.SaveQuestionaryRequestAction => ({
  type: types.SAVE_QUESTIONARY_REQUEST,
  questionary,
});

export const setSending = (sending: boolean): types.SetSendingAction => ({
  type: types.SET_SENDING,
  sending,
});

export const saveFeedbackRequest = (
  feedback: FeedbackValues,
): types.SaveFeedbackRequestAction => ({
  type: types.SAVE_FEEDBACK_REQUEST,
  feedback,
});
