/* eslint-disable react/button-has-type */

import React, { HTMLProps, ReactElement, ReactNode } from 'react';
import styles from './MainButton.module.scss';

type HTMLButtonType = 'button' | 'submit' | 'reset';
type AppButtonType = 'main' | 'download';
type AppColorType = 'primary' | 'secondary';

const typeStyleMap: Record<AppButtonType, string> = {
  main: styles.main,
  download: styles.download,
};
const typeColorMap: Record<AppColorType, string> = {
  primary: styles.primary,
  secondary: styles.secondary,
};

interface MainButtonProps extends HTMLProps<HTMLButtonElement> {
  children: ReactNode;
  type: HTMLButtonType;
  appButtonType?: AppButtonType;
  appColorType?: AppColorType;
}

const MainButton = React.forwardRef<HTMLButtonElement | null, MainButtonProps>(
  (
    {
      children,
      type,
      appButtonType = 'main',
      appColorType = 'primary',
      ...htmlProps
    },
    ref,
  ): ReactElement => (
    <>
      <button
        ref={ref}
        {...htmlProps}
        type={type}
        className={`${styles.button} ${typeStyleMap[appButtonType]} ${
          typeColorMap[appColorType]
        } ${htmlProps.className || ''}`}
      >
        {children}
      </button>
    </>
  ),
);

export default MainButton;
