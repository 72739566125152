import { CircularProgress, Grid } from '@material-ui/core';
import { GetApp as GetAppIcon } from '@material-ui/icons';
import { Form, Formik } from 'formik';
import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { historyAPI } from '../../api';
import ContentTitle from '../../components/ContentTitle';
import { FormRadioField, FormTextField } from '../../components/FormFields';
import LoadingSpinner from '../../components/LoadingSpinner';
import MainButton from '../../components/MainButton';
import Question from '../../components/Question';
import {
  BooleanSelection,
  QuestionaryFieldPrefix,
  QuestionaryType,
  QuestionType,
} from '../../enums';
import { downloadFile } from '../../helpers/util-functions';
import { useFetchQuestions } from '../../hooks';
import { TranslationKey } from '../../i18n/translations';
import { userSessionSelectors } from '../../selectors';
import { logger } from '../../services';
import { QuestionaryValues } from '../../types';
import AnswersFetcher from './AnswersFetcher';
import styles from './CompletedInterview.module.scss';

const initialValues = Object.values(QuestionaryFieldPrefix).reduce(
  (values, fieldPrefix) => ({
    ...values,
    [fieldPrefix]: {
      answer: '',
      comments: '',
    },
  }),
  {} as QuestionaryValues,
);

const CompletedInterview = (): ReactElement => {
  const { id } = useParams();
  const [fetching, setFetching] = useState(true);
  const [downloading, setDownloading] = useState(false);
  const locale = useSelector(userSessionSelectors.getLocale);

  const navigate = useNavigate();
  const handleBackClick = useCallback(() => {
    navigate('/history');
  }, [navigate]);

  const handleDownloadClick = useCallback(() => {
    setDownloading(true);
    historyAPI.downloadInterviews([Number(id)], locale).subscribe(
      ({ response }) => {
        downloadFile('interview.zip', response);
        setDownloading(false);
      },
      (err) => {
        logger.error(err);
        setDownloading(false);
      },
    );
  }, [id, locale]);

  const intl = useIntl();
  const [fetchingQuestions, questions] = useFetchQuestions(
    QuestionaryType.Interview,
    intl,
  );

  const criticalStatusQuestion = useMemo(
    () =>
      questions.find(
        ({ fieldPrefix }) => fieldPrefix === QuestionaryFieldPrefix.Critical,
      ),
    [questions],
  );

  const booleanOptions = useMemo(
    () =>
      Object.values(BooleanSelection).map((value) => ({
        value,
        label: intl.formatMessage({
          id: value,
        }),
      })),
    [intl],
  );

  return (
    <div className={styles.container}>
      <Grid container>
        <Grid container item xs={12} justify="space-between">
          <ContentTitle className={styles.title}>
            <FormattedMessage id={TranslationKey.COMPLETED_INTERVIEW} />
          </ContentTitle>

          <div className={styles.buttonContainer}>
            <MainButton
              type="button"
              appButtonType="download"
              appColorType="secondary"
              onClick={handleBackClick}
            >
              <FormattedMessage id={TranslationKey.BACK} />
            </MainButton>

            <MainButton
              type="button"
              appButtonType="download"
              disabled={fetching || downloading}
              onClick={handleDownloadClick}
            >
              {downloading ? (
                <CircularProgress
                  size="14px"
                  color="inherit"
                  thickness={6}
                  className={styles.buttonSpinner}
                />
              ) : (
                <GetAppIcon
                  style={{
                    fontSize: 16,
                  }}
                />
              )}

              <FormattedMessage
                id={
                  downloading
                    ? TranslationKey.GENERATING_DOWNLOAD
                    : TranslationKey.DOWNLOAD
                }
              />
            </MainButton>
          </div>
        </Grid>

        <Formik
          initialValues={initialValues}
          autoComplete="off"
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onSubmit={() => {}}
        >
          {({ values }) => (
            <Form noValidate className={styles.form} autoComplete="off">
              <Grid container item xs={12} justify="center" alignItems="center">
                <AnswersFetcher id={Number(id)} setFetching={setFetching} />

                {fetching || fetchingQuestions ? (
                  <LoadingSpinner />
                ) : (
                  <Grid
                    container
                    justify="center"
                    alignItems="center"
                    spacing={1}
                  >
                    {questions
                      .filter(({ type }) => type !== QuestionType.Additional)
                      .map((question, index) => (
                        <Question
                          key={question.fieldPrefix}
                          {...question}
                          number={index + 1}
                          disabled
                          blackDisabled
                        />
                      ))}

                    <Grid container item xs={10} className={styles.container}>
                      <Grid item xs={12} sm={8}>
                        <p>
                          <strong>Business Partner:</strong>
                        </p>
                        <p>{criticalStatusQuestion?.explanation || ''}</p>
                        <p>
                          {criticalStatusQuestion?.question || ''}{' '}
                          <span className={styles.required}>*</span>
                        </p>
                      </Grid>
                      <Grid item xs={12} sm={8}>
                        <FormRadioField
                          id={`${QuestionaryFieldPrefix.Critical}-answer`}
                          name={`${QuestionaryFieldPrefix.Critical}.answer`}
                          options={booleanOptions}
                          disabled
                          required
                        />
                      </Grid>

                      {values[QuestionaryFieldPrefix.Critical].answer ===
                        BooleanSelection.Yes && (
                        <Grid item xs={12} sm={8}>
                          <FormTextField
                            id={`${QuestionaryFieldPrefix.Critical}-comments`}
                            name={`${QuestionaryFieldPrefix.Critical}.comments`}
                            label={`${intl.formatMessage({
                              id: TranslationKey.COMMENTS,
                            })}:`}
                            type="textarea"
                            multiline
                            rows={4}
                            disabled
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </div>
  );
};

export default CompletedInterview;
