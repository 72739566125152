import { Grid } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import ContentTitle from '../../components/ContentTitle';
import { TranslationKey } from '../../i18n/translations';
import styles from './Admin.module.scss';
import ReportDropzone from './ReportDropzone';

const ReportLoad = (): ReactElement => (
  <>
    <Grid item xs={12} className={styles.textContainer}>
      <ContentTitle>
        <FormattedMessage id={TranslationKey.REPORT_LOAD} />
      </ContentTitle>
    </Grid>
    <Grid container spacing={2} justifyContent="space-between">
      <ReportDropzone title="HeadCount" isHeadCount />
      <ReportDropzone
        title={<FormattedMessage id={TranslationKey.WITHDRAWALS} />}
      />
    </Grid>
  </>
);
export default ReportLoad;
